import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";
import { Invitation } from "./invitation";
import { IResponse } from "./responses";
import { firestore } from "firebase";
import { ReactNode } from "react";

export interface Section {
  [x: string]: ReactNode;
  avgRating: number;
  num: number;
  invited: number;
  completed: number;
  views: number;
}

export interface CompanySummary {
  id?: string;
  name: string;
  logo: string;
  sector: string;
  description: string;
  stage: string;
  website: string;
  investmentLink?: string;
  contactEmail?: string;
}

export interface Project {
  id: string;
  isDemo: boolean;
  company: CompanySummary;
  requester: string;
  startDate: firestore.Timestamp;
  endDate: firestore.Timestamp;
  sections: string[];
  sectionWeights: Record<string, number>;
  sectionStats: Record<string, Section>;
  pdfLinks: Record<string, string>;
  privacy: number;
  likes?: boolean;
  shared?: boolean;
  likesCount: number;
}

export interface Report {
  project: Project;
  responses: IResponse[];
  invitations: Invitation[];
  rank: number;
}

export interface ProjectState {
  allProjects: Project[];
  focusedReport?: Report;
  fetching: boolean;
  fetchError?: Error | string;
  creating: boolean;
  createError?: Error | string;
  sharing: boolean;
  shared: boolean;
  shareError?: Error | string;
  deleting: boolean;
  deleted: boolean;
  deleteError?: Error | string;
  addFavouriteError?: Error | string;
  removeFavouriteError?: Error | string;
}

export enum ProjectActions {
  CREATE = "CREATE_PROJECT",
  CREATE_SUCCESS = "CREATE_PROJECT_SUCCESS",
  CREATE_ERROR = "CREATE_PROJECT_ERROR",
  FETCH = "FETCH_PROJECT",
  FETCHED_REPORT = "FETCHED_REPORT",
  FETCHED_ALL = "FETCHED_ALL_PROJECTS",
  FETCH_ERROR = "FETCH_PROJECT_ERROR",
  DELETE = "SHARE_PROJECT",
  DELETED_PROJECT = "DELETED_PROJECT",
  DELETE_ERROR = "SHARE_ERROR",
  SHARE = "SHARE_PROJECT",
  SHARED_PROJECT = "SHARED_PROJECT",
  SHARED_REPORT = "SHARED_REPORT",
  SHARE_ERROR = "SHARE_ERROR",
  RESET_MODAL = "RESET_PROJECT_MODAL",
  ADD_FAVOURITE = "ADD_FAVOURITE_PROJECT",
  ADD_FAVOURITE_ERROR = "ADD_FAVOURITE_PROJECT_ERROR",
  REMOVE_FAVOURITE = "REMOVE_FAVOURITE_PROJECT",
  REMOVE_FAVOURITE_ERROR = "REMOVE_FAVOURITE_PROJECT_ERROR",
}

interface ProjectActionType<T, P = {}> {
  type: T;
  payload?: P;
}

export type ProjectAction =
  | ProjectActionType<typeof ProjectActions.CREATE>
  | ProjectActionType<typeof ProjectActions.CREATE_SUCCESS>
  | ProjectActionType<typeof ProjectActions.CREATE_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.FETCH>
  | ProjectActionType<typeof ProjectActions.FETCHED_REPORT, Report>
  | ProjectActionType<typeof ProjectActions.FETCHED_ALL, Project[]>
  | ProjectActionType<typeof ProjectActions.FETCH_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.DELETE>
  | ProjectActionType<typeof ProjectActions.DELETED_PROJECT, string>
  | ProjectActionType<typeof ProjectActions.DELETE_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.SHARE>
  | ProjectActionType<typeof ProjectActions.SHARED_PROJECT>
  | ProjectActionType<typeof ProjectActions.SHARED_REPORT>
  | ProjectActionType<typeof ProjectActions.SHARE_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.RESET_MODAL>
  | ProjectActionType<typeof ProjectActions.ADD_FAVOURITE>
  | ProjectActionType<typeof ProjectActions.ADD_FAVOURITE_ERROR, Error | string>
  | ProjectActionType<typeof ProjectActions.REMOVE_FAVOURITE>
  | ProjectActionType<typeof ProjectActions.REMOVE_FAVOURITE_ERROR, Error | string>
    ;
export type ProjectThunk = ThunkAction<void, RootState, unknown, ProjectAction>;
export type ProjectDispatch = ThunkDispatch<RootState, any, ProjectAction>;
