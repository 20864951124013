import React, { useEffect, useState } from "react";

import {
	Menu,
	MenuItem,
	Button,
	TableCell,
	TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { Typography } from "../../components/Wrappers/Wrappers";
import { TechnicalFactor } from "../../model";
import { QuestionRepo } from "../../repositories/question";
import invitation from "../../repositories/invitation";

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);



function TechnicalList(props: { data: TechnicalFactor }) {

	const { data } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEdit = () => {
		history.push("/edit-Factor/" + data.did)
	};

	const deleteFactor = () => {
		setAnchorEl(null);
		const res = invitation.deleteFactor(data.did).then(() => {
			window.location.reload();
		})
	};


	return (
		<StyledTableRow>
			<TableCell>
			</TableCell>
			<TableCell>
				<Typography weight="bold" variant="body1">
					{data.value}
				</Typography>
			</TableCell>
            <TableCell>
				{data.section}
			</TableCell>
			<TableCell>
				{data.weight}
			</TableCell>
			<TableCell>
				<Button aria-controls="simple-menu" color="primary" aria-haspopup="true" onClick={handleClick}>
					Action
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleEdit}>Edit</MenuItem>
					<MenuItem onClick={deleteFactor}>Delete</MenuItem>
				</Menu>
			</TableCell>
		</StyledTableRow>

	);
}

export { TechnicalList };
