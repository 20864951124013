import {
  ResponseState,
  ResponseAction,
  ResponseActions,
} from "../model/responses";
import { Reducer } from "react";

const initialState: ResponseState = {
  fetching: false,
  responses: [],
};

export const commentReducer: Reducer<ResponseState, ResponseAction> = (
  state: ResponseState = initialState,
  action: ResponseAction
): ResponseState => {
  switch (action.type) {
    case ResponseActions.FETCH:
      return { ...state, fetching: true };
    case ResponseActions.FETCH_ERROR:
      return { ...state, fetching: false, error: action.payload };
    case ResponseActions.FETCHED_RESPONSES:
      return { ...state, fetching: false, responses: action.payload ?? [] };
    default:
      return state;
  }
};
