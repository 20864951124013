import React, { Dispatch, useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import useStyles from "./styles";
import { useHistory, useParams } from "react-router-dom";
import {FactorForm}  from "./components";
import FadeIn from "react-fade-in";
import {  TechnicalFactor,Option } from "../../model";
import invitation from "../../repositories/invitation";
import Loader from "../../components/Loader/Loader";

const validationSchema = Yup.object().shape<TechnicalFactor>({
    value: Yup.string().required("this field is necessary"),
    label: Yup.string().required("this field is necessary"),
    weight: Yup.number().required("this feild is necessary"),
    options: Yup.array<Option>().min(1,"minimum one option should be added").required("this feild is necessary"),
    section: Yup.string().required("this field is necessary"),
    active: Yup.boolean().required('this field is required'),
    
  
});


function EditFactor() {
  const classes = useStyles();
  const history = useHistory();
  const [data,setData]=useState<TechnicalFactor>();
  const {id}  = useParams<{id:string}>();
useEffect(()=>{
invitation.getTechnicalFactor(id).then((res)=>{
    setData(res);
})
},[])

const intitialValues: TechnicalFactor = {
    value: data?data.value:'',
    label: data?data.label:'',
    weight: data?data.weight:0,
    options: data?data.options:[],
    section: data?data.section:'',
    active: data?data.active:false
  };
  if (!data) return <Loader text="fetching the Factor..." />;
  return (
    <div className={classes.root}>
      <Formik<TechnicalFactor>
        initialValues={intitialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
            invitation.updateTechnicalFactor(id,values).then((res)=>{
           history.push("/app/technical");
            })
        //    history.push("/app/dashboard");
        }}
      >
        {(formik) => (
          <Form className={classes.root}>
            <FadeIn>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                  <FactorForm options={intitialValues.options}/>
                  <div style={{ marginBottom: 40 }} />
              </div>
            </FadeIn>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditFactor;
