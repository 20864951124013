import { ProjectState, ProjectAction, ProjectActions } from "../model/project";
import { Reducer } from "react";

const initialState: ProjectState = {
  allProjects: [],
  creating: false,
  fetching: true,
  sharing: false,
  shared: false,
  deleting: false,
  deleted: false,
};

export const projectReducer: Reducer<ProjectState, ProjectAction> = (
  state: ProjectState = initialState,
  action: ProjectAction
): ProjectState => {
  switch (action.type) {
    case ProjectActions.CREATE:
      return { ...state, creating: true, createError: undefined };
    case ProjectActions.CREATE_SUCCESS:
      return { ...state, creating: false, createError: undefined };
    case ProjectActions.CREATE_ERROR:
      return { ...state, creating: false, createError: action.payload };
    case ProjectActions.SHARE:
      return { ...state, sharing: true };
    case ProjectActions.SHARED_PROJECT:
      return { ...state, sharing: false, shared: true };
    case ProjectActions.SHARED_REPORT:
      return { ...state, sharing: false, shared: true };
    case ProjectActions.SHARE_ERROR:
      return { ...state, sharing: false, shareError: action.payload };
    case ProjectActions.FETCH:
      return { ...state, fetching: true, fetchError: undefined };
    case ProjectActions.FETCHED_ALL:
      return {
        ...state,
        fetching: false,
        fetchError: undefined,
        allProjects: action.payload ?? [],
      };
    case ProjectActions.FETCHED_REPORT:
      return {
        ...state,
        fetching: false,
        fetchError: undefined,
        focusedReport: action.payload,
      };
    case ProjectActions.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: action.payload };
    case ProjectActions.DELETE:
      return { ...state, deleting: true };
    case ProjectActions.DELETE_ERROR:
      return { ...state, deleting: false, deleteError: action.payload };
    case ProjectActions.DELETED_PROJECT:
      const newListOfProjects = state.allProjects.filter(
        (p) => p.id !== action.payload
      );
      return {
        ...state,
        deleted: true,
        deleting: false,
        allProjects: newListOfProjects,
        focusedReport: undefined,
      };
    case ProjectActions.RESET_MODAL:
      return {
        ...state,
        deleting: false,
        deleteError: undefined,
        deleted: false,
        sharing: false,
        shareError: undefined,
        shared: false,
      };
    case ProjectActions.ADD_FAVOURITE:
      const newListOfProjectsAddFavourite = state.allProjects.map(
        (p) => {
            if(p.id == action.payload){
              return {...p,likes:true,likesCount:p.likesCount+1,addFavouriteError:undefined}
            }
            return p;
          }
        );
      return {
          ...state,
          allProjects: newListOfProjectsAddFavourite,
      };

    case ProjectActions.ADD_FAVOURITE_ERROR:
      return { ...state, addFavouriteError: action.payload };

    case ProjectActions.REMOVE_FAVOURITE:
      const newListOfProjectsRemoveFavourite = state.allProjects.map(
        (p) => {
          var removeCount=p.likesCount-1;
            if(p.id == action.payload){
              return {...p,likes:false,likesCount:removeCount,removeFavouriteError:undefined}
            }
            return p;
          }
        );
      return {
          ...state,
          allProjects: newListOfProjectsRemoveFavourite,
      };

    case ProjectActions.REMOVE_FAVOURITE_ERROR:
      return { ...state, removeFavouriteError: action.payload };

    default:
      return state;
  }
};
