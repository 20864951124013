import { NotificationsThunk, NotificationsActions } from "../model";
import { Firestore } from "../repositories/firestore";

export const fetchNotifications = (
  silent: boolean = false
): NotificationsThunk => {
  return async (dispatch, getState) => {
    if (!silent) dispatch({ type: NotificationsActions.FETCH });
    const { uid } = getState().firebase.auth;
    if (!uid) {
      dispatch({
        type: NotificationsActions.FETCH_ERROR,
        payload: "User Authentication Required.",
      });
      return;
    }
    const res = await Firestore.fetchNotifications(uid);
    if (res instanceof Error)
      dispatch({ type: NotificationsActions.FETCH_ERROR, payload: res });
    else
      dispatch({
        type: NotificationsActions.FETCHED_NOTIFICATIONS,
        payload: res ?? [],
      });
  };
};

export const markNotificationsAsRead = (): NotificationsThunk => {
  return async (dispatch, getState) => {
    console.log("mark all as read asynchronously");
    const { unreadNotifications, numUnread } = getState().notifications;
    if (numUnread === 0) return;
    await Firestore.markNotifAsRead(unreadNotifications ?? []);
    dispatch({ type: NotificationsActions.MARK_AS_READ });
  };
};
