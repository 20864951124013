import {
    QuestionActions,
    QuestionThunk,
    Questions,
  } from "../model";
  import { History, LocationState } from "history";
  import { QuestionRepo } from "../repositories/question";

  export const fetchAllQuestions = (): QuestionThunk => {
    return async (dispatch, getState) => {
      dispatch({ type: QuestionActions.FETCH });
      const res = await QuestionRepo.fetchAllQuestions();
      if (res instanceof Error)
        dispatch({ type: QuestionActions.FETCH_ERROR, payload: res });
      else dispatch({ type: QuestionActions.FETCHED_QUESTIONS, payload: res });
    };
  };

  export const createQuestion = (data:Questions): QuestionThunk => {
    return async (dispatch, getState) => {
      dispatch({ type: QuestionActions.CREATE });
      const res = await QuestionRepo.create(data);
      // console.debug(res,"prject action response")
      if (res instanceof Error)
        dispatch({ type: QuestionActions.CREATE_ERROR, payload: res });
      else dispatch({ type: QuestionActions.CREATE_SUCCESS, payload: res });
    };
  };