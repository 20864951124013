import React, { useEffect, Dispatch, useState } from "react";
import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";

import {
	Fab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Add as AddIcon, ChevronRight } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { searchString } from "../../actions/search";
import useStyles from "./styles";
import {app} from "../../repositories/firebase";
import { QuestionList } from "../../components/QuestionList";
import { RootState } from "../../reducers";
import { fetchAllQuestions } from "../../actions/question";
import {
	QuestionDispatch,
	Questions,
	SearchAction,
} from "../../model";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import _ from "lodash";
import { Filter } from "../../utils/enums";
import invitation from "../../repositories/invitation";
import axios from "axios";
import { NotificationV1Model } from "../../model/notificationV1";
import { NotificationList } from "../../components/NotificationList";


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#F2F7FF",
		fontWeight: "bold",
	},
}))(TableCell);

function getDefaultSorting(search: string): Filter {
	const sorting = new URLSearchParams(search).get("section");
	switch (sorting) {
		case "team":
			return Filter.team;
		case "idea":
			return Filter.idea;
		case "market":
			return Filter.market;
		case "finance":
			return Filter.finance;
		case "competition":
			return Filter.competition;
		case "esg":
			return Filter.esg;
		case "crypto":
			return Filter.crypto;
		default:
			return Filter.default;
	}
}


function NotificationV1() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	console.debug("chviywvkh", app)
	const instanceurl =app.options.apiUrl
	const keys = [
		{id:1,label:""},
		{id:2,label:"Title"},
		{id:3,label:"Notification Text"},
		// {id:4,label:"Image"},
		{id:5,label:"ProjectId"},
		{id:5,label:"Action"},

	];
	const question = useSelector((state: RootState) => state.question);
	const search = useSelector((state: RootState) => state.search);
	const profile = useSelector((state: RootState) => state.firebase.profile);
	const user = useSelector((state: RootState) => state.firebase.auth);
	const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
	const dispatchQuestion = useDispatch<QuestionDispatch>();
	const [filter, setFilter] = useState(Filter.default);
	const [loader, setLoader] = useState(false)
	const [notification, setNotification] = useState<NotificationV1Model[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	
	useEffect(() => {
		dispatchQuestion(fetchAllQuestions());
	}, [dispatchQuestion]);
	useEffect(() => {
		dispatchSearch(searchString(searchQuery));
	}, [dispatchSearch]);
	useEffect(() => {
		setFilter(getDefaultSorting(location.search));
	}, [location]);
	useEffect(() => {
		setLoader(true)
        const response = axios.get(
            //for main server
            instanceurl+".cloudfunctions.net/notificationV1",
            //for test instance
             ).then(function (response) {
            console.debug(response);
            let r = response.data
            if(r){
				setLoader(false)
                setNotification(r.data)
            }
      
        }).catch(function (error) {
          // console.debug("response",response);
            console.debug(error);
      
      
            return error.response;
        });
        // https://us-central1-invluencer-test.cloudfunctions.net/notificationV1
		// setQuestions(question.allQuestions)
	}, []);

	if (loader == true) return <Loader text="fetching notifications..." />;

	// if (notification.length)
	// 	return <Loader text="Fetching your Questions..." />;

	const userName = user.displayName?.split(" ")[0] ?? "there!";

	return (
		<>
			<Fab
				className={classes.createNotification}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={goToCreate}
				// onClick={()=>{invitation.createTechnicalScore().then((res)=>{
				// 	console.debug('hi finished hemanth');
				// })}}
			>
				<AddIcon />
				New Notification
			</Fab>
			<>
				<PageTitle
					noMargin
					title={
						new Date().getHours() > 12
							? "Good afternoon " + userName 
							: "Good morning " + userName 
					}
					extra={
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
							}}
						>
						</div>
					}
				/>
				{ <div className={classes.container}>
					
						<TableContainer>
						<Table stickyHeader className="mb-0">
							<TableHead>
								<TableRow>
									{keys.map((key) => (
										<StyledTableCell key={key.id}>{key.label}</StyledTableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
					{notification.map((v, index) => {
                                  return  <NotificationList key={index} data={v}/>
					})} 
                    
							</TableBody>
						</Table>
					</TableContainer>
					
				</div> }
			</>
		</>
	);

	function goToCreate(e: React.MouseEvent): void {
		e.preventDefault();
		return history.push("/create-notification");
	}
}



export default NotificationV1;