import * as React from "react";
import {
  useLocation,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Form from "./Form";
import SectionsOverview from "./SectionsOverview";
import Disclaimer from "./components/Disclaimer/Disclaimer";
import InvitationRepo from "./../../repositories/invitation";

function Overview() {
  const match = useRouteMatch();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id") || "";

  const [invitation, loading, error] = InvitationRepo.useDocumentData(id);

  if (loading) return <Loader />;
  if (!id || !invitation || error) return <Redirect to="/" />;
  if (!invitation.disclaimerAccepted)
    return <Disclaimer invitation={invitation} />;

  return (
    <Switch>
      <Route
        path={`${match.path}/:section`}
        exact
        render={(props) => {
          if (!invitation) return <Redirect to="https://requestor.i3d.ai" />;
          const sectionsStatus = invitation.sectionsStatus;
          const section = props.match.params.section;
          if (sectionsStatus[section] === "done")
            return <Redirect to={`${match.path}?id=${id}`} />;
          return <Form {...props} section={section} invitation={invitation} />;
        }}
      />
      <Route
        exact
        path={match.path}
        render={(props) => (
          <SectionsOverview {...props} invitation={invitation} />
        )}
      />
    </Switch>
  );
}

export default Overview;
