import { useDocumentData } from "react-firebase-hooks/firestore";
import { callableFunctions, firestore } from "../repositories/firebase";
import { handleError } from "../utils/decorators";
import { Invitation, Reminder,TechnicalFactor } from "../model";

const invitationsCollection = firestore.collection("invitations");
const technicalScoreCollection = firestore.collection("technicalScore");
const weightCollection = firestore.collection("weights");
const autoCommentCollection = firestore.collection("automatedComments");



export default class {
  static sendReminderFunc = callableFunctions.httpsCallable("sendReminder");

  @handleError("Failed to accept terms...")
  static async acceptTerms(id: string): Promise<void | Error> {
    const data = { disclaimerAccepted: true };
    await invitationsCollection.doc(id).update(data);
  }

  static useDocumentData(
    id: string
  ): [Invitation | undefined, boolean, Error | undefined] {
    return useDocumentData<Invitation>(invitationsCollection.doc(id), {
      idField: "id",
    });
  }

  @handleError("Failed to send reminder.")
  static async sendReminder(
    uid: string,
    reminder: Reminder,
    inviteID: string
  ): Promise<void | Error> {
    const data = { ...reminder, uid, inviteID };
    const err = await this.sendReminderFunc(data);
    if (err.data) return new Error(err.data);
  }

  @handleError("Failed to fetch invitation data.")
  static async fetchInvitation(
    inviteID: string,
  ): Promise<Invitation> {
    const invitation = await invitationsCollection.doc(inviteID).get();
    const data=  invitation.data() as Invitation;
    return data;
  }

  @handleError("Failed to fetch invitation data.")
  static async updateSectionStatus(
    inviteID: string,
    section: string
  ): Promise<void | Error> {
    const invitation = await invitationsCollection.doc(inviteID).get();
    const data=  invitation.data() as Invitation;
    data.sectionsStatus[section] = "done";
    await invitationsCollection.doc(inviteID).update(data);


  }

  

  @handleError("Failed to add technicalMarket...")
  static async getTechnicalQuestions(){
  
    const data = await technicalScoreCollection.get();
    return data.docs.map((d)=>{ return {...d.data(),did:d.id } as TechnicalFactor});

    
    // await invitationsCollection.doc(id).update(data);
  }

  @handleError("Failed to add technicalMarket...")
  static async getTechnicalFactor(id){
  
    const data = await technicalScoreCollection.doc(id).get();
    const tech = data.data();
    const opt = await Promise.all(tech?.options.map(async(val)=>{
      if('comment' in val)
      {
      let comm = await this.getComment(val.comment);
      let comment = comm?comm.text:'';
      return {...val,comment:comment,commentId:val.comment};
      }
      else{
        return val;
      }
    }));
  
    tech.options = opt;
    // console.debug(tech)
    return tech as TechnicalFactor;

    
    // await invitationsCollection.doc(id).update(data);
  }

  @handleError("Failed to add technicalMarket...")
  static async updateTechnicalFactor(id,values){
    var opt = await Promise.all(values.options.map(async(val)=>{
      if('commentId' in val)
      {
        let comm = await this.updateComment({text:val.comment},val.commentId);
        return {value:val.value,score:val.score,comment:val.commentId};
      }
      else
      {
        if('comment' in val)
        {
          let id = await this.addTechnicalComment({text:val.comment});
        return {value:val.value,score:val.score,comment:id};
        }
        else
        {
          return val;
        }
      }

    }));
    values.options = opt
    // console.debug(values)
    const data = await technicalScoreCollection.doc(id).update(values);
    // return data.data() as TechnicalFactor;

    
    // await invitationsCollection.doc(id).update(data);
  }

  @handleError("Failed to add technicalMarket...")
  static async addTechnicalQuestions(data){
  
    var opt = await Promise.all( data.options.map(async (val)=>{
      let id = await this.addTechnicalComment({text:val.comment});
      return {...val,comment:id};
     }));
     data.options = opt;
    const success = await technicalScoreCollection.add(data);
    return success;
   
  }

  @handleError("Failed to add comment...")
  static async addTechnicalComment(data){
  
    const success = await autoCommentCollection.add(data);
    return success.id;
  }

  @handleError("Failed to get weights...")
  static async getComment(id:string) {
      const result = await autoCommentCollection.doc(id).get();
      return result.data();
    }

   @handleError("Failed to update comment...")
  static async updateComment(data,id): Promise<void | Error> {
      const result = await autoCommentCollection.doc(id).update(data);
    }

  @handleError("Failed to delete factor...")
    static async deleteFactor(
        id: string
      ): Promise<void | Error> {
        const result = await technicalScoreCollection.doc(id).delete();
      }

  @handleError("Failed to update weights...")
  static async updateWeights(data): Promise<void | Error> {
      const result = await weightCollection.doc('DZCXUgVq4BYkaWyP4E6n').update(data);
    }

  @handleError("Failed to get weights...")
  static async getWeights() {
      const result = await weightCollection.doc('DZCXUgVq4BYkaWyP4E6n').get();
      return result.data();
    }



}
