import { firestore } from "./firebase";
import { INotification, IResponse, Feedback } from "../model";
import { handleError } from "../utils/decorators";

const notifications = firestore.collection("notifications");
const comments = firestore.collection("comments");
const feedbacks = firestore.collection("feedbacks");
const users = firestore.collection("users");

export class Firestore {
  @handleError("Failed to fetch notifications.")
  static async fetchNotifications(
    uid: string
  ): Promise<INotification[] | Error> {
    const snapshot = await notifications.where("receiverUID", "==", uid).get();
    return snapshot.docs.map(
      (d) => ({ ...d.data(), ref: d.ref } as INotification)
    );
  }

  @handleError("Failed to mark notifications as read.")
  static async markNotifAsRead(notifs: INotification[]): Promise<void | Error> {
    await Promise.all(notifs.map((n) => n.ref.update({ seen: true })));
  }

  @handleError("Failed to fetch comments.")
  static async fetchComments(uid: string): Promise<IResponse[] | Error> {
    const snapshot = await comments.where("requesterID", "==", uid).get();
    return snapshot.docs.map((d) => ({ ...d.data(), ref: d.ref } as IResponse));
  }

  @handleError("Failed to send feedback.")
  static async sendFeedback(
    uid: string,
    displayName: string | null,
    feedback: Feedback
  ): Promise<void | Error> {
    const data = {
      ...feedback,
      uid,
      username: displayName,
    };
    await feedbacks.doc().set(data, { merge: true });
  }

  @handleError("Failed to create subscription.")
  static async createSubscription(
    uid: string,
    price: string,
    onData: (error: Error, sessionId: string) => Promise<void>
  ): Promise<void | Error> {
    const docRef = await users.doc(uid).collection("checkout_sessions").add({
      price: price,
      allow_promotion_codes: true,
      trial_from_plan: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    docRef.onSnapshot(async (snap) => {
      const data = snap.data();
      await onData(data?.error, data?.sessionId);
    });
  }
}
