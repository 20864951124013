import {
  NotificationsState,
  NotificationsAction,
  NotificationsActions,
  INotification,
} from "../model/notifications";
import { Reducer } from "react";

const initialState: NotificationsState = {
  fetching: false,
  numUnread: 0,
  unreadNotifications: [],
  oldNotifications: [],
};

export const notificationsReducer: Reducer<
  NotificationsState,
  NotificationsAction
> = (
  state: NotificationsState = initialState,
  action: NotificationsAction
): NotificationsState => {
  switch (action.type) {
    case NotificationsActions.MARK_AS_READ:
      return { ...state, numUnread: 0 };
    case NotificationsActions.FETCH:
      return { ...state, fetching: true };
    case NotificationsActions.FETCH_ERROR:
      return { ...state, fetching: false, error: action.payload };
    case NotificationsActions.FETCHED_NOTIFICATIONS:
      let unread: INotification[] = [];
      let old: INotification[] = [];
      action.payload?.forEach((v) => {
        if (v.seen) old.push(v);
        else unread.push(v);
      });
      return {
        ...state,
        fetching: false,
        unreadNotifications: unread,
        numUnread: unread.length,
        oldNotifications: old,
      };
    default:
      return state;
  }
};
