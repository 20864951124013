import { RootState } from "../reducers";
import { ThunkDispatch, ThunkAction } from "redux-thunk";

export interface Invitation {
  id?: string;
  projectID: string;
  email: string;
  requestor: string;
  requestorId: string;
  company_name: string;
  sections: string[];
  disclaimerAccepted: boolean;
  sectionsStatus: Record<string, string>;
  sentAt: Date | firebase.firestore.Timestamp;
  // dueDate?: Date;
  pdfLink: Record<string, string>;
  formLink: Record<string, string>;
}

export interface InvitationState {
  invitation?: Invitation;
  fetching: boolean;
  error?: any;
}

export enum InvitationActions {
  FETCH = "FETCH_INVITATION",
  FETCHED_INVITATION = "FETCHED_INVITATION",
  FETCH_ERROR = "FETCH_INVITATION_ERROR",
}

interface InvitationActionType<T, P = {}> {
  type: T;
  payload?: P;
}

export type InvitationAction =
  | InvitationActionType<typeof InvitationActions.FETCH>
  | InvitationActionType<
      typeof InvitationActions.FETCHED_INVITATION,
      Invitation
    >
  | InvitationActionType<typeof InvitationActions.FETCH_ERROR, any>;

export type InvitationThunk = ThunkAction<
  void,
  RootState,
  unknown,
  InvitationAction
>;
export type InvitationDispatch = ThunkDispatch<
  RootState,
  any,
  InvitationAction
>;
