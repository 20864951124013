import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { callableFunctions } from "../../repositories/firebase";

function FormDialog({ open, initialValues, type, selectedBlocked, setFlag, setDialogOpen, handleClose }) {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setDialogOpen(false);
    setName("");
    setMessage("");
  };

  useEffect(() => {
    if (selectedBlocked) {
      setName(selectedBlocked.name || "");
    }
  }, [selectedBlocked]);

  const addBlockDomain = async (data) => {
    const addBlockedDomain = callableFunctions.httpsCallable('addBlockedDomain');
    try {
      const result = await addBlockedDomain(data);
      return result.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateBlockedDomain = async (id, name, activeStatus) => {
    const updateDomainFunction = callableFunctions.httpsCallable('updateBlockedDomain');
    try {
      const result = await updateDomainFunction({ id, name, activeStatus });
      return result.data;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (type === 'rename') {
      try {
        const UpdateActiveStatus = selectedBlocked.activeStatus;
        const result = await updateBlockedDomain(selectedBlocked.id, name, UpdateActiveStatus);
        if (result.success) {
          setMessage('Domain name updated successfully');
          setMessageType('success');
          setFlag(true);
          handleClose();
          onClose();
        } else {
          console.debug("Error:Rename", result)
          setMessage(result.message);
          setMessageType('error');
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    } else {
      const addedData = {
        name: name,
        activeStatus: "TRUE"
      };

      try {
        const result = await addBlockDomain(addedData);
        if (result.success) {
          setMessage('Domain added successfully');
          setMessageType('success');
          setFlag(true);
          onClose();
        } else {
          setMessage(result.message);
          setMessageType('error');
        }
      } catch (error) {
        console.error("Error adding domain:", error);
      }
    }
    
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`${type === 'rename' ? 'Rename' : type === 'add' ? 'Add' : ''} Blocked Domain`}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill in the details below.</DialogContentText>
        {message !=""?<span style={{ color: messageType === 'error' ? 'red' : 'green' }}>{message}</span>:""}
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            label="Domain Name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value.toLowerCase())}
          />
          <DialogActions>
            <Button color="primary" onClick={onClose} disabled={loading}>Cancel</Button>
            <Button type="submit" color="primary" disabled={loading || name === ""}>
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default FormDialog;
