import React, { useState, useEffect, Dispatch } from "react";
import { Drawer, IconButton, List, Theme, ListItemText } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  ArrowBack as ArrowBackIcon,
  Feedback as FeedbackIcon,

} from "@material-ui/icons";
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar, showFeedbackModal } from "../../actions/layout";
import { LayoutAction } from "../../model";

const structure = [

  // {
  //   label: "Questions Set",
  //   icon: "Q"

  // },
  {
    id: 0, label: "All Questions", link: "/app/dashboard",
    // icon: <HomeIcon />
    icon: "A"
  },
  {
    id: 1,
    label: "Team",
    link: "/app/dashboard?section=team",
    // icon: <BackupOutlinedIcon />,
    icon: "T"

  },
  {
    id: 2,
    label: "Idea/Product",
    link: "/app/dashboard?section=idea",
    // icon: <FavoriteBorderIcon />,
    icon: "I"

  },
  {
    id: 3,
    label: "Market",
    link: "/app/dashboard?section=market",
    // icon: <FavoriteBorderIcon />,
    icon: "M"

  },
  {
    id: 4,
    label: "Competition",
    link: "/app/dashboard?section=competition",
    // icon: <FavoriteBorderIcon />,
    icon: "C"

  },
  {
    id: 5,
    label: "BM/Finance",
    link: "/app/dashboard?section=finance",
    // icon: <FavoriteBorderIcon />,
    icon: "B"

  },
  {
    id: 6,
    label: "ESG",
    link: "/app/dashboard?section=esg",
    // icon: <FavoriteBorderIcon />,
    icon: "E"

  },
  {
    id: 7,
    label: "Crypto",
    link: "/app/dashboard?section=crypto",
    // icon: <FavoriteBorderIcon />,
    icon: "Cr"

  },
  {
    id: 8,
    label: "FAQs",
    link: "/app/faqs",
    // icon: <FavoriteBorderIcon />,
    icon: "Fq"

  },
];

interface IProps {
  location: any;
}

function Sidebar({ location }: IProps) {
  var classes: any = useStyles();
  var theme = useTheme<Theme>();

  // global
  const { isSidebarOpened } = useSelector((state: RootState) => state.layout);
  const dispatch = useDispatch<Dispatch<LayoutAction>>();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      PaperProps={{ elevation: 0 }}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => dispatch(toggleSidebar())}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {/* <ListItemText primary="Questions Set" /> */}
        <span className={classes.questionSet}>Questions Set</span>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />

        ))}
        <div
          style={{ marginTop: "auto" }}
          onClick={() => dispatch(showFeedbackModal())}
        >
          <SidebarLink
            key="feedback"
            label="Feedback"
            location={location}
            isSidebarOpened={isSidebarOpened}
            // icon={<FeedbackOutlinedIcon />}
            icon="F"
          />
        </div>
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
