import React from "react";
import {
  withStyles,
  Badge as BadgeBase,
  Typography as TypographyBase,
  Button as ButtonBase,
  Select as SelectBase,
  Input,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
import classnames from "classnames";

// styles
var useStyles = makeStyles(() => ({
  badge: {
    fontWeight: 600,
    height: 16,
    minWidth: 16,
  },
  selectInput: {
    padding: 10,
    paddingRight: 25,
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

interface IBadgeProps {
  brightness?: FontBrightness;
  color?: FontColor;
  htmlColor?: string;
  [x: string]: any;
}

function Badge({
  children,
  brightness,
  color,
  htmlColor,
  ...props
}: React.PropsWithChildren<IBadgeProps>) {
  var classes = useStyles();
  var theme = useTheme();
  var Styled = createStyled({
    badge: {
      backgroundColor: getColor({ color, theme, brightness, htmlColor }),
    },
  });

  return (
    <Styled>
      {(styledProps: any) => (
        <BadgeBase
          classes={{
            badge: classnames(classes.badge, styledProps.classes.badge),
          }}
          {...props}
        >
          {children}
        </BadgeBase>
      )}
    </Styled>
  );
}

type Alignment = "inherit" | "left" | "center" | "right" | "justify";

interface ITypographyProps {
  weight?: FontWeight;
  size?: FontSize;
  brightness?: FontBrightness;
  color?: FontColor;
  htmlColor?: string;
  align?: Alignment;
  [x: string]: any;
}

function Typography({
  children,
  weight,
  size,
  brightness,
  color,
  htmlColor,
  align,
  ...props
}: React.PropsWithChildren<ITypographyProps>) {
  var theme = useTheme();

  return (
    <TypographyBase
      align={align}
      style={{
        color: getColor({ color, theme, brightness, htmlColor }),
        fontWeight: getFontWeight(weight),
        fontSize: getFontSize(size, props.variant, theme),
      }}
      {...props}
    >
      {children}
    </TypographyBase>
  );
}

interface IButtonProps {
  children: React.ReactChild;
  color?: FontColor;
  className?: string;
  [x: string]: any;
}

function Button({ children, color, className, ...props }: IButtonProps) {
  var theme = useTheme<any>();

  var Styled = createStyled({
    root: {
      color: getColor({ color, theme }),
    },
    contained: {
      backgroundColor: getColor({ color, theme }),
      boxShadow: theme.customShadows.widget,
      color: `${color ? "white" : theme.palette.text.primary} !important`,
      "&:hover": {
        backgroundColor: getColor({ color, theme, brightness: "light" }),
        boxShadow: theme.customShadows.widgetWide,
      },
      "&:active": {
        boxShadow: theme.customShadows.widgetWide,
      },
    },
    outlined: {
      color: getColor({ color, theme }),
      borderColor: getColor({ color, theme }),
    },
    select: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  });

  return (
    <Styled>
      {({ classes }: any) => (
        <ButtonBase
          classes={{
            contained: classes.contained,
            root: classes.root,
            outlined: classes.outlined,
          }}
          {...props}
          className={classnames(
            {
              [classes.select]: props.select,
            },
            className
          )}
        >
          {children}
        </ButtonBase>
      )}
    </Styled>
  );
}

interface ISelectProps<T> {
  value?: T;
  possibleValues?: {
    value: string;
    label: string;
  }[];
  label?: string;
  onChange: (values: T) => void;
  renderValue?: (value: unknown) => React.ReactNode;
}

const defaultSelectValues = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "weekly",
    label: "Weekly",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
];

function Select<T>({
  value,
  possibleValues = defaultSelectValues,
  onChange,
  renderValue,
  label,
}: ISelectProps<T>) {
  const classes = useStyles();

  return (
    <SelectBase
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      renderValue={renderValue}
      input={
        <Input
          startAdornment={
            label && <Typography weight="bold">{label + ": "}</Typography>
          }
          disableUnderline
          classes={{ input: classes.selectInput }}
        />
      }
    >
      {possibleValues.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </SelectBase>
  );
}

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    maxWidth: 440,
    padding: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
  },
}))(Tooltip);

export { Badge, Typography, Button, Select };

// ########################################################################

type FontColor =
  | "primary"
  | "secondary"
  | "warning"
  | "success"
  | "info"
  | "text"
  | "background";

type FontBrightness = "main" | "light" | "dark" | "contrastText";

interface GetColorProps {
  color?: FontColor;
  htmlColor?: string;
  theme?: any;
  brightness?: FontBrightness;
}

function getColor({
  color,
  htmlColor,
  theme,
  brightness = "main",
}: GetColorProps) {
  if (htmlColor) return htmlColor;
  if (
    color &&
    theme &&
    theme.palette[color] &&
    theme.palette[color][brightness]
  ) {
    return theme.palette[color][brightness];
  }
  return undefined;
}

type FontWeight = "light" | "medium" | "bold" | undefined;

function getFontWeight(style: FontWeight) {
  switch (style) {
    case "light":
      return 300;
    case "medium":
      return 500;
    case "bold":
      return 600;
    default:
      return 400;
  }
}

type FontSize = "sm" | "md" | "xl" | "xxl" | false | undefined;

function getFontSize(size: FontSize, variant = "", theme: any) {
  var multiplier;

  switch (size) {
    case "sm":
      multiplier = 0.8;
      break;
    case "md":
      multiplier = 1.5;
      break;
    case "xl":
      multiplier = 2;
      break;
    case "xxl":
      multiplier = 3;
      break;
    default:
      multiplier = 1;
      break;
  }

  var defaultSize =
    variant && theme.typography[variant]
      ? theme.typography[variant].fontSize
      : theme.typography.fontSize + "px";

  return `calc(${defaultSize} * ${multiplier})`;
}

function createStyled(styles: any, options?: any) {
  var Styled = function (props: any) {
    const { children, ...other } = props;
    return children(other);
  };

  return withStyles(styles, options)(Styled);
}
