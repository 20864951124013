export enum SearchActions {
    SEARCH = "SEARCH",
  }

export interface SearchState {
  searchString: any;
}
interface SearchActionsType<T, P = {}> {
type: T;
payload?: P;
}
export type SearchAction =
 | SearchActionsType<SearchActions.SEARCH>