import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  },
  halfScreenContainer: {
    width: "50%",
    height: "100vh",
  },
  homebutton: {
    position: "absolute",
    top: 30,
    left: 30,
    zIndex: 9999,
  },
}));
