import {
    SearchActions,SearchAction,SearchState
} from "./../model";
import { Reducer } from "react";

  
  const initialState: SearchState  = {
    searchString: "",
  }
  
 export const searchReducer : Reducer<SearchState, SearchAction> = (
  state: SearchState = initialState,
  action: SearchAction
): SearchState => {
    switch (action.type) {
      case SearchActions.SEARCH:
        return {
          ...state,
          searchString: action.payload,
        }
      default: 
        return state
    }
  };
  
//   export default searchReducer