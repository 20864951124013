import React from "react";
import { Button, ButtonGroup, Grid, IconButton, TextareaAutosize, Theme, Tooltip, withStyles, Tabs, Tab } from "@material-ui/core";
import { Typography } from "../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { Select, Input, AutoComplete, Checkbox } from "formik-antd";
import { useFormikContext } from "formik";
import { Questions } from "../../../model";
import FadeIn from "react-fade-in";
import { sectionOptions } from "./data";


const { Option } = Select;

export default function () {
  const classes = useStyles();
  const formik = useFormikContext<Questions>();

  return (
    <FadeIn>
      <div>
          <div className={classes.inputFields} style={{ width: "100%", }}>
            <Typography variant="h4" className={classes.sectionTitles}>
              Question
            </Typography>
            <AutoComplete
              style={{ width: "100%", borderRadius: 10 }}
              name="question"
              placeholder="e.g. How are you"
              className={classes.details}
            />
          </div>
          <div>
            {formik.errors.question && formik.touched.question && (
              <Typography variant="caption" color="secondary">
                {formik.errors.question}
              </Typography>
            )}
  
          </div>
          <div className={classes.inputFields} style={{ width: "100%", }}>
            <Typography variant="h4" className={classes.sectionTitles}>
               Question Type
            </Typography>
            <Select
              name="type"
              defaultValue="No stage selected"
              style={{ width: "100%" }}
              className={classes.details}
            >
                <Option  value={"number"} title={"number"}>
                  number
                </Option>
                <Option  value={"text"} title={"text"}>
                  text
                </Option>
            </Select>
  
          </div>
          <div>
            {formik.errors.type &&
              formik.touched.type && (
                <Typography variant="caption" color="secondary">
                  {formik.errors.type}
                </Typography>
              )}
          </div>
        </div>
        <div>
          <div className={classes.inputFields} style={{ width: "100%", borderRadius: 10, }}>
            <Typography variant="h4" className={classes.sectionTitles}>
              Question Section
            </Typography>
            <Select
              name="section"
              defaultValue="No type selected"
              style={{ width: "100%" }}
              className={classes.details}
            >
              {sectionOptions.map((section) => (
                <Option key={section.value} value={section.value} title={section.label}>
                  {section.label}
                </Option>
              ))}
            </Select>
  
          </div>
          <div>
            {formik.errors.section && formik.touched.section && (
              <Typography variant="caption" color="secondary">
                {formik.errors.section}
              </Typography>
            )}
          </div>
          <div className={classes.inputFields} style={{ width: "100%", }}>
            <Typography variant="h4" className={classes.sectionTitles}>
              active
            </Typography>
            <Select
              name="active"
              defaultValue={false}
              style={{ width: "100%" }}
              className={classes.details}
            >
                <Option value={true} title="True">
                  {"True"}
                </Option>
                <Option value={false} title="False">
                  {"False"}
                </Option>
            </Select>
  
          </div>
          <div className={classes.inputFields} style={{ width: "100%", }}>
            <Typography variant="h4" className={classes.sectionTitles}>
              comment
            </Typography>
            <Select
              name="comment"
              defaultValue={false}
              style={{ width: "100%" }}
              className={classes.details}
            >
                <Option value={true} title="True">
                  {"True"}
                </Option>
                <Option value={false} title="False">
                  {"False"}
                </Option>
            </Select>
  
          </div>
        </div>
        
        

   
    </FadeIn>

  );
}