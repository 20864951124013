import React, { useEffect,useState } from "react";
import { withRouter, useLocation, useParams, useHistory } from "react-router-dom";
import { Grid, Typography, Tabs, Tab, Button } from "@material-ui/core";
import { Typography as Typo } from "../../components/Wrappers/Wrappers";
import { Checkbox, Divider } from "antd";
// styles
import useStyles from "./styles";
import Tour from "reactour";
// logo
import logo from "./logo.svg";
import { LoginForm, RegisterForm } from "../../components/Login";
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { getSectionLabel } from "../../pages/create/components/data";
import Loader from "../../components/Loader/Loader";
import invitation from "../../repositories/invitation"
import { Invitation } from "../../model";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";

function OverView() {
    const classes = useStyles();
    let history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const plan = searchParams.get("plan");
    const [start, setStart] = useState(false);
    const [invitationData,setInvitationData] = useState<Invitation>();
	const user = useSelector((state: RootState) => state.firebase.auth);
    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        /////////// fetching Invitation data///////////////////////
        invitation.fetchInvitation(id).then((response)=>
        {
            setInvitationData(response);
        })
      }, []);
    // _________________________REACTOUR FUNCTION______________________
    const accentColor = "#5cb7b7";
    const [isTourOpen, setTourOpen] = React.useState(true);
    const closeTour = () => {
        setTourOpen(false);
      };
    const tourConfig = [
        {
            selector: '[data-tut="reactour__logo"]',
            content: `You have to click on that button, so that you can access information `
        },
    ];

    const handleStart = () => {
        invitation.acceptTerms(id).then((response)=>{
        setStart(true);
        })
        .catch((error)=>{
            console.debug(error,"acceptTerms in invitation")
        });
    };

    // ____________________________START FORM ON NEWSCORE COMPONENT___________________
    
    const handleStartScore = (e) => {
        history.push("/newScore/"+id+"/"+e.target.value)
    };

    if (!invitationData) return <Loader text="Fetching your form..." />;
    if(user.email!==invitationData.email) return <ErrorDisplay error={"sorry you don't have permission to this page"} />
    return (
        <Grid container className={classes.container}>
            <Tour
                onRequestClose={closeTour}
                disableInteraction={false}
                steps={tourConfig}
                isOpen={isTourOpen}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={accentColor}
            />
            <Grid xs={5}>
                <div className={classes.logotypeContainers}>
                    <div className={classes.logotypeContainer}>
                        <img src={logo} alt="logo" className={classes.logotypeImage} />
                        <Typography className={classes.logotypeText}>i3D Platform</Typography>
                    </div>

                    <div className={classes.detailsContainer}>
                        <Typography >
                            Welcome
                        </Typography>
                        <Typography>
                            {user.email}
                        </Typography>
                        <Typography >
                            by clicking the button below, you certify thatyou have all legal requirement to access
                            sensitive information concerning cc42 Genetic and its legal subsidiaries.
                        </Typography>
                        <Button
                            data-tut="reactour__logo"
                            onClick={() => handleStart()}
                        >
                            I accept
                        </Button>
                    </div>

                </div>
            </Grid>
            <Grid xs={7}>
                {start ? "" : <div className={classes.overlay}></div>}
                <div className={classes.formContainer}>
                    <Grid xs={12} className={classes.formContainerData}>
                        <Typography >
                            Due diligence on cc 42 Genetics
                        </Typography>
                        <Typography >
                            Complete Overview
                        </Typography>
                        <Typography >
                            You have been requested to complete due diligence on these sections
                        </Typography>
                    </Grid>
                    <Grid xs={12} style={{ display: "flex", marginTop: "3rem", justifyContent: "center", }}>
                        <Grid xs={5}>
                            <div className={classes.sectionToDO}>
                                <Typography >
                                    Section To Do
                                </Typography>
                                {invitationData.sections.map((sec)=>{
                                    return (
                                        invitationData.sectionsStatus[sec]!=="done"?(
                                            <Checkbox onClick={(e) => handleStartScore(e)} value={sec}>
                                            <Typography variant="body" weight="bold" >
                                                {getSectionLabel(sec)}
                                            </Typography>
                                            </Checkbox>
                                        ):""
                                    )
                                })}
                                
                            </div>
                        </Grid>
                        <Grid xs={5}>
                            <div className={classes.sectionToDO}>
                                <Typography >
                                    Section Finished
                                </Typography>
                                {invitationData.sections.map((sec)=>{
                                    return (
                                        <li className={classes.done}>
                                            {invitationData.sectionsStatus[sec]==="done"? getSectionLabel(sec):""}
                                        </li>
                                    )
                                })}
                                
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}

export default OverView;
