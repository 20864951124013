import React, { useEffect, Dispatch, useState } from "react";
import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";

import {
	Fab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Add as AddIcon, ChevronRight } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { searchString } from "../../actions/search";
import useStyles from "./styles";
import { QuestionList } from "../../components/QuestionList";
import { RootState } from "../../reducers";
import { fetchAllQuestions } from "../../actions/question";
import {
	QuestionDispatch,
	Questions,
	SearchAction,
} from "../../model";
import Loader from "../../components/Loader/Loader";
import PageTitle from "../../components/PageTitle/PageTitle";
import _ from "lodash";
import { Filter } from "../../utils/enums";
import invitation from "../../repositories/invitation";


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#F2F7FF",
		fontWeight: "bold",
	},
}))(TableCell);

function getDefaultSorting(search: string): Filter {
	const sorting = new URLSearchParams(search).get("section");
	switch (sorting) {
		case "team":
			return Filter.team;
		case "idea":
			return Filter.idea;
		case "market":
			return Filter.market;
		case "finance":
			return Filter.finance;
		case "competition":
			return Filter.competition;
		case "esg":
			return Filter.esg;
		case "crypto":
			return Filter.crypto;
		default:
			return Filter.default;
	}
}


function Dashboard() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();

	const keys = [
		{id:1,label:""},
		{id:2,label:"Question"},
		{id:3,label:"Platform"},
		{id:4,label:"Type"},
		{id:5,label:"Comment"},
		{id:6,label:"Active"},
		{id:7,label:"Section"},
		{id:8,label:"Crypto"},
		{id:9,label:"Action"},
	];
	const question = useSelector((state: RootState) => state.question);
	const search = useSelector((state: RootState) => state.search);
	const profile = useSelector((state: RootState) => state.firebase.profile);
	const user = useSelector((state: RootState) => state.firebase.auth);
	const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
	const dispatchQuestion = useDispatch<QuestionDispatch>();
	const [filter, setFilter] = useState(Filter.default);
	
	const [questions, setQuestions] = useState<Questions[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	
	useEffect(() => {
		dispatchQuestion(fetchAllQuestions());
	}, [dispatchQuestion]);
	useEffect(() => {
		dispatchSearch(searchString(searchQuery));
	}, [dispatchSearch]);
	useEffect(() => {
		setFilter(getDefaultSorting(location.search));
	}, [location]);
	useEffect(() => {
		setQuestions(question.allQuestions)
	}, [question.allQuestions, search.searchString]);

	if (question.fetching || !questions.length)
		return <Loader text="Fetching your Questions..." />;

	const userName = user.displayName?.split(" ")[0] ?? "there!";

	return (
		<>
			<Fab
				className={classes.fab}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={goToCreate}
				// onClick={()=>{invitation.createTechnicalScore().then((res)=>{
				// 	console.debug('hi finished hemanth');
				// })}}
			>
				<AddIcon />
				New Question
			</Fab>
			<Fab
				className={classes.fab1}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={()=>{history.push('/create-faq')}}
			>
				<AddIcon />
				New Faq Question
			</Fab>
			<>
				<PageTitle
					noMargin
					title={
						new Date().getHours() > 12
							? "Good afternoon " + userName 
							: "Good morning " + userName 
					}
					extra={
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
							}}
						>
						</div>
					}
				/>
				{ <div className={classes.container}>
					
						<TableContainer>
						<Table stickyHeader className="mb-0">
							<TableHead>
								<TableRow>
									{keys.map((key) => (
										<StyledTableCell key={key.id}>{key.label}</StyledTableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
					{questions.filter((v) =>
								v.question
									.toLowerCase()
									.includes(search.searchString.toLowerCase())
							).map((v, index) => {
								if(filter == Filter.team)
									{
										if(v.section=="team")
										{
											return <QuestionList key={index} data={v}/>
										}
									}
									else if(filter == Filter.idea)
									{
										if(v.section=="idea"){
											return <QuestionList key={index} data={v}/>
										}
									}
									else if(filter == Filter.market)
									{
										if(v.section=="market"){
											return <QuestionList key={index} data={v}/>
										}
									}
									else if(filter == Filter.competition)
									{
										if(v.section=="competition"){
											return <QuestionList key={index} data={v}/>
										}
									}
									else if(filter == Filter.finance)
									{
										if(v.section=="finance"){
											return <QuestionList key={index} data={v}/>
										}
									}
									else if(filter == Filter.esg)
									{
										if(v.section=="esg"){
											return <QuestionList key={index} data={v}/>
										}
									}
									else if(filter == Filter.crypto)
									{
										if(v.crypto){
											return <QuestionList key={index} data={v}/>
										}
									}
									else{
										return <QuestionList key={index} data={v}/>
									}
						
					})}
							</TableBody>
						</Table>
					</TableContainer>
					
				</div> }
			</>
		</>
	);

	function goToCreate(e: React.MouseEvent): void {
		e.preventDefault();
		return history.push("/create");
	}
}



export default Dashboard;