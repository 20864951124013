import React, { lazy, Dispatch, Suspense } from "react";

import { useSelector, useDispatch } from "react-redux";
import { LayoutAction } from "../../model";
import { RootState } from "../../reducers";
import { closeModal, showPastDueModal } from "../../actions/layout";
import { CircularProgress } from "@material-ui/core";

const useModal = () => {
  const dispatchLayout = useDispatch<Dispatch<LayoutAction>>();
  const { openModal } = useSelector((state: RootState) => state.layout);
  const profile = useSelector((state: RootState) => state.firebase.profile);
  React.useEffect(() => {
    if (profile.isLoaded && !profile.isEmpty) {
      if ("admin" !== profile.role && "past_due" === profile.planStatus)
        dispatchLayout(showPastDueModal());
    }
  }, [profile, dispatchLayout]);

  return {
    onClose: () => {
      dispatchLayout(closeModal());
    },
    openModal,
  };
};

export const ModalWrapper = () => {
  // Get modal params from redux store

  const { onClose, openModal } = useModal();

  // Import dynamic component according to component path param
  const CustomComponent = lazy(() => import(`${openModal?.type}`));

  return (
    <Suspense fallback={<CircularProgress />}>
      {openModal && (
        <CustomComponent show={!!openModal} onClose={onClose} {...openModal} />
      )}
    </Suspense>
  );
};
