import { firestore } from "../repositories/firebase";
import { Project, Report, IResponse, ShareInfo,ShareReport } from "../model";
import { Invitation } from "../model/invitation";
import { email2ObjectKey, array2Object } from "../utils";
import firebase from "firebase";
import { handleError } from "../utils/decorators";
import { ImportantDevicesOutlined } from "@material-ui/icons";

const projectCollection = firestore.collection("project");
const userCollection = firestore.collection("users");
const favouritesCollection = firestore.collection("favourites");
const invitationsCollection = firestore.collection("invitations");
const shareReportsCollection = firestore.collection("shareReports");
const selectedShareCollection = firestore.collection("selectedShare");
const demoProjectId = "eu6mLyPqUO3FU5mDhdTc";

export class ProjectRepo {
  @handleError("Failed to create project...")
  static async create(
    project: Project,
    invitations: Invitation[],
    selectedPeople: Record<string,string>[],
  ): Promise<string | Error> {
    const result = await projectCollection.add(project);
    await Promise.all(
      selectedPeople.map((v) =>
      selectedShareCollection.add({ ...v, projectID: result.id })
      )
    );
    await Promise.all(
      invitations.map((v) =>
        invitationsCollection.add({ ...v, projectID: result.id })
      )
    );
    return result.id;
  }

  @handleError("Failed to add to favourite project...")
  // static async addToFavrourite(data): Promise<string | Error> {
  static async addToFavrourite(data): Promise<string | Error> {
    const result = await favouritesCollection.add(data);
    console.debug(result,"result in  add to fav repository")
    return result.id;
  }

  // var jobskill_query = db.collection('job_skills').where('job_id','==',post.job_id);
  // jobskill_query.get().then(function(querySnapshot) {
  //   querySnapshot.forEach(function(doc) {
  //     doc.ref.delete();
  //   });
  // });

  @handleError("Failed to remove from favourite project...")
  // static async addToFavrourite(data): Promise<string | Error> {
  static async removeToFavrourite(data): Promise<string | Error> {
    const query = await favouritesCollection.where('UID' ,"==", data.UID).where("projectid","==",data.projectid);

    const result = await query.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        doc.ref.delete();
      });
    });
    console.debug(result,"result in  remove to fav repository")
    if(result==undefined)
      return data.projectid;
    else
      return "result.id";
  }

  @handleError("Failed to fetch all projects...")
  static async fetchAll(uid: string,email: string): Promise<Project[] | Error> {
    //created by me
    // const snapshot = await projectCollection
    //   .where("requester", "==", uid)
    //   .get();
    //privacy- for everyone
    const snapshot2 = await projectCollection
    // .where("privacy", "==", 0)
    .get();
    // const demoDoc = await projectCollection.doc(demoProjectId).get();
    const favColl = await favouritesCollection.where("projectid", "==",demoProjectId ).where('UID','==',uid)
    .get();
    const likes = await favouritesCollection.where("projectid", "==",demoProjectId ).get();
    const shared = await selectedShareCollection.where("email","==",email).get();
    const sharedProjects = await Promise.all(
      shared.docs
      .filter(doc => doc.exists)
      .map(doc=> projectCollection.doc(
        doc.data().projectID).get())
    );
    //shared with me
    const SharedWithme = await Promise.all (sharedProjects
    .filter(doc => doc.exists)
    .map( async (doc) => {
       return ({...doc.data(),id: doc.id,
        // shared:true,
        likes:await this.getUserLikeStatus(doc.id,uid),likesCount: (await this.getProjectLikes(doc.id)).length} as Project) 
      } 
    ));
    // let test=()=>{
    //   let a=favColl.docs.map((d)=>{
    //     return {...d.data()};
    //   })
    //   return (
    //     a
    //   )
    // }
    // let likesArray=()=>{
    //   let a=likes.docs.map((d)=>{
    //     return {...d.data()};
    //   })
    //   return (
    //     a
    //   )
    // }
    const sharedStatus = (id)=>{
     if(SharedWithme.find((obj)=>obj.id===id)){
       return true;
     }
     return false;
    }
     
    // const demo = {
    //   ...demoDoc.data(),
    //   id: demoProjectId,
    //   isDemo: true,
    //   likes:this.getLikeStatus(test(),demoProjectId),
    //   likesCount:likesArray().length,
    // } as Project;
    // const projectsFetched = await Promise.all(snapshot.docs.map(
    //   async (d) => {
    //     return ({ ...d.data(), id: d.id,likes:await this.getUserLikeStatus(d.id,uid),likesCount: (await this.getProjectLikes(d.id)).length} as Project) 
    //   }
    // ));

    const projectsFetched1 = await Promise.all(snapshot2.docs.map(
      async (d) => {
        return ({ ...d.data(), id: d.id,shared:sharedStatus(d.id),likes:await this.getUserLikeStatus(d.id,uid),likesCount: (await this.getProjectLikes(d.id)).length} as Project) 
      }
    ));
  //  const allProjects= _.uniqWith(SharedWithme.concat(projectsFetched1), _.isEqual)
   
    return [...projectsFetched1];
    // return [...projectsFetched, demo,...SharedWithme];
  }


  static async getProjectLikes(projectId)
  {
    const favColl =  await favouritesCollection.where("projectid", "==",projectId ).get();
    let likesArray=()=>{
      let a=favColl.docs.map((d)=>{
        return {...d.data()};
      })
      return (
        a
      )
    }
    return likesArray();
  }

  static async getUserLikeStatus(projectId,uid)
  {
    const favColl =  await favouritesCollection.where("projectid", "==",projectId ).where('UID','==',uid).get();
    let test=()=>{
      let a=favColl.docs.map((d)=>{
        return {...d.data()};
      })
      return (
        a
      )
    }
    return this.getLikeStatus(test(),projectId);
  }



  

static getLikeStatus(liksProjectArray,prjoectId){
  // console.debug("getLikeStatus method called")
  // console.debug(liksProjectArray,"projectArray");
  let likeProjectArray=liksProjectArray.filter((project)=>{
    // console.debug(project,"project in map");
    if(project.projectid==prjoectId)
    return prjoectId;
  })
  // console.debug(likeProjectArray,"projectArray in map");
  if(likeProjectArray.length)
  return true;
  else
  return false;
}

static getRank(score,allProjects,id,startDate){
  var rank= allProjects.length;
  allProjects.forEach(element => {
    if((element.sectionStats?(element.sectionStats.overall?element.sectionStats.overall.avgRating:0):0)<score)
    {
      rank--;
    }
    if(id != element.id)
    {
      if((element.sectionStats?(element.sectionStats.overall?element.sectionStats.overall.avgRating:0):0)==score && element.startDate>startDate)
      {
        rank--;
      }
     
    }
  });
  return rank;
  
}

static getSectionRank(score,id,section,allProjects,startDate){
  var rank= allProjects.length;

      if(section=="team")
      {
        allProjects.forEach(element => {
            if((element.sectionStats?(element.sectionStats.team?element.sectionStats.team.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.team?element.sectionStats.team.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
        });
      }
      if(section=="market")
      {
        allProjects.forEach(element => {
            if((element.sectionStats?(element.sectionStats.market?element.sectionStats.market.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.market?element.sectionStats.market.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
        });
      }
      if(section=="idea")
      {
        allProjects.forEach(element => {
            if((element.sectionStats?(element.sectionStats.idea?element.sectionStats.idea.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.idea?element.sectionStats.idea.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
        });
      }
      if(section=="finance")
      {
        allProjects.forEach(element => {
            if((element.sectionStats?(element.sectionStats.finance?element.sectionStats.finance.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.finance?element.sectionStats.finance.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
        });
      }
      if(section=="esg")
      {
        allProjects.forEach(element => {
          
            if((element.sectionStats?(element.sectionStats.esg?element.sectionStats.esg.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.esg?element.sectionStats.esg.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
          
        });
      }
      if(section=="competition")
      {
        allProjects.forEach(element => {
          
            if((element.sectionStats?(element.sectionStats.competition?element.sectionStats.competition.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.competition?element.sectionStats.competition.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
          
        });
      }
      if(section=="overall")
      {
        allProjects.forEach(element => {
          
            if((element.sectionStats?(element.sectionStats.overall?element.sectionStats.overall.avgRating:0):0)<score)
            {
              rank--;
            }
            if(id != element.id)
            {
              if((element.sectionStats?(element.sectionStats.overall?element.sectionStats.overall.avgRating:0):0)==score && element.startDate>startDate)
              {
                rank--;
              }
            }
          
        });
      }
      
  return rank;
  
}



  @handleError("Failed to fetch your report...")
  static async fetchReport(
    projectId: string,
    project?: Project
  ): Promise<Report | Error> {
    const projectDoc = projectCollection.doc(projectId);
    let fetchedProject: firebase.firestore.DocumentSnapshot | undefined;
    const projectAll = await projectCollection.get();
    const allProjects=projectAll.docs.map(
      (d) => ({ ...d.data(), id: d.id } as Project)
    )
    const likes = await favouritesCollection.where("projectid", "==",projectId ).get();
    let likesArray=()=>{
      let a=likes.docs.map((d)=>{
        return {...d.data()};
      })
      return (
        a
      )
    }
    if (!project) fetchedProject = await projectDoc.get();
    // console.debug(project.sectionStats.overall.avgRating,"project");
    const [responses, invitations] = await Promise.all([
      projectDoc.collection("responses").get(),
      invitationsCollection.where("projectID", "==", projectId).get(),
    ]);

    if (project) {
    const score = project.sectionStats.overall.avgRating;
    var rank = this.getRank(score,allProjects,project.id,project.startDate);
    return {
        project: {
          ...project,
          likesCount:likesArray().length,
        },
        responses: responses.docs.map((d) => d.data() as IResponse),
        invitations: invitations.docs.map(
          (d) => ({ ...d.data(), id: d.id } as Invitation)
        ),
        rank: rank,
      };
    } else if (fetchedProject && fetchedProject.exists) {
    const score = fetchedProject.data().sectionStats.overall.avgRating;  
      var rank = this.getRank(score,allProjects,fetchedProject.id,fetchedProject.data().startDate);
      return {
        project: {
          ...fetchedProject.data(),
          id: fetchedProject.id,
          likesCount:likesArray().length,
        } as Project,
        responses: responses.docs.map((d) => d.data() as IResponse),
        invitations: invitations.docs.map(
          (d) =>
            ({
              ...d.data(),
              id: d.id,
            } as Invitation)
        ),
        rank: rank,
      };
    } else {
      return new Error("This project does not exist");
    }
  }

  @handleError("Failed to delete project...")
  static async deleteProject(projectId: string): Promise<void | Error> {
    const projectDoc = projectCollection.doc(projectId);
    await projectDoc.delete();
  }

  @handleError("Failed to get all projects...")
  static async listProjects() {
    const projectAll = await projectCollection.get();
    const allProjects=projectAll.docs.map(
      (d) => ({ ...d.data(), id: d.id } as Project)
    )
    return allProjects;
  }

  @handleError("Failed to increment project views...")
  static async incrementViews(
    projectId: string,
    section: string
  ): Promise<void | Error> {
    await projectCollection.doc(projectId).update({
      [`sectionStats.${section}.views`]: firebase.firestore.FieldValue.increment(
        1
      ),
    });
  }

  @handleError("Failed to share your project...")
  static async shareProject(
    project: Project,
    info: ShareInfo,
    requestorName: string,
    requestorId: string
  ): Promise<void | Error> {
    const invitations: Invitation[] = info.emails.map((email) => {
      const sections = info.emailSections[email2ObjectKey(email)];
      return {
        projectID: project.id,
        email: email,
        requestor: requestorName,
        requestorId: requestorId,
        company_name: project.company.name,
        sections: sections,
        sentAt: new Date(),
        // dueDate: _.findKey(project.endDate, "toDate")
        //   ? project.endDate?.toDate()
        //   : undefined,
        disclaimerAccepted: false,
        sectionsStatus: array2Object(sections, "sent"),
        pdfLink: project.pdfLinks ?? {},
        formLink: {},
      };
    });

    await Promise.all(
      invitations.map((invite) => invitationsCollection.add(invite))
    );
  }

  @handleError("Failed to share your project...")
  static async shareProjectReport(
    project: Project,
    info: ShareReport,
    requestorName: string,
    requestorId: string
  ): Promise<void | Error> {
    console.debug(info);
    const data={
      projectID: project.id,
      email: info.email[0],
      requestor: requestorName,
      requestorId: requestorId,
      receiver_name: info.firstName,
      project_link: info.project_link,
      company_name: project.company.name,
      sentAt: new Date(),
    }
    
    await shareReportsCollection.add(data)
    // await Promise.all(
    //   invitations.map((invite) => invitationsCollection.add(invite))
    // );
  }
}
