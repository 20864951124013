import { Reducer } from "react";
import {
  ReminderState,
  ReminderAction,
  ReminderActions,
} from "../model/reminder";

const initialState: ReminderState = {
  sending: false,
  sent: false,
};

export const reminderReducer: Reducer<ReminderState, ReminderAction> = (
  state: ReminderState = initialState,
  action: ReminderAction
): ReminderState => {
  switch (action.type) {
    case ReminderActions.SEND:
      return { ...state, sending: true };
    case ReminderActions.SENT:
      return { ...state, sending: false, sent: true };
    case ReminderActions.ERROR:
      return { ...state, sending: false, error: action.payload };
    case ReminderActions.RESET:
      return { ...state, sending: false, error: undefined, sent: false };
    default:
      return state;
  }
};
