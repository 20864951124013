import { firestore } from "../repositories/firebase";
import { Questions, ScoreForm,Faqs } from "../model";
import { Invitation } from "../model/invitation";
import { email2ObjectKey, array2Object } from "../utils";
import firebase from "firebase";
import { handleError } from "../utils/decorators";

const questionCollection = firestore.collection("questions");
const faqsCollection = firestore.collection("faqs");
const responseCollection = firestore.collection("submissions");

export class QuestionRepo {

  @handleError("Failed to fetch all questions...")
    static async fetchAllQuestions(): Promise<Questions[] | Error> {
      const data = await questionCollection.get();
      const questionsFetched = await Promise.all(data.docs.map(
        async (d) => {
          return ({ ...d.data(), id: d.id } as Questions) 
        }
      ));
      return questionsFetched;
      }

    @handleError("Failed to fetch all questions...")
    static async fetchFaqs(): Promise<Faqs[] | Error> {
      const data = await faqsCollection.get();
      const questionsFetched = await Promise.all(data.docs.map(
        async (d) => {
          return ({ ...d.data(), id: d.id } as Questions) 
        }
      ));
      return questionsFetched;
      }

  @handleError("Failed to create question...")
    static async create(
        question: Questions,
      ): Promise<string | Error> {
        question['date'] = new Date();
        const result = await questionCollection.add(question);
        return result.id;
      }

  @handleError("Failed to create question...")
  static async createFaq(
      data: Faqs,
    ): Promise<string | Error> {
      data['date'] = new Date();
      const result = await faqsCollection.add(data);
      return result.id;
    }

  @handleError("Failed to update question...")
    static async update(
        data: Record<string,any>,
        id: string
      ): Promise<void | Error> {
        const result = await questionCollection.doc(id).update(data);
      }

  @handleError("Failed to update question...")
    static async updateFaq(
        data: Record<string,any>,
        id: string
      ): Promise<void | Error> {
        const result = await faqsCollection.doc(id).update(data);
      }

  @handleError("Failed to delete question...")
    static async delete(
        id: string
      ): Promise<void | Error> {
        const result = await questionCollection.doc(id).delete();
      }

  @handleError("Failed to delete question...")
  static async deleteFaq(
      id: string
    ): Promise<void | Error> {
      const result = await faqsCollection.doc(id).delete();
    }
  @handleError("Failed to fetch question...")
    static async fetchQuestion(
          id: string
        ): Promise<Questions> {
          const result = await questionCollection.doc(id).get();
          var responses = await responseCollection.where("form_response.hidden.section","==",result.data().section).get();
          var responseData = responses.docs.map((d)=>{
            return {...d.data()} as ScoreForm;
          })
          var data = result.data() as Questions
          return {...data,responses:responseData};
        }
  
        @handleError("Failed to fetch question...")
        static async fetchFaq(
              id: string
            ): Promise<Questions> {
              const result = await faqsCollection.doc(id).get();
              var data = result.data() as Questions
              return {...data};
            }

  }
