import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";
import { ScoreForm } from ".";

export interface Questions {
    id?: string;
    question: string;
    type: string;
    comment: boolean;
    active: boolean;
    crypto: boolean;
    section: string;
    stage: string;
    platform: string;
    context: string;
    responses?: ScoreForm[];
  }

  export interface Faqs {
    id?: string;
    question: string;
    context: string;
    platform: string;
    // responses?: ScoreForm[];
  }

  export interface TechnicalFactor {
    id?: string;
    value: string;
    section: string;
    active: boolean;
    label: string;
    weight: number;
    options: Record<string,any>[];
    did?: string;
    // responses?: ScoreForm[];
  }

  export interface Option {
    id?: string;
    value: string;
    score: number;
    comment?: string;
    // responses?: ScoreForm[];
  }

  
  

  export interface QuestionState {
    fetching: boolean;
    creating: boolean;
    createError?: Error | string;
    error?: any;
    allQuestions: Questions[] | undefined;
  }
  

  export enum QuestionActions {
    FETCH = "FETCH_ALL_QUESTIONS",
    FETCHED_QUESTIONS = "FETCHED_QUESTIONS",
    FETCH_ERROR = "FETCH_QUESTIONS_ERROR",
    CREATE = "CREATE_QUESTION",
    CREATE_SUCCESS = "CREATE_QUESTION_SUCCESS",
    CREATE_ERROR = "CREATE_QUESTION_ERROR",
  }
  
  interface QuestionActionsType<T, P = {}> {
    type: T;
    payload?: P;
  }
  
  export type QuestionAction =
    | QuestionActionsType<typeof QuestionActions.FETCH>
    | QuestionActionsType<typeof QuestionActions.CREATE>
    | QuestionActionsType<typeof QuestionActions.CREATE_SUCCESS>
    | QuestionActionsType<typeof QuestionActions.CREATE_ERROR, Error | string>
    | QuestionActionsType<
        typeof QuestionActions.FETCHED_QUESTIONS,
        Questions[]
      >
    | QuestionActionsType<typeof QuestionActions.FETCH_ERROR, any>
  
  export type QuestionThunk = ThunkAction<
    void,
    RootState,
    unknown,
    QuestionAction
  >;
  export type QuestionDispatch = ThunkDispatch<
    RootState,
    any,
    QuestionAction
  >;