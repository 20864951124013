import { Link } from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import { NotificationsDispatch, INotification } from "../../model";
import {
  markNotificationsAsRead,
  fetchNotifications,
} from "../../actions/notifications";
import Dot from "../../components/Sidebar/components/Dot";
import moment from "moment";
import useStyles from "./styles";
import Loader from "../../components/Loader/Loader";
import FadeIn from "react-fade-in";

function Notifications() {
  const classes = useStyles({ shaded: false });
  const notifications = useSelector((state: RootState) => state.notifications);
  const dispatch = useDispatch<NotificationsDispatch>();

  React.useEffect(() => {
    if (!notifications.fetching) dispatch(fetchNotifications(true));
    return () => {
      dispatch(markNotificationsAsRead());
    };
  }, [dispatch, notifications.fetching]);

  if (notifications.fetching) return <Loader />;

  return (
    <div className={classes.root}>
      <FadeIn>
        <PageTitle noMargin title="Notifications" />
        <Typography weight="bold" variant="h4" htmlColor="black">
          New
        </Typography>
        <div
          className={
            notifications.unreadNotifications.length === 0
              ? classes.centered
              : classes.section
          }
        >
          {notifications.unreadNotifications.length === 0 && (
            <Typography>No new notifications!</Typography>
          )}
          {notifications.unreadNotifications.map((n, index) => (
            <NotificationWidget
              key={index}
              notification={n}
              shaded={index % 2 === 0}
            />
          ))}
        </div>
        <Typography weight="bold" variant="h4" htmlColor="black">
          Earlier
        </Typography>
        <div
          className={
            notifications.oldNotifications.length === 0
              ? classes.centered
              : classes.section
          }
        >
          {notifications.oldNotifications.length === 0 && (
            <Typography>No earlier notifications!</Typography>
          )}
          {notifications.oldNotifications.map((n, index) => (
            <NotificationWidget
              key={index}
              notification={n}
              shaded={index % 2 === 0}
            />
          ))}
        </div>
      </FadeIn>
    </div>
  );
}

interface NotificationProps {
  notification: INotification;
  shaded: boolean;
}

function NotificationWidget({ notification, shaded }: NotificationProps) {
  const classes = useStyles({
    seen: notification.seen,
    shaded: shaded,
  });

  return (
    <Link href={notification.deepLink} className={classes.link}>
      <div className={classes.container}>
        <div className={classes.bodyContainer}>
          {!notification.seen && <Dot size="large" color="primary" />}
          <Typography
            variant="subtitle2"
            weight={notification.seen ? undefined : "bold"}
            className={classes.bodyText}
          >
            {notification.body}
          </Typography>
        </div>
        <Typography className={classes.bodyText}>
          {moment(notification.sentAt.toDate()).fromNow()}
        </Typography>
      </div>
    </Link>
  );
}

export default Notifications;
