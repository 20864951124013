interface Modal {
  type: ModalType;
  [x: string]: any;
}

export interface LayoutState {
  isSidebarOpened: boolean;
  openModal?: Modal;
}

export enum LayoutActions {
  TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
  TOGGLE_MODAL = "TOGGLE_MODAL",
}

export enum ModalType {
  DELETE = "./DeleteDialog",
  SHARE = "./ShareDialog",
  SHAREREPORT = "./ShareDialogReport",
  PAST_DUE = "./PastDueDialog",
  UPGRADE = "./UpgradeRequiredDialog",
  FEEDBACK = "./FeedbackDialog",
  REMINDER = "./ReminderDialog",
  PDF = "./pdfDialog"
}

interface LayoutActionsType<T, P = {}> {
  type: T;
  payload?: P;
}

export type LayoutAction =
  | LayoutActionsType<LayoutActions.TOGGLE_SIDEBAR>
  | LayoutActionsType<LayoutActions.TOGGLE_MODAL, Modal>;
