import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";
import { firestore } from "firebase";

export interface IResponse {
  comment?: string;
  question: string;
  score: number;
  section: string;
  sentiment?: number;
  ref: firestore.DocumentReference;
}

export interface Hidden {
  company: string;
  projectid: string;
  section: string;
  invitationid: string;
}

interface FieldExtras {
  title: string;
  properties: Record<string, unknown>;
}

export interface Field {
  id: string;
  type: string;
  // ref: string;
  comment?: boolean;
}

export interface Definition {
  id: string;
  title: string;
  fields: (Field & FieldExtras)[];
}

export interface Answer {
  type: string;
  number: number;
  field: Field;
  text: string;
  comment?: [] ;
}

export interface FormResponse {
  form_id: string;
  token: string;
  landed_at: string;
  submitted_at: string;
  hidden: Hidden;
  definition: Definition;
  answers: Answer[];
}

export interface ScoreForm {
  event_id: string;
  event_type: string;
  form_response: FormResponse;
  user?: string;
}

export interface ReformattedAnswer {
  score: number;
  comment: string;
  question?: string;
  fieldMeta?: Field;
  sentiment?: number;
  top?: boolean;
  section: string;
}

export interface Reformatted {
  projectId: string;
  section: string;
  invitationId: string;
  answers: ReformattedAnswer[];
}


export interface ResponseState {
  fetching: boolean;
  error?: any;
  responses: IResponse[];
}

export enum ResponseActions {
  FETCH = "FETCH_ALL_ResponseS",
  FETCHED_RESPONSES = "FETCHED_ResponseS",
  FETCH_ERROR = "FETCH_ResponseS_ERROR",
}

interface ResponseActionsType<T, P = {}> {
  type: T;
  payload?: P;
}

export type ResponseAction =
  | ResponseActionsType<typeof ResponseActions.FETCH>
  | ResponseActionsType<typeof ResponseActions.FETCHED_RESPONSES, IResponse[]>
  | ResponseActionsType<typeof ResponseActions.FETCH_ERROR, any>;

export type ResponseThunk = ThunkAction<
  void,
  RootState,
  unknown,
  ResponseAction
>;
export type ResponseDispatch = ThunkDispatch<RootState, any, ResponseAction>;
