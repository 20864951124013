import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {

        '& .MuiBackdrop-root': {
            backgroundColor: "rgba(0, 0, 0, 0.5) !important"

        },

    },
    container: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
    },
    logotypeContainer: {
        backgroundColor: theme.palette.primary.main,
        width: "60%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    logotypeImage: {
        width: 165,
        marginBottom: theme.spacing(4),
    },
    logotypeText: {
        fontFamily: "'Manrope', sans-serif",
        fontWeight: "bold",
        color: "white",
        fontSize: 84,
        [theme.breakpoints.down("md")]: {
            fontSize: 48,
        },
    },
    textFieldUnderline: {
        "&:before": {
            borderBottomColor: theme.palette.primary.light,
        },
        "&:after": {
            borderBottomColor: theme.palette.primary.main,
        },
        "&:hover:before": {
            borderBottomColor: `${theme.palette.primary.light} !important`,
        },
    },
    textField: {
        borderBottomColor: theme.palette.background.light,
    },
    formContainer: {
        width: "40%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
    },
    form: {
        width: 320,
        display: "flex",
        justifyContent: "center",
        height: "35%",
        alignItems: "center",
    },
    copyright: {
        marginTop: theme.spacing(4),
        whiteSpace: "nowrap",
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            bottom: theme.spacing(2),
        },
    },
    submitButton: {
        background: "#137DFB",
        border: "1px solid #137DFB",
        fontSize: "13px",
        color: "#fff",
        width: 30 + "%",
        padding: "0.4rem !important",
        marginTop: "1rem !important"
    },
    greeting: {
        fontSize: "2rem",
        marginTop: "2rem",
    },
    loginLink: {
        position: "absolute",
        bottom: "25px",
        right: "110px",
        fontSize: "13px"
    },
    forgotpara: {
        fontSize: "14px",
        fontWeight: "600",
        marginTop: "7rem",
    },
    forgotparas: {
        fontSize: "12px",
        margin: "20px",
        width: 75 + "%",
        textAlign: "center"

    },


}));
