import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color:"#AEAEAE",
  },
  title: {
    flexGrow: 1,
    fontSize:24,
    fontWeight:600,
    color:"#AEAEAE",
  },

  //createNotification
  input:{
    background: "#f5f5f5",
  },
  inputFields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: theme.spacing(4),
  },
  sectionTitles: {
    paddingRight: theme.spacing(2),
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textAlign: "left",
    width: 300,
    color: "rgb(138 136 136)",
  },
  detailQuestion:{
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #BEBEBE",
    height: "6rem",
    padding: "0 20px !important",
    background: "#f5f5f5",
    boxSizing: "border-box",
    borderColor: "#40a9ff"
  },
  selected:{
 
  background: "#f5f5f5",
  },
  context:{
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #BEBEBE",
    boxSizing: "border-box",
    height: "15rem",
    background: "#f5f5f5",
    padding: "0 20px !important",
    borderColor: "#40a9ff"
  },
  radioButtonBox:{
    color: "rgb(138 136 136)",
    '& label > span':{
      padding:"0px !important",
      paddingLeft: "9px !important",
      fontSize: "0.9rem"
    },
    '& label > span >span>div>svg':{
      width: "0.8em",
      height: "1em"
    }
  },
  submitButton:{
    border: "1px solid",
    padding: "7px 35px 7px 35px",
    borderRadius: "5px",
    fontSize: "0.8rem",
    color: "#fff",
    background: "#0c0c52",
    marginTop: "2rem",
    '&:hover':{
      color: "#fff",
      background: "#0c0c52",
    }
  },
  cancelButton:{
    border: "1px solid",
    padding: "7px 27px 7px 27px",
    borderRadius: "5px",
    fontSize: "0.8rem",
    color: "#fff",
    background: "#0c0c52",
    marginTop: "2rem",
    '&:hover':{
      color: "#fff",
      background: "#0c0c52",
    }
  },
  sectionTitleDescription: {
    // paddingRight:theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textAlign: "left",
    width: 300,
    color: "rgb(138 136 136)",
    marginBottom:"15px"
    // color: theme.palette.blackColor.main,
 
  },
  companyDescription: {
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #BEBEBE",
    boxSizing: "border-box",
    height: "6rem",
    background: theme.palette.background.default,
    padding: "0 20px"
  },

  subtitle:{
    flexGrow: 1,
    color: "#414141",
    fontSize: "13px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "20px"
  },
  heading:{
    color:"#000000",
    fontWeight:500,
    textAlign:"left",
  },
  comp_details:{
    width: "40vw",
    marginTop: "8%",
    height: "100%",
    marginLeft:27+"%",
  },
  toolbar: {
    minHeight: 100,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepperContainer: {
    textAlign:"center",
    marginTop: theme.spacing(2),
    // width: "50vw",
    width: "60vw",
    height: "100%",
    fontSize:32,
    fontFamily:"Montserrat",
    fontWeight:"bold",
    marginBottom: "1rem"
   
  },
  projectHead:{
    color: "#000000"
  },
  submitDetail:{
    width: "150px",
    height: "40px",
    background: "#137DFB",
    border: "1px solid #137DFB",
    boxSizing: "border-box",
    borderRadius: "10px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#F8FAFD !important",
    marginTop: "2rem"
  },
  stepper: {},
  // steps: {
  //   backgroundColor: theme.palette.background.main,
  //   minWidth: "35vw",
  // },
}));
