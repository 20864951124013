import React, { Dispatch, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button as MButton,
} from "@material-ui/core";
import useStyles from "./styles";
import { useHistory , useParams} from "react-router-dom";
import {QuestionNewForm}  from "./components";
import { Button } from "../../components/Wrappers/Wrappers";
import FadeIn from "react-fade-in";
import { QuestionDispatch, Questions } from "../../model";
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import { QuestionRepo } from "../../repositories/question";

const validationSchema = Yup.object().shape<Questions>({
    question: Yup.string().required("this field is necessary"),
    type: Yup.string().required("this field is necessary"),
    comment: Yup.boolean(),
    active: Yup.boolean(),
    crypto: Yup.boolean(),
    section: Yup.string().required("this field is necessary"),
    stage: Yup.string().required("this field is necessary"),
    platform: Yup.string().required("this field is necessary"),
    context: Yup.string(),
  
});



function Edit() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [data,SetData]= React.useState<Questions>();
  useEffect(() => {
    const res = QuestionRepo.fetchQuestion(id).then((result)=>{
        SetData(result);
    })
}, []);
  if (!data) return <Loader text="fetching the question..." />;
//   if (createError) return <ErrorDisplay error={createError} />;

  const intitialValues: Questions = {
    question: data.question,
    type: data.type,
    comment: data.comment,
    active: data.active,
    crypto: data.crypto,
    section: data.section,
    stage: data.stage,
    platform: data.platform,
    context: data.context,
  };

  return (
    <div className={classes.root}>
      <Formik<Questions>
        initialValues={intitialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
            console.debug("hi")
            console.debug(values)
          QuestionRepo.update(values,id).then((res)=>{
              history.push("/app/dashboard")
          })
        }}
      >
        {(formik) => (
          <Form className={classes.root}>
            <FadeIn>
              {/* <AppBar elevation={0} position="static" color="transparent">
                <Toolbar className={classes.toolbar}>
                  <MButton color="inherit" onClick={() => history.goBack()}>
                    Cancel
                  </MButton>
                </Toolbar>
              </AppBar> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                  <QuestionNewForm/>
                  {/* <QuestionForm/> */}
              </div>
            </FadeIn>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Edit;
