import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainers: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
  },
  logotypeContainer: {
    width: "100%",
    display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
  
  },
  logotypeImage: {
    width: 10+"%",
    height: 15+"%",
    marginBottom: theme.spacing(4),
    marginTop: "1rem",
  },
  logotypeText: {
    fontFamily: "'Manrope', sans-serif",
    fontWeight: "bold",
    color: "#000",
    fontSize: 20,
    marginTop: "2rem",
    marginLeft: "1.3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
 


//   ______________________________
detailsContainer:{
    width: "75%",
    height: "71%",
    margin: "auto",
    backgroundColor: "#fff",
    borderRadius: "10px",
    textAlign: "center",
    padding: "3rem",
    marginTop: "3rem",
    '& p:nth-child(1)':{
        fontSize: "2.5rem",
        color: "#137DFB",
        paddingTop: "2rem",
    },
    '& p:nth-child(2)':{
        fontSize: "1.1rem",
        color: "#137DFB",
        paddingTop: "2rem",
    },
    '& p:nth-child(3)':{
        fontSize: "0.8rem",
        // color: "#137DFB",
        paddingTop: "4rem",
    },
    '& button':{
        marginTop: "3rem",
        border: "1px solid #137DFB",
        background: "#137DFB",
        color: "#fff",
        fontSize: "12px",
        padding: "0.5rem 1.5rem 0.5rem 1.5rem",
    }
},

// ___________________________________

formContainer: {
    width: "100%",
    height: "100vh",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form: {
    width: 320,
  },
  formContainerData:{
      width: "100%",
      textAlign: "center",
    '& p:nth-child(1)':{
        fontSize: "2rem",
        // color: "#137DFB",
        paddingTop: "2rem",
        fontWeight: "600"
    },
    '& p:nth-child(2)':{
        fontSize: "1.6rem",
        // color: "#137DFB",
        // paddingTop: "2rem",
    },
    '& p:nth-child(3)':{
        fontSize: "0.9rem",
        // color: "#137DFB",
        lineHeight: "2"
    },
  },
  sectionToDO:{
    padding: "1rem",
    margin: "2rem",
    height: "52vh",
    color: "#137DFB",
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    '& p:nth-child(1)':{
        margin: "1rem",
       
    },
    '& label:nth-child(1), label:nth-child(2), label:nth-child(3), label:nth-child(4), label:nth-child(5), label:nth-child(6)':{
        margin: "1rem",
        width: "100%"
    },
  },
  overlay:{
    width: "57%",
    height: "100vh",
    position: "absolute",
    background: "#fff",
    zIndex: "999",
    opacity: "0.5",
    top: "0",
    right: "0",
  },

  done:{
    fontFamily:"Montserrat",
    fontSize:"16px",
    fontWeight: "bold",
    marginBottom: "20px",

}
  
}));
