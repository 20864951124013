import { Reducer } from "react";
import {
  FeedbackState,
  FeedbackAction,
  FeedbackActions,
} from "../model/feedback";

const initialState: FeedbackState = {
  sending: false,
  sent: false,
};

export const feedbackReducer: Reducer<FeedbackState, FeedbackAction> = (
  state: FeedbackState = initialState,
  action: FeedbackAction
): FeedbackState => {
  switch (action.type) {
    case FeedbackActions.SEND:
      return { ...state, sending: true };
    case FeedbackActions.SENT:
      return { ...state, sending: false, sent: true };
    case FeedbackActions.ERROR:
      return { ...state, sending: false, error: action.payload };
    case FeedbackActions.RESET:
      return { ...state, sending: false, error: undefined, sent: false };
    default:
      return state;
  }
};
