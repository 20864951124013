import React, { Dispatch, useEffect,useState } from "react";
import {
	Button,TextField
} from "@material-ui/core";
import useStyles from '../styles';
import Modal from "antd/lib/modal/Modal";
function FactorModal(props)
{
   const[value,setValue]=useState('');
   const[score,setScore]=useState('');
   const[comment,setComment]=useState('');
    const classes = useStyles();
    const handleClose = ()=>{
        setValue('')
        setScore('')
        setComment('')
        props.setOpen(false)
    }
    const submitForm = ()=>{
        
        props.setOption([...props.option,{value:value,score:parseInt(score),comment:comment}]);
        setValue('')
        setScore('')
        setComment('')
        props.setOpen(false);
        
    }
return(
    <>
        <Modal
          title={"Add"}
          centered
          className={classes.modalTeam}
          zIndex={2}
          visible={props.open}
            onCancel={handleClose}
          footer={[
            <Button key="back"
              onClick={handleClose}
            >
              Cancel
            </Button>,
            <Button onClick={submitForm}
            >
              Submit
            </Button>
          ]}
        >
            <div>
            <TextField id="outlined-basic" value={value} style={{width:'100%',marginBottom:10}} label="value" variant="outlined" onChange={(e)=>{setValue(e.target.value)}} />
            </div>
            <div>
            <TextField id="outlined-basic" value={score} label="score" style={{marginBottom:10}} variant="outlined" onChange={(e)=>{setScore(e.target.value)}}/>
            </div>
            <div>
            <TextField id="outlined-basic" value={comment} label="comment" style={{width:'100%'}} variant="outlined" onChange={(e)=>{setComment(e.target.value)}}/>
            </div>
        </Modal>
</>
)
}

export default FactorModal;