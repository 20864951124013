import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
 
    container:{
        marginTop:20,
    },
    background:{
        background: "#0c0c52",
        color: "white",
        marginTop:10,
        marginLeft:5,
    }
}));
