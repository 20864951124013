import { QuestionState, QuestionAction, QuestionActions } from "../model/question";
import { Questions } from "../model";
import { Reducer } from "react";

const initialState: QuestionState = {
    fetching: false,
    creating: false,
    allQuestions: [],
  };

  export const questionsReducer: Reducer<
  QuestionState,
  QuestionAction
> = (
  state: QuestionState = initialState,
  action: QuestionAction
): QuestionState => {
  switch (action.type) {
    case QuestionActions.CREATE:
      return { ...state, creating: true, createError: undefined };
    case QuestionActions.CREATE_SUCCESS:
      return { ...state, creating: false, createError: undefined };
    case QuestionActions.CREATE_ERROR:
      return { ...state, creating: false, createError: action.payload };
    case QuestionActions.FETCH:
      return { ...state, fetching: true };
    case QuestionActions.FETCH_ERROR:
      return { ...state, fetching: false, error: action.payload };
    case QuestionActions.FETCHED_QUESTIONS:
      return {
        ...state,
        fetching: false,
        allQuestions: action.payload,
      };
    default:
      return state;
  }
};
