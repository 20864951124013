import React, { ChangeEvent, Dispatch, useEffect, useState } from "react";
import { Field, Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import { useCounter } from "ahooks";
import { Close as CloseIcon } from "@material-ui/icons";
import { app } from "../../repositories/firebase";

import useStyles from "./components/styles";
import { useHistory } from "react-router-dom";
import { Button, FormControl, Grid, Input, Button as MButton, MenuItem, OutlinedInput, Radio, Select } from "@material-ui/core";
import { Typography } from "../../components/Wrappers/Wrappers";
// import { Select, Input, AutoComplete, Checkbox } from "formik-antd";

// import {QuestionNewForm}  from "./components";
// import {QuestionForm}  from "./components";
// import { Button } from "../../components/Wrappers/Wrappers";
import FadeIn from "react-fade-in";
import { ProjectDispatch, QuestionDispatch, Questions } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
// import {createQuestion} from "../../actions/question";
import { NotificationV1Model } from "../../model/notificationV1";
import axios from "axios";
import { getProject } from "../../service";
import { fetchAllProjects } from "../../actions/project";
import TEditor from "./TEditor";
import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CreateNotification() {
    const classes = useStyles();
    const history = useHistory();
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")
    const [platform, setPlatform] = useState("")
    const [img, setImg] = useState("")
    const [user, setuser] = useState("")
    const [companyId, setCompanyId] = useState()
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [titleError, setTitleError] = useState(false)
    const [textError, setTextError] = useState(false)
    const [companyName, setCompanyName] = useState()
    const [companyOption, setCompanyOption] = useState([])
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch<ProjectDispatch>();
    const project = useSelector((state: RootState) => state.project);
    const instanceurl = app.options.apiUrl
    // console.debug("useSelector", project)
    console.debug("chviywvkh", app)
    const { creating, createError } = useSelector(
        (state: RootState) => state.question
    );
    //   const dispatch = useDispatch<QuestionDispatch>();

    function createQuestion() {

        let data = {}
        if (title) {
            data['title'] = title
        } else {
            setTitleError(true)
        }
        if (text) {
            let d = text.replace(/<[^>]+>/g, '');
            data['notificationText'] = d
        } else {
            setTextError(true)
        }
        if (companyId) {
            data['projectId'] = companyId
        }
        // if(selectedCompany){
        //     if(selectedCompany.cId && selectedCompany.cName && selectedCompany.cLogo){
        //         data['projectId'] = selectedCompany.cId
        //         data['projectName'] = selectedCompany.cName
        //         data['projectLogo'] = selectedCompany.cLogo
        //     }
        // }
        if (title && text) {
            console.debug("createQuestion:data", data)
            setLoader(true)
            axios.post(
                //for main server
                instanceurl + ".cloudfunctions.net/addNotificationV1",
                //for test instance
                // "https://us-central1-invluencer-test.cloudfunctions.net/addNotificationV1",
                data,
            ).then(function (response) {
                console.debug(response);
                setLoader(false)
                history.push("/app/notifications")
            }).catch(function (error) {
                // console.debug("response",response);
                console.debug(error);


                return error.response;
            });
        } else {
            setTitleError(true)
            setTextError(true)
        }
    }

    useEffect(function () {
        dispatch(fetchAllProjects());
    }, [])
    const formatedProjectData = (d) => {
        let data = {
            "id": d.id,
            "company": d.company

        }

        return data
    }

    const handleEditorChange = (event, editor) => {
        // Remove <p> tags from content
        // const updatedContent = content.replace(/&amp;rsquo;/g, "'");
        // console.debug("handleEditorChange:content", updatedContent)
        // setText(updatedContent);
        // Additional logic or actions you want to perform with the updated content
        const data = editor.getData();
        setText(data);
    };

    useEffect(function () {
        if (project.allProjects) {
            let d = project.allProjects.map(c => formatedProjectData(c)
            )
            setCompanyOption(d)
            console.debug("fetchAllProjects", d)
        }
    }, [project.allProjects.length != 0])

    function onChangePlatform(e) {
        if (e.target.name == "rapid") {
            setPlatform("")
            setPlatform(e.target.value)
        }
        if (e.target.name == "expert") {
            setPlatform("")
            setPlatform(e.target.value)
        }
        if (e.target.name == "both") {
            setPlatform("")
            setPlatform(e.target.value)
        }
    }

    const handleChange = (e) => {
        // setSelectedCompany(e.target.value)
        setCompanyId(e.target.value)
        console.debug("handleChange", e.target)
    }

    const onchangeHandlerImage = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            console.debug("onchangeHandlerImage:target", e.target.files[0].name)
            setImg(e.target.files[0].name);
        }
    };

    if (loader == true) return <Loader text="Creating notification..." />;
    //   if (createError) return <ErrorDisplay error={createError} />;

    return (
        // <div className={classes.root}>

        <Grid container style={{ padding: "8rem", paddingTop: "5rem" }}>
            <Grid item xs={6} style={{ paddingRight: "7rem" }}>
                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Notification Title *
                    </Typography>
                    <Input
                        className={classes.detailQuestion}
                        name="question"
                        onChange={(e) => setTitle(e.target.value)}
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2rem", marginTop: "1rem" }}
                        placeholder="Enter notification title here..."
                    />

                    {titleError == true ? <span style={{ color: "red" }}>Please Enter Title</span> : ""}
                </div>
                <div className={classes.inputField} >
                    <Grid item >
                        <Grid container >
                            <Grid item xs={6}>
                                <Typography variant="h4" className={classes.sectionTitleDescription}>
                                    Notification Title *
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.sectionTitleDescription}>{'(max 330 characters)'}</Typography>
                            </Grid>
                        </Grid>
                        {text.length > 330 &&
                            <Typography className={classes.error}>
                                No. of characters exceeded 330
                            </Typography>
                        }
                        <CKEditor
                            editor={ClassicEditor}
                            data={text}
                            // onReady={editor => {
                            //     // You can store the "editor" and use when it is needed.
                            //     console.log('Editor is ready to use!', editor);
                            // }}
                            onChange={handleEditorChange}
                        // onBlur={(event, editor) => {
                        //     console.log('Blur.', editor);
                        // }}
                        // onFocus={(event, editor) => {
                        //     console.log('Focus.', editor);
                        // }}
                        />
                        {/* <Editor
                            // key={""}
                            // key={props.version + modelKey}
                            apiKey='cqcs2kis87q9a5elaqbtrdbbdt08j41o80kie5n5cwrgaftk'
                            initialValue={""}
                            init={{
                                height: 500,
                                width: "100 %",
                                menubar: "file edit view insert format tools table tc help",
                                plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                ],
                                mobile: {
                                    plugins: [
                                        "advlist autolink lists link image charmap print preview anchor",
                                        "searchreplace visualblocks code fullscreen",
                                        "insertdatetime media table paste code help wordcount",
                                    ],
                                },
                                toolbar:
                                    "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                images_upload_url: process.env.REACT_APP_BASEURL + "api/v1/associate-feedback-image",
                                init_instance_callback: function (editor) {
                                    var freeTiny = document.querySelector(
                                        ".tox-notifications-container"
                                    );
                                    //  freeTiny.style.display = "none";
                                },
                                content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                            onEditorChange={handleEditorChange}
                            textareaName={text}
                            value={text}
                            onBlur={false}
                        /> */}
                        {/* <Editor
                            id='article_body'
                            apiKey='cqcs2kis87q9a5elaqbtrdbbdt08j41o80kie5n5cwrgaftk'
                            init={{
                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'emoticons'],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help | emoticons ',
                                menubar: true,
                                statusbar: true,

                            }}
                            onEditorChange={handleEditorChange}
                            textareaName={text}
                            value={text}
                            onBlur={false}
                        /> */}
                    </Grid>
                    {/* <TEditor name={text} label={'Notification Description'} setText={setText} /> */}

                    {textError == true ? <span style={{ color: "red" }}>Please Enter Description in body</span> : ""}
                </div>
            </Grid>
            <Grid item xs={6} style={{ paddingRight: "7rem" }}>
                {/* <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem" }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Platform
                    </Typography>
                    <Grid container style={{ paddingTop: "0.6rem" }}>
                        <Grid item xs={4} className={classes.radioButtonBox}>

                            <Radio color="primary" name="rapid" onChange={onChangePlatform} value={"rapid"} />
                            <label className={classes.label}>
                                i3D Rapid
                            </label>
                        </Grid>
                        <Grid item xs={4} className={classes.radioButtonBox}>
                            <Radio color="primary" name="expert" onChange={onChangePlatform} value={"expert"} />
                            <label className={classes.label}>
                                i3D Expert
                            </label>
                        </Grid>
                        <Grid item xs={4} className={classes.radioButtonBox}>

                            <Radio color="primary" name="both" onChange={onChangePlatform} value={"both"} />
                            <label className={classes.label}>
                                both
                            </label>
                        </Grid>
                    </Grid>
                </div> */}
                <FormControl variant="standard" sx={{ m: 3, minWidth: 120 }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Select Company
                    </Typography>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={companyName}
                        input={<OutlinedInput classes={{ input: classes.input }} />}
                        label="Company"
                        className={classes.selected}
                        onChange={handleChange}
                        style={{ height: '40px', marginTop: "15px", background: "#f5f5f5" }}

                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                        {companyOption ? companyOption.map(c => (
                            <MenuItem value={c.id}>
                                {c.company ? c.company.name : ""}
                            </MenuItem>
                            // <MenuItem value={{cId:c.id,cName:c.company.name,cLogo:c.company.logo}}>
                            //     {c.company ? c.company.name : ""}
                            // </MenuItem>
                        )) : []
                        }

                    </Select>

                </FormControl>
                {/* <div className={classes.inputField} style={{ width: "100%", marginTop: "2px" }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Notification Image
                    </Typography>
                    <Input
                        type="file"
                        className={""}
                        name="notificationImage"
                        onChange={onchangeHandlerImage}
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2px", marginTop: "2px" }}
                        placeholder="provide question about the question.."
                    />

                </div> */}
            </Grid>

            <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem", textAlign: "center" }}>
                <Button
                    color="primary"
                    size="small"
                    type="button"
                    className={classes.submitButton}
                    onClick={createQuestion}
                >
                    Save
                </Button>
                <MButton className={classes.cancelButton} style={{ marginLeft: "2rem" }} color="inherit" onClick={() => history.goBack()}>
                    Cancel
                </MButton>
            </div>
        </Grid>
        //    </div>
    );
}

export default CreateNotification;
