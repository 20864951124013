import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    // borderRadius: "0px 7px 7px 0px",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  linkActive: {
    backgroundColor: theme.palette.background.light,
    // borderRadius: "0px 7px 7px 0px",
    borderRight:"3px solid #137DFB"
  },
  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      backgroundColor: "#FFFFFF",
    },
  },
  linkIcon: {
    // marginRight: theme.spacing(1),
    // color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
    // width: 24,
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "1.2em",
    minWidth: "2rem",
    height: "2rem",
    lineHeight: "1.9rem",
    textAlign: "center",
    borderRadius: "50%",
    background: "transparent",
    verticalAlign: "middle",
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid",
    marginRight: "1rem",
    marginLeft: "1rem",
   
  },
  linkIconActive: {
    color: theme.palette.primary.main,
  },
  linkText: {
    padding: 0,
    color: theme.palette.text.secondary + "CC",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 16,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  },
  linkTextActive: {
    color: theme.palette.text.primary,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 30,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
}));
