import React, { useEffect, useState } from "react";

import {
	Fab,
	Input,
	Menu,
	MenuItem,
	Select,
	Table,
	Button,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { Typography } from "../../components/Wrappers/Wrappers";
import { NotificationV1Model } from "../../model";
import { QuestionRepo } from "../../repositories/question";
import Loader from "../Loader/Loader";
import { deleteNotifications } from "../../service";


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);



function NotificationList(props: { data: NotificationV1Model }) {

	const { data } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [loader, setLoader] = useState(false)
	const history = useHistory();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	// console.debug("NotificationList: data", data)
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEdit = () => {
		history.push("/edit/" + data.id)
	};

	const deleteNotification = () => {
		console.debug("deleteNotification", data)
		// setAnchorEl(null);
		const res = deleteNotifications(data.id).then(() => {
			window.location.reload();
		})
	};



	return (
		<StyledTableRow>
			<TableCell>
			</TableCell>
			<TableCell>
				<Typography weight="bold" variant="body1">
					{data.title}
				</Typography>
			</TableCell>
			<TableCell>
				{data.notificationText}
			</TableCell>
			{/* <TableCell>
				{data.img}
			</TableCell> */}
			<TableCell>
				{data.projectId}
			</TableCell>
		
			<TableCell>
				<Button aria-controls="simple-menu" color="primary" aria-haspopup="true" onClick={handleClick}>
					Action
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{/* <MenuItem onClick={handleEdit}>Duplicate</MenuItem> */}
					<MenuItem onClick={deleteNotification}>Delete</MenuItem>
					{/* <MenuItem onClick={() => history.push("/responses/" + data.id)}>Responses</MenuItem> */}
				</Menu>
			</TableCell>
		</StyledTableRow>

	);
}

export { NotificationList };
