import React, { Dispatch, useEffect,useState } from "react";
import {
	Button,TextField
} from "@material-ui/core";
import useStyles from '../styles';
import Modal from "antd/lib/modal/Modal";
import {reward} from "../../../repositories/reward"
function RewardModalAdd(props)
{
   const[network,setNetwork]=useState('');
   const[rewards,setRewards]=useState('');
    const classes = useStyles();
    const handleClose = ()=>{
        props.setAddOpen(false)
    }
    const submitForm = ()=>{
        reward.create({network:network,rewards:rewards}).then((res)=>{
            props.setAddOpen(false)
            window.location.reload(true);
        })
    }
return(
    <>
        <Modal
          title="Add"
          centered
          className={classes.modalTeam}
          zIndex={2}
          visible={props.addOpen}
            onCancel={handleClose}
          footer={[
            <Button key="back"
              onClick={handleClose}
            >
              Cancel
            </Button>,
            <Button onClick={submitForm}
            >
              Submit
            </Button>
          ]}
        >
            <div>
            <TextField id="outlined-basic" label="network size" variant="outlined" onChange={(e)=>{setNetwork(e.target.value)}} />
            </div>
            <div>
            <TextField id="outlined-basic" label="rate/hour" variant="outlined" onChange={(e)=>{setRewards(e.target.value)}}/>
            </div>
        </Modal>
</>
)
}

export default RewardModalAdd;