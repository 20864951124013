// prettier-ignore
import { makeStyles, Typography } from '@material-ui/core';
import React from "react";
import { Button } from "../Wrappers/Wrappers";
import { Link } from "react-router-dom";

interface Props {
  customError?: React.ReactNode;
  error: string | Error;
}

function Loader(props: Props) {
  const { error, customError } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {customError ?? (
        <Typography variant="h3" color="secondary">
          {error.toString()}
        </Typography>
      )}
      <Button
        className={classes.button}
        color="secondary"
        variant="outlined"
        component={Link}
        to="/"
      >
        Navigate back home
      </Button>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  button: {
    marginTop: 30,
  },
});

export default Loader;
