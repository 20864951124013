import React, { useState, useEffect } from 'react';
import {
  Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography, Button, Checkbox, Box, AppBar, Toolbar,
  CircularProgress, MenuItem, Select, InputLabel, FormControl,
  IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { callableFunctions } from '../../repositories/firebase';
import useStyles from "./styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'; // Import the back icon
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useHistory, useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.common.white,
      },
    },
  })
)(TableRow);

interface User {
  uid: string;
  email: string;
  displayName?: string;
}

interface FetchUsersResponse {
  users: User[];
  nextPageToken?: string;
}

const BlockedUsersList = () => {
  const classes = useStyles();
  const history = useHistory();
  const name = useParams<{ name: string }>();
  const user = useSelector((state: RootState) => state.firebase.auth);
  const [users, setUsers] = useState<User[]>([]);
  const [fetching, setFetching] = useState(false); // Added fetching state
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [domain, setDomain] = useState<string>(name.name);
  const [loading, setLoading] = useState(false); // Loading state for "Load More" button
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [blockedDomain, setBlockedDomain] = useState<string>(name.name);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10); // Default limit per page
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [dataFlag, setDataFlag] = useState(false);
  const userName = user.displayName?.split(" ")[0] ?? "there!";

  const loadUsersByDomain = async (resetPagination: boolean) => {
    setLoading(!resetPagination); // Show loader on "Load More" button
    setFetching(resetPagination); // Show loader for first-time fetch
    try {
      const getUsersByBlockedDomain = callableFunctions.httpsCallable('getUsersByBlockedDomain');
      const response = await getUsersByBlockedDomain({
        domain: blockedDomain,
        limit: limit,
        pageToken: resetPagination ? undefined : nextPageToken,
      });

      const data: FetchUsersResponse = response.data;

      if (resetPagination) {
        setUsers(data.users);
        setPage(1);
      } else {
        setUsers(prevUsers => [...prevUsers, ...data.users]);
        setPage(prevPage => prevPage + 1);
      }

      setDataFlag(data.users.length < limit);
      setNextPageToken(data.nextPageToken ?? null);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users. Please try again later.');
    } finally {
      setLoading(false);
      setFetching(false); // Stop fetching
    }
  };

  const handleLoadMore = () => {
    if (nextPageToken) {
      loadUsersByDomain(false);
    }
  };

  useEffect(() => {
    if (blockedDomain) {
      loadUsersByDomain(true);
    }
  }, [blockedDomain, limit]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedUsers([...users]);
    } else {
      setSelectedUsers([]);
    }
  };

  const isSelected = (user: User) => selectedUsers.findIndex(sel => sel.uid === user.uid) !== -1;

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, user: User) => {
    const selectedIndex = selectedUsers.findIndex(sel => sel.uid === user.uid);
    let newSelected: User[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUsers, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelected = newSelected.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1),
      );
    }

    setSelectedUsers(newSelected);
  };

  const deleteUsersCallable = callableFunctions.httpsCallable('deleteBlockedDomainUser');

  const handleDeleteUsers = async () => {
    setDeleteLoader(true);
    try {
      const uids = selectedUsers.map(d => d.uid);
      const response = await deleteUsersCallable({ userIds: uids });
      loadUsersByDomain(true);
      console.debug("Delete users response:", response.data);
      setSuccess(response.data.message);
      loadUsersByDomain(true);
      setSelectedUsers([]);
      setDeleteLoader(false);
    } catch (error) {
      console.error('Error deleting users:', error);
      setError('Error deleting users. Please try again later.');
    } finally {
      setDeleteLoader(false);
    }
  };

  const handleLimitChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLimit(event.target.value as number);
  };

  const handleBackClick = () => {
    history.push("/app/blocked-domain"); // Navigate back to the previous page
  };

  return (
    <>
        <Box display="flex" alignItems="center" justifyContent="space-between" margin="10px 0">
        <Box display="flex" alignItems="center">
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
          <PageTitle noMargin title={name.name} />
        </Box>
        <FormControl variant="outlined" size="small" style={{ minWidth: 150 }}>
          <InputLabel id="results-per-page-label">Results per page</InputLabel>
          <Select
            labelId="results-per-page-label"
            value={limit}
            onChange={handleLimitChange}
            label="Results per page"
          >
            {[10, 20, 25, 30].map((limitOption) => (
              <MenuItem key={limitOption} value={limitOption}>
                {limitOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {fetching ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {selectedUsers.length > 0 && (
            <AppBar position="static" color="default">
              <Toolbar>
                <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
                  {selectedUsers.length} selected
                </Typography>
                {deleteLoader === false ? (
                  <Button
                    style={{ backgroundColor: "#137DFB", color: "white" }}
                    variant="contained"
                    onClick={handleDeleteUsers}
                  >
                    Delete
                  </Button>
                ) : (
                  <div>
                    <CircularProgress size={24} />
                  </div>
                )}
              </Toolbar>
            </AppBar>
          )}

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        users.length === 0 ? false : selectedUsers.length === users.length
                      }
                      onChange={handleSelectAll}
                      inputProps={{ "aria-label": "select all users" }}
                    />
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Display Name</TableCell>
                  <TableCell>UID</TableCell>
                  <TableCell>Signed In</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0 ? (
                  users.map((user) => (
                    <StyledTableRow key={user.uid} selected={isSelected(user)}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(user)}
                          onChange={(event) => handleCheckboxClick(event, user)}
                          inputProps={{ "aria-label": `select ${user.email}` }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography weight="bold" variant="body1">
                          {user.email}
                        </Typography>
                      </TableCell>
                      <TableCell>{user.displayName ?? "N/A"}</TableCell>
                      <TableCell>
                        <Typography weight="bold" variant="body1">
                          {user.uid ?? "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography weight="bold" variant="body1">
                          {user?.metadata.creationTime ?? "N/A"}
                        </Typography>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography align="center" color="textSecondary">
                        No users found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box style={{ textAlign: "center", marginTop: 20 }}>
            {dataFlag === false && users.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoadMore}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Load More'}
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default BlockedUsersList;
