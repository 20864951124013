import * as firebase from "firebase";
import { callableFunctions } from "./firebase";
import { loadStripe } from "@stripe/stripe-js";
import { handleError } from "../utils/decorators";
import { Firestore } from "./firestore";

const publishableKey = "pk_test_Md6tQuscaC9rKSIB0Pqlizj1";
const getPriceId = callableFunctions.httpsCallable("getPriceId");
const createPortalLink = firebase
  .app()
  .functions("europe-west1")
  .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");

export class Paywall {
  @handleError("Failed to make redirect to checkout...")
  static async goToCheckout(uid: string, price: string): Promise<void | Error> {
    const { data: priceId } = await getPriceId(price);
    if (!priceId)
      return new Error(`Could not fetch price ID for price: ${price}`);
    await Firestore.createSubscription(
      uid,
      priceId,
      async (error, sessionId) => {
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe(publishableKey);
          await stripe?.redirectToCheckout({ sessionId });
        }
      }
    );
  }

  @handleError("Could not redirect to the Customer Portal")
  static async goToCustomerPortal(): Promise<void | Error> {
    const { data } = await createPortalLink({
      returnUrl: window.location.origin,
    });
    window.location.assign(data.url);
  }
}
