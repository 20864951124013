import React from "react";
import { Grid, Paper, CircularProgress } from "@material-ui/core";
import { Typography, Button } from "../../../../components/Wrappers/Wrappers";
import InvitationsRepo from "../../../../repositories/invitation";
import classnames from "classnames";
import { Invitation } from "../../../../model";

// styles
import useStyles from "./styles";

// logo
import logo from "../logo.svg";

interface Props {
  invitation: Invitation;
}

export default function Disclaimer(props: Props) {
  const { invitation } = props;
  const classes = useStyles();

  const [accepting, setaccepting] = React.useState<boolean>(false);

  async function acceptTerms() {
    if (!invitation.id) return;
    setaccepting(true);
    await InvitationsRepo.acceptTerms(invitation.id);
    setaccepting(false);
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={logo} alt="logo" />
        <Typography variant="h3" color="text" className={classes.logotypeText}>
          i3D Platform
        </Typography>
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant="h1"
          color="primary"
          className={classnames(classes.textRow, classes.errorCode)}
        >
          Welcome!
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          {invitation.email}
        </Typography>
        <Typography
          variant="h6"
          color="text"
          brightness="light"
          className={classnames(classes.textRow, classes.safetyText)}
        >
          By clicking the button below, you certify that you have all the legal
          requirements to access sensitive information concerning{" "}
          <span style={{ fontWeight: "bold" }}>{invitation.company_name}</span>{" "}
          and its legal subsidiaries.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.backButton}
          onClick={acceptTerms}
        >
          {accepting ? <CircularProgress /> : "I accept"}
        </Button>
      </Paper>
    </Grid>
  );
}
