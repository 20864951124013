import * as React from "react";
import * as ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import App from "./components/App";
import { ModalWrapper } from "./components/Modals/ModalWrapper";
import configureStore from "./configureStore";
import Themes from "./themes";
import { firebase } from "./repositories/firebase";
import { createFirestoreInstance } from "redux-firestore";
import {
  ReactReduxFirebaseConfig,
  ReactReduxFirebaseProvider,
  ReactReduxFirebaseProviderProps,
} from "react-redux-firebase";

const { store } = configureStore();

// react-redux-firebase config
const rrfConfig: Partial<ReactReduxFirebaseConfig> = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true, // Get custom claims along with the profile
  updateProfileOnLogin: true,
  autoPopulateProfile: true,
};
const rrfProps: ReactReduxFirebaseProviderProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  initializeAuth: true,
  createFirestoreInstance, // <- needed if using firestore
};

export function ReduxRoot() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <App />
          <ModalWrapper />
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

const rootEl = document.getElementById("root");
ReactDOM.render(<ReduxRoot />, rootEl);

// comment in for PWA with service worker in production mode
registerServiceWorker();
