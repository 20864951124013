import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";
import { firestore } from "firebase";

export interface INotification {
  receiverUID: string;
  body: string;
  seen: boolean;
  sentAt: firestore.Timestamp;
  deepLink: string;
  ref: firestore.DocumentReference;
}

export interface NotificationsState {
  fetching: boolean;
  numUnread: number;
  error?: any;
  unreadNotifications: INotification[];
  oldNotifications: INotification[];
}

export enum NotificationsActions {
  FETCH = "FETCH_ALL_NOTIFICATIONS",
  FETCHED_NOTIFICATIONS = "FETCHED_NOTIFICATIONS",
  FETCH_ERROR = "FETCH_NOTIFICATIONS_ERROR",
  MARK_AS_READ = "MARK_NOTIFICATIONS_AS_READ",
}

interface NotificationsActionsType<T, P = {}> {
  type: T;
  payload?: P;
}

export type NotificationsAction =
  | NotificationsActionsType<typeof NotificationsActions.FETCH>
  | NotificationsActionsType<
      typeof NotificationsActions.FETCHED_NOTIFICATIONS,
      INotification[]
    >
  | NotificationsActionsType<typeof NotificationsActions.FETCH_ERROR, any>
  | NotificationsActionsType<typeof NotificationsActions.MARK_AS_READ>;

export type NotificationsThunk = ThunkAction<
  void,
  RootState,
  unknown,
  NotificationsAction
>;
export type NotificationsDispatch = ThunkDispatch<
  RootState,
  any,
  NotificationsAction
>;
