import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  appBar: {
    width: "100vw",
    backgroundColor: "white",
  },
  toolbar: {
    marginTop: theme.spacing(1),
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    maxWidth: 300,
    boxShadow: "none",
    background: theme.palette.background.default,
  },
  headerMenu: {
    marginTop:  theme.spacing(7),
    
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      // backgroundColor: theme.palette.primary.light,
      backgroundColor: "lightgray",
      color: "white",
    },
  },
  headerEmptyMenu: {
    margin: theme.spacing(2),
  },
  NotificationTitle: {
    paddingLeft:"12px"
  },
  headerMenuButton: {
    // marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
    // background: "lightgray"
  },
  headerIcon: {
    fontSize: 24,
    color: theme.palette.text.main,
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    justifyContent:"center",
   alignItems:"center",
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,

  },
  profileMenuLink: {
    fontSize: '10px',
    textTransform: "capitalize",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  large: {
    marginBottom: "5%",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
