export enum ViewType {
  grid,
  list,
}

export enum SortingType {
  default = "company.name|asc",
  defaultReversed = "company.name|desc",
  ranking = "sectionStats.overall|desc",
  rankingReversed = "sectionStats.overall|asc",
  sector = "company.sector|asc",
  date = "startDate|asc",
  dateReversed = "startDate|desc",
  likes = "company.name|asc|myLikes",
  shares = "company.name|asc|sharedWithMe",
  uploads = "company.name|asc|myUploads",
}

export enum Filter {
  default = "all",
  team = "team",
  idea = "idea",
  market = "market",
  finance = "finance",
  competition = "competition",
  esg = "esg",
  crypto = "crypto",
}
