// prettier-ignore
import { makeStyles, CircularProgress } from '@material-ui/core';
import { Typography } from "../Wrappers/Wrappers";
import React from "react";

interface Props {
  customLoader?: React.ReactNode;
  text?: string;
}

function Loader(props: Props) {
  const { customLoader, text } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {customLoader ?? <CircularProgress />}
      <div style={{ marginTop: 20 }} />
      {text && <Typography weight="bold">{text}</Typography>}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Loader;
