import React, { Dispatch, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import {FactorForm}  from "./components";
import FadeIn from "react-fade-in";
import { QuestionDispatch, TechnicalFactor,Option } from "../../model";
import invitation from "../../repositories/invitation";

const validationSchema = Yup.object().shape<TechnicalFactor>({
    value: Yup.string().required("this field is necessary"),
    label: Yup.string().required("this field is necessary"),
    weight: Yup.number().required("this feild is necessary"),
    options: Yup.array<Option>().min(1,"minimum one option should be added").required("this feild is necessary"),
    section: Yup.string().required("this field is necessary"),
    active: Yup.boolean().required('this field is required'),
    
  
});

const intitialValues: TechnicalFactor = {
  value: "",
  label: '',
  weight: 0,
  options: [],
  section: '',
  active:false,
};

function CreateFactor() {
  const classes = useStyles();
  const history = useHistory();


  return (
    <div className={classes.root}>
      <Formik<TechnicalFactor>
        initialValues={intitialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
            invitation.addTechnicalQuestions(values).then((res)=>{
           history.push("/app/technical");
            })
        //    history.push("/app/dashboard");
        }}
      >
        {(formik) => (
          <Form className={classes.root}>
            <FadeIn>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                  <FactorForm options={[]}/>
                  <div style={{ marginBottom: 40 }} />
              </div>
            </FadeIn>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateFactor;
