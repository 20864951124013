import { makeStyles } from "@material-ui/styles";

const logoSize = 40;
export default makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: theme.spacing(12),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: "white",
    zIndex: 1000,
  },
  fab1: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(24),
    color: "white",
    zIndex: 1000,
  },
  gridContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    // gridAutoFlow: "row dense",
    // gridGap: theme.spacing(2),
    // // gridTemplateColumns: "repeat(auto-fit, 400px)",
    // gridTemplateColumns: "auto auto auto",
    // marginBottom: theme.spacing(3),
  },
  selectInput: {
    padding: 10,
    paddingRight: 25,
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  search: {
    maxWidth: 300,
    boxShadow: "none",
    background: theme.palette.background.default,
  },
  companyLogo: {
    width: logoSize,
    height: logoSize,
    borderRadius: 8,
    objectFit: "contain",
  },
}));
