import React, { useState } from "react";
import { withRouter, useLocation, Link } from "react-router-dom";
import { Grid, Typography, Tabs, Tab, Button } from "@material-ui/core";
import { Typography as Typo } from "../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import logo from "./logo.svg";
import { LoginForm, RegisterForm } from "../../components/Login";
import { Field, Formik, Form, FormikProps } from "formik";
import { ForgotInfo, forgotValidation } from "./index";
import { TextField } from "material-ui-formik-components";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { RootState } from "../../reducers";
import { UserDispatch } from "../../model";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

function ForgotPassword() {
    const classes = useStyles();
    const location = useLocation();
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<UserDispatch>();
    const [open, setOpen] = useState(false);
    const [emailValue, setEmailValue] = useState(false);

    const searchParams = new URLSearchParams(location.search);
    const handleDialogStatus = () => {
        setOpen(!open);
    };

    const handleSubmit = (formik: FormikProps<ForgotInfo>) => {
        console.debug(formik.values.email,"fjdhfj")
        // var questions = [
        //     {
        //         id: "q1",
        //         type: "opinion_scale",
        //         comment: false,
        //     },
        // ];
        // var answers = questions.map((val) => {
        //     return {
        //         type: val.type == "opinion_scale" ? "number" : "text",
        //         number: formik.values[val.id],
        //         field: val,
        //         text: "",
        //     }
        // });
        // formik.values.email = answers;
        // console.debug(formik.values)
        const response = axios.get(
            "https://us-central1-vk-invluencer-dev.cloudfunctions.net/forgetForm",
            {
                params: formik.values.email ,
            }

        ).then(function (response) {
            console.debug(response);
            return response;
        }).catch(function (error) {
            console.debug(error);
            return error.response;
        });
    };





    return (
        <Grid container className={classes.container}>
            <div className={classes.logotypeContainer}>
                <img src={logo} alt="logo" className={classes.logotypeImage} />
                <Typography className={classes.logotypeText}>i3D Platform</Typography>
                <Typo variant="h3" htmlColor="white">
                    Powered by Invluencer Ltd.
        </Typo>
                <Typo
                    color="primary"
                    brightness="contrastText"
                    className={classes.copyright}
                >
                    © 2020 Invluencer Ltd. All rights reserved.
        </Typo>
            </div>
            <div className={classes.formContainer}>
                <Typography variant="h1" className={classes.greeting}>
                    Forgot Password
                </Typography>
                <Typography variant="h3" className={classes.forgotpara}>
                    Nothing to worry about. We have you covered
                </Typography>
                <Typography variant="h3" className={classes.forgotparas}>
                    All you need to do is input your registered email address and leave the rest to us.
                </Typography>
                <div className={classes.form}>

                    <Formik<ForgotInfo>
                        initialValues={{
                            email: "",
                        }}
                        validateOnMount
                        validateOnChange
                        validateOnBlur
                        validationSchema={forgotValidation}
                        // onSubmit={(values) => dispatch(forgetPass(values.email))}
                        // onClick={handleSubmit(formik)}
                    >
                        {(formik) => {
                            return (
                                <Form onSubmit={formik.submitForm}>

                                    <Field
                                        required
                                        name="email"
                                        label="Email"
                                        component={TextField}
                                        margin="normal"
                                        placeholder="Email Address"
                                        type="email"
                                        fullWidth
                                        InputProps={{
                                            classes: {
                                                underline: classes.textFieldUnderline,
                                                input: classes.textField,
                                            },
                                        }}
                                    />
                                    <Button
                                        color="primary"
                                        size="large"
                                        type="button"
                                        className={classes.submitButton}
                                        onClick={handleSubmit(formik)}
                                    >
                                        Submit
                                    </Button>
                                    <p className={classes.loginLink}>
                                        Go to the <Link to="/">login page</Link>
                                    </p>
                                </Form>
                            );
                        }}
                    </Formik>

                </div>
            </div>
            <Dialog onClose={handleDialogStatus} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Mail has been sent to your registered email</DialogTitle>
            </Dialog>
        </Grid>
    );
}

export default ForgotPassword;
