import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";

export interface Feedback {
  rating?: number;
  type?: string;
  commentary?: string;
}

export interface FeedbackState {
  sending: boolean;
  sent: boolean;
  error?: any;
}

export enum FeedbackActions {
  SEND = "SEND_FEEDBACK",
  SENT = "SENT_FEEDBACK",
  ERROR = "ERROR_FEEDBACK",
  RESET = "RESET_FEEDBACK",
}

interface FeedbackActionsType<T, P = {}> {
  type: T;
  payload?: P;
}

export type FeedbackAction =
  | FeedbackActionsType<typeof FeedbackActions.SEND>
  | FeedbackActionsType<typeof FeedbackActions.SENT>
  | FeedbackActionsType<typeof FeedbackActions.RESET>
  | FeedbackActionsType<typeof FeedbackActions.ERROR, any>;

export type FeedbackThunk = ThunkAction<
  void,
  RootState,
  unknown,
  FeedbackAction
>;
export type FeedbackDispatch = ThunkDispatch<RootState, any, FeedbackAction>;
