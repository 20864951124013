import React, { useEffect, useState } from "react";

import {
	Fab,
	Input,
	Menu,
	MenuItem,
	Select,
	Table,
	Button,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Questions } from "../../model";
import { QuestionRepo } from "../../repositories/question";


const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);



function QuestionList(props: { data: Questions }) {

	const { data } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEdit = () => {
		history.push("/edit/" + data.id)
	};

	const deleteQuestion = () => {
		setAnchorEl(null);
		const res = QuestionRepo.delete(data.id).then(() => {
			window.location.reload();
		})
	};


	return (
		<StyledTableRow>
			<TableCell>
			</TableCell>
			<TableCell>
				<Typography weight="bold" variant="body1">
					{data.question}
				</Typography>
			</TableCell>
			<TableCell>
				{data.platform}
			</TableCell>
			<TableCell>
				{data.type}
			</TableCell>
			<TableCell>
				{data.comment ? "True" : "False"}
			</TableCell>
			<TableCell>
				{data.active ? "True" : "False"}
			</TableCell>
			<TableCell>
				{data.section}
			</TableCell>
			<TableCell>
				{data.crypto ? "True" : "False"}
			</TableCell>
			<TableCell>
				<Button aria-controls="simple-menu" color="primary" aria-haspopup="true" onClick={handleClick}>
					Action
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleEdit}>Edit</MenuItem>
					<MenuItem onClick={deleteQuestion}>Delete</MenuItem>
					<MenuItem onClick={() => history.push("/responses/" + data.id)}>Responses</MenuItem>
				</Menu>
			</TableCell>
		</StyledTableRow>

	);
}

export { QuestionList };
