import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useDropzone } from 'react-dropzone';
import { callableFunctions, storage } from "../../repositories/firebase"; // Adjust this import according to your file structure

interface ImportDomainDialogProps {
  onSuccess: () => void;
  onError: (error: string) => void;
  open: boolean;
  setFlag: (flag: boolean) => void;
  setDialogImportOpen: (open: boolean) => void;
  handleClose: () => void;
}

const ImportDomainDialog: React.FC<ImportDomainDialogProps> = ({ onSuccess, onError, open, setFlag, setDialogImportOpen, handleClose }) => {
  const [uploading, setUploading] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);

  const onDrop = (files: File[]) => {
    setAcceptedFiles(files);
  };

  const uploadFile = async () => {
    if (acceptedFiles.length === 0) {
      onError('No file selected.');
      return;
    }

    const file = acceptedFiles[0];
    const storageRef = storage.ref().child(`blockedDomain/${file.name}`);
    const fileRef = storageRef.child(file.name);

    setUploading(true);
    try {
      await fileRef.put(file);

      // Call the Cloud Function to process the file
      const processFile = callableFunctions.httpsCallable('importBlockedDomainsFromExcel');
      const result = await processFile();
      onSuccess();
      setAcceptedFiles([]);
      if (result.data.success) {
        setFlag(true)
        onSuccess();
      } else {
        onError('Some domains were duplicates and were not added.');
      }
    } catch (error) {
      setFlag(true)
      onSuccess();
      console.error('Error uploading file:', error);
      onError('Error uploading file. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const onClose = () => {
    setDialogImportOpen(false);
    setAcceptedFiles([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.xlsx'
  });

  const dropzoneStyle = {
    border: '2px dashed #007BFF',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };

  const exampleCSV = 'data:text/csv;charset=utf-8,name,activeStatus\nexample.com,TRUE\nanotherdomain.com,FALSE';

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Import Blocked Domain</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please upload the Excel file with the blocked domains. You can download the example CSV file to format your data correctly.
        </DialogContentText>
        <Box mb={2}>
          <Button
            color="secondary"
            variant="outlined"
            href={exampleCSV}
            download="example_blocked_domains.csv"
          >
            Sample File
          </Button>
        </Box>
        <Box>
          <div {...getRootProps()} style={dropzoneStyle}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the Excel file here ...</Typography>
            ) : (
              <Typography>Drag 'n' drop an Excel file here, or click to select one</Typography>
            )}
          </div>
          {acceptedFiles.length > 0 && (
            <Box mt={2}>
              <Typography variant="body2">{acceptedFiles[0].name}</Typography>
            </Box>
          )}
          <Box mt={2}>
            <Button color="primary" onClick={onClose} disabled={uploading}>Cancel</Button>
            <Button
              color="primary"
              variant="contained"
              disabled={uploading || acceptedFiles.length === 0}
              onClick={uploadFile}
            >
              {uploading ? <CircularProgress size={24} /> : 'Upload Excel File'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImportDomainDialog;
