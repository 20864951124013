import { firestore } from "../repositories/firebase";
import firebase from "firebase";
import { handleError } from "../utils/decorators";
const rewardCollection = firestore.collection("rewards");
const signupReward = firestore.collection("signUpReward");

export class reward {

  @handleError("Failed to fetch all questions...")
    static async fetchAllRewards() {
      const data = await rewardCollection.get();
      const questionsFetched = await Promise.all(data.docs.map(
        async (d) => {
          return ({ ...d.data(), id: d.id }) 
        }
      ));
      return questionsFetched;
      }


  @handleError("Failed to create question...")
    static async create(
        data,
      ) {
        const result = await rewardCollection.add(data);
        return result.id;
      }

  @handleError("Failed to update question...")
    static async update(
        data: Record<string,any>,
        id: string
      ): Promise<void | Error> {
        const result = await rewardCollection.doc(id).update(data);
      }

  @handleError("Failed to update signup reward...")
    static async updateSignUpReward(
        data: Record<string,any>,
      ): Promise<void | Error> {
        const result = await signupReward.doc('1').update(data);
      }

      @handleError("Failed to update score reward...")
      static async updateScoreReward(
          data: Record<string,any>,
        ): Promise<void | Error> {
          const result = await signupReward.doc('2').update(data);
        }

  @handleError("Failed to delete question...")
    static async delete(
        id: string
      ): Promise<void | Error> {
        const result = await rewardCollection.doc(id).delete();
      }

  @handleError("Failed to fetch signup reward...")
    static async fetchRewardSignUp() {
          const result = await signupReward.doc('1').get();
          var data = result.data();
          return {...data};
        }

  @handleError("Failed to fetch signup reward...")
    static async fetchRewardScore() {
          const result = await signupReward.doc('2').get();
          var data = result.data();
          return {...data};
        }
  

  }
