import React,{useState} from "react";
import { Button, Grid, Button as MButton } from "@material-ui/core";
import { Typography } from "../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
// import { Select, Input, AutoComplete, Radio } from "formik-antd";
import { useFormikContext, Field } from "formik";
import {  Option, TechnicalFactor } from "../../../model";
import { sectionOptions, stages } from "./data";
import { Select, Input} from "formik-antd";
import { useHistory } from "react-router-dom";
import {
	Fab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
    withStyles,
    Menu,
    MenuItem,
} from "@material-ui/core";
import FactorModal from "../../technical/components/Factormodal";
import FactorModalEdit from "../../technical/components/FactorModalEdit";
// const { Option1 } = Select;

function FactorForm(props) {
  const history = useHistory();
    const classes = useStyles();
    const formik = useFormikContext<TechnicalFactor>();
    const [option,setOption] = useState<Option[]>(props.options.length?props.options:[]);
    const [open,setOpen] = useState(false);
    const [openEdit,setOpenEdit] = useState(false);
    const [error,setError] = useState('');
    const [editKey,setEditKey] = useState(-1);
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#F2F7FF",
        },
    }))(TableCell);

	
    const handleEdit = (i) => {
        setEditKey(i);
        setOpenEdit(true);
	};

	const deleteQuestion = (i) => {
		var data = option;
        data.splice(i,1);
        setOption([...data]);

	};
	
    return (
        <Grid container style={{ padding: "8rem", paddingTop: "5rem" }}>
            <FactorModal open={open} setOpen={setOpen} option={option} setOption={setOption}/>

           {editKey>-1?<FactorModalEdit open={openEdit} setOpen={setOpenEdit} option={option} 
           setOption={setOption} setEditKey={setEditKey} editKey={editKey}/>:''} 
           
            <Grid item xs={6} style={{ paddingRight: "7rem" }}>
                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Factor
                    </Typography>
                    <Input
                        className={classes.detailQuestion}
                        name="label"
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2rem", marginTop: "1rem" }}
                        placeholder="Enter Factor here..."
                    />
                </div>
                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Value
                    </Typography>
                    <Input
                        className={classes.detailQuestion}
                        name="value"
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2rem", marginTop: "1rem" }}
                        placeholder="provide question about the question.."
                    />

                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Weight
                    </Typography>
                    <Input
                        className={classes.detailQuestion}
                        name="weight"
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2rem", marginTop: "1rem" }}
                        placeholder="provide weight of the question.."
                    />

                </div>

                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Active
                    </Typography>
                    <Field type="checkbox" name="active" />
                    <label className={classes.label}>
                        enable
                    </label>
                </div>

                <div className={classes.inputField} style={{ width: "100%",marginTop:20 }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Section (Select One)
                    </Typography>
                    <Grid container style={{ paddingTop: "0.6rem" }}>
                        {sectionOptions.map((value,index) => {
                            return (
                                <Grid item xs={4} key={index} className={classes.radioButtonBox}>
                                    <Field type="radio" name="section" value={value.value} />
                                    <label className={classes.label}>
                                        {value.label}
                                    </label>
                                </Grid>
                            );

                        })}

                    </Grid>
                </div>

                
               
            </Grid>

            <TableContainer>
						<Table stickyHeader className="mb-0">
							<TableHead>
								<TableRow>
										<StyledTableCell></StyledTableCell>
										<StyledTableCell>value</StyledTableCell>
										<StyledTableCell>score</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
                                {
                                    option.map((val,index)=>{
                                        return (
                                            <TableRow key={index+"key"}>
                                                <TableCell></TableCell>
                                                <TableCell>{val.value}</TableCell>
                                                <TableCell>{val.score}</TableCell>
                                                <TableCell onClick={()=>{handleEdit(index)}}>
                                                                <a>Edit</a>
                                                </TableCell>
                                                <TableCell onClick={()=>{deleteQuestion(index)}}>
                                                    <a>Delete</a>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                </TableContainer>
                
                <div>
                    <Button color="primary" type="button" onClick={()=>{setOpen(true)}}>add option</Button>
                </div>

            <div className={classes.inputField} style={{ width: "100%", marginBottom: "1rem", textAlign:"center" }}>
                <span style={{color:'red'}}>{error}</span>
            </div>
            <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem", textAlign:"center" }}>
                <Button
                    color="primary"
                    size="small"
                    type="button"
                    // disabled={formik.isValid}
                    className={classes.submitButton}
                    onClick={() => {
                        if(!formik.isValid)
                        {
                            setError("please fill all the fields");
                        }
                        formik.values.options=option;
                        formik.values.weight= parseFloat(formik.values.weight);
                        formik.submitForm()}}
                >
                    Save
                </Button>
                <MButton className={classes.cancelButton} style={{marginLeft: "2rem"}} color="inherit" onClick={() => history.goBack()}>
                    Cancel
                </MButton>
            </div>
        </Grid>


    );
}

export default FactorForm;
