import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "cloumn",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",

  },
  sectionTitle: {
    paddingRight: theme.spacing(2),
    fontSize: "15px !important",
    fontWeight: "500 !important",
    textAlign: "right",
    width: 300,
    color: "#000000",

  },
  
  label:{
    marginLeft:10,
  },

  inputFields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: theme.spacing(4),
  },
  privacySelect: {
    // height: 106,
    width: 454,
    textAlign: "left",
    background: "#F8FAFD",
    border: "1px solid #BEBEBE",
    boxSizing: "border-box",
    borderRadius: "10px",
    // padding: "0.6rem",
    boxShadow: "none"

  },
  details: {
    '& .ant-select-selector': {
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      height: "40px !important",
      border: "1px solid #BEBEBE !important",
      background: "transparent !important",
      borderRadius: "10px !important",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "17px",
      color: "#000000",
      padding: "0 20px !important"
    }
  },
  type: {
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #BEBEBE",
    boxSizing: "border-box",
    height: "6rem",
    background: "transparent",
    padding: "0 20px !important"
  },

  // ____________________________________NEW QUESTION SECTIONS FORM____________________________
  sectionTitles: {
    paddingRight: theme.spacing(2),
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textAlign: "left",
    width: 300,
    color: "rgb(138 136 136)",
  },

  sectionTitleDescription:{
    paddingRight: theme.spacing(2),
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textAlign: "left",
    width: 300,
    color: "rgb(138 136 136)",
    marginBottom:"15px"
  },

  detailQuestion:{
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #BEBEBE",
    height: "6rem",
    padding: "0 20px !important",
    background: "#f5f5f5",
    boxSizing: "border-box",
    borderColor: "#40a9ff"
  },
  input:{
    padding: "10px 14px"
  },
  select:{
    height:"200px"
  },
  menuPaper:{
    height:"230px"
  },
  context:{
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #BEBEBE",
    boxSizing: "border-box",
    height: "15rem",
    background: "#f5f5f5",
    padding: "0 20px !important",
    borderColor: "#40a9ff"
  },
  submitButton:{
    border: "1px solid",
    padding: "7px 35px 7px 35px",
    borderRadius: "5px",
    fontSize: "0.8rem",
    color: "#fff",
    background: "#0c0c52",
    marginTop: "2rem",
    '&:hover':{
      color: "#fff",
      background: "#0c0c52",
    }
  },
  cancelButton:{
    border: "1px solid",
    padding: "7px 27px 7px 27px",
    borderRadius: "5px",
    fontSize: "0.8rem",
    color: "#fff",
    background: "#0c0c52",
    marginTop: "2rem",
    '&:hover':{
      color: "#fff",
      background: "#0c0c52",
    }
  },
  radioButtonBox:{
    color: "rgb(138 136 136)",
    '& label > span':{
      padding:"0px !important",
      paddingLeft: "9px !important",
      fontSize: "0.9rem"
    },
    '& label > span >span>div>svg':{
      width: "0.8em",
      height: "1em"
    }
  }


}));