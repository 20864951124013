import React, { Dispatch, useEffect,useState } from "react";
import useStyles from './styles';
import {
	Fab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,Paper,Button
} from "@material-ui/core";
import RewardModal from "./components/RewardModal";
import RewardModalAdd from "./components/RewardModalAdd";
import {reward} from '../../repositories/reward';
import { Typography } from "../../components/Wrappers/Wrappers";
import { TextField } from "@material-ui/core";


function Rewards(){
	const classes = useStyles();
    const [value,setValue] = useState({});
    const [token,setToken] = useState('0');
    const [stat,setStat] = useState(false);
    const [stat1,setStat1] = useState(false);
    const [scoreToken,setScoreToken] = useState('0');
    const [open,setOpen] = useState(false);
    const [addOpen,setAddOpen] = useState(false);
    const [data,setData] = useState([]);
    useEffect(()=>{
        reward.fetchAllRewards().then((res)=>{
            setData(res.sort(function(a, b){return parseInt(a.network)-parseInt(b.network)}));
        })
        reward.fetchRewardSignUp().then((res)=>{
            setToken(res.tokens);
            setStat(true)
        })
        reward.fetchRewardScore().then((res)=>{
          setScoreToken(res.tokens);
          setStat1(true)
      })
    },[])

    function deleteHandler(id)
    {
        reward.delete(id).then((res)=>{
            window.location.reload();
        })
    }

    if(!stat)
    return <h1>loading...</h1>
    if(!stat1)
    return <h1>loading...</h1>
return(
    <>
    <TableContainer component={Paper} className={classes.container}>
      <Table style={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tier</TableCell>
            <TableCell>network size</TableCell>
            <TableCell>rate per hour</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,index) => (
            <TableRow
              key={row.id}
            >
              <TableCell component="th" scope="row">
                {"Tier "+ (index+1)}
              </TableCell>
              <TableCell>
                {row.network}
              </TableCell>
              <TableCell>
                {row.rewards}
              </TableCell>
              <TableCell>
                <Button color="primary"
                    size="small"
                    type="button" onClick={()=>{
                        setOpen(true);
                        setValue(row);
                    }}>edit</Button>
              </TableCell>
              <TableCell>
                <Button color="primary"
                    size="small"
                    type="button" onClick={()=>deleteHandler(row.id)}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button className={classes.background} onClick={()=>{setAddOpen(true)}} >Add</Button>

    <RewardModalAdd addOpen={addOpen} setAddOpen={setAddOpen} length={data.length}/>
    {open&&<RewardModal open={open} setOpen={setOpen} data={value}/>}

    <div>
        <Typography>Sign-up Rewards</Typography>
        <TextField defaultValue={token} variant='outlined' onChange={(e)=>{setToken(e.target.value)}}></TextField>
        <Button className={classes.background} onClick={()=>{
            reward.updateSignUpReward({tokens:token}).then((res)=>{
            window.location.reload();
        })}}>save</Button>
    </div>

    <div>
        <Typography>Scoring Rewards</Typography>
        <TextField defaultValue={scoreToken} variant='outlined' onChange={(e)=>{setScoreToken(e.target.value)}}></TextField>
        <Button className={classes.background} onClick={()=>{
            reward.updateScoreReward({tokens:scoreToken}).then((res)=>{
            window.location.reload();
        })}}>save</Button>
    </div>
    </>
);

}

export default Rewards;