import { makeStyles } from "@material-ui/core/styles";

const logoSize = 100
;

export default makeStyles((theme) => ({
  
  documentDropDown:{
    // display: "none",
    position: "absolute",
    backgroundColor: "#fff",
    minWidth: "125px",
    boxShadow: theme.customShadows.widget,
    zIndex: "1",
    borderRadius: "4px",
    top: "45px",
    '& a':{
        color: "#000",
        display: "block",
        padding: "10px",
        fontFamily: "'Manrope', sans-serif",
        fontSize: "12px"
    },
  }
}));