import React, { useEffect, Dispatch, useState } from "react";
import {
    createStyles, Grid, InputBase, Theme, Typography, withStyles, Button, IconButton
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "./styles";
import { Select, Input } from "formik-antd";
import { Formik,FormikProps } from "formik";
import { ProfileInfo } from "../../model/profile";
import { TextField } from "material-ui-formik-components";
import * as Yup from "yup";
import {Auth} from "../../repositories/auth";
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { values } from "lodash";

const { Option } = Select;

const schema = Yup.object().shape<ProfileInfo>({
    Name: Yup.string(),
    Organization: Yup.string(),
    emails: Yup.array<string>(),
    password: Yup.string(),
    newPassword: Yup.string(),
    c_newPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords did not match'),
  
  });
const intitialValues: ProfileInfo = {
    Name: "",
    Organization: "",
    emails: [],
    password: "",
    newPassword: "",
    c_newPassword: "",
};
function ProfileDetails() {
    const classes = useStyles();
    const history = useHistory();
	const user = useSelector((state: RootState) => state.firebase.auth);

    function resetPassword(formik: FormikProps<ProfileInfo>)
    {
        console.debug(formik.values);
        Auth.resetPassword(user.email,formik.values.password,formik.values.newPassword)
        .then((response)=>
        {
            alert(response);
            window.location.reload();
        })
        .catch((error)=>
        {
            console.log(error);

        });

    }
    const BootstrapInput = withStyles((theme: Theme) =>
        createStyles({
            root: {
                'label + &': {
                    marginTop: theme.spacing(3),
                },
            },
            input: {
                borderRadius: 5,
                position: 'relative',
                backgroundColor: "#F4F6F9 !important",
                border: 'none',
                fontSize: 16,
                padding: '10px 26px 10px 12px',
                transition: theme.transitions.create(['border-color', 'box-shadow']),
                '&:focus': {
                    borderRadius: 5,
                    borderColor: '#F4F6F9',
                    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
                },
            },
            formControl: {
                margin: 0,
                display: "flex",
                wrap: "wrap"
            }
        }),
    )(InputBase);
    return (
        <>
            <PageTitle
                title={
                    "Profile"
                }
                extra={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}
                    >
                    </div>
                }
            />
            <Formik<ProfileInfo>
                initialValues={intitialValues}
                validateOnMount={true}
                validateOnChange={true}
                validateOnBlur={true}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                    console.log({ values, actions });
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                }}
            >
                {(formik) => (
                <Grid container style={{ padding: "5%" }} >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <ul className={classes.inlineStyle} style={{ width: 100 + "%" }}>
                            <li>
                                <Typography className={classes.personalDetail}>Personal Details</Typography>
                            </li>

                            <li>
                                <Typography className={classes.details}>Profile Picture</Typography>
                                <img
                                    src={user.photoURL?user.photoURL:"/image53.png"}
                                    alt="company logo"
                                    className={classes.profileLogo}
                                />
                            </li>

                            <li className={classes.details} >
                                <Typography className={classes.details}>Name</Typography>
                                <Input
                                    className={classes.detailsInput}
                                    name="name"
                                    value={user.displayName}
                                    style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                    placeholder="e Invluencer Admin"
                                />
                            </li>

                            <li className={classes.details} >
                                <Typography className={classes.details}>Organization</Typography>
                                <Input
                                    className={classes.detailsInput}
                                    name="Organisation"
                                    style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                    placeholder="Invluencer Ltd"
                                />
                            </li>
                            <li className={classes.details} >
                                <Typography className={classes.details}> E-Mail</Typography>
                                <Input
                                    className={classes.detailsInput}
                                    name="email"
                                    value={user.email}
                                    style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                    placeholder="eadmin@invluencer.com"
                                />
                            </li>

                            <li className={classes.details} >
                                <Typography className={classes.details}>Country/Region</Typography>
                                <Select
                                    name="Country/Region"
                                    defaultValue="Country/Region"
                                    style={{ width: "100%" }}
                                    className={classes.detailsInput}
                                >

                                    <Option value="Country/Region">
                                        India
                                    </Option>

                                </Select>
                            </li>

                            <li className={classes.details} >
                                <Typography className={classes.details}>Role</Typography>
                                <Select
                                    name="Role"
                                    defaultValue="Role"
                                    style={{ width: "100%" }}
                                    className={classes.detailsInput}
                                >
                                    <Option value="">
                                        Angel Investor
                                </Option>

                                </Select>
                            </li>

                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <ul className={classes.inlineStyle} style={{ width: 100 + "%" }}>
                            <li>
                                <Typography className={classes.personalDetail}>Password Management</Typography>
                            </li>

                            <li className={classes.details} >
                                <Typography className={classes.details}>Primary password</Typography>
                                <Input
                                    type="password"
                                    className={classes.detailsInput}
                                    name="password"
                                    style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                    placeholder="primary password"
                                />
                            </li>

                            <li className={classes.details} >
                                <Typography className={classes.details}>New password</Typography>
                                <Input
                                    type="password"
                                    className={classes.detailsInput}
                                    name="newPassword"
                                    style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                    placeholder="new password"
                                />
                            </li>

                            <li className={classes.details} >
                                <Typography className={classes.details}>Confirm new password</Typography>
                                <Input
                                    type="password"
                                    className={classes.detailsInput}
                                    name="c_newPassword"
                                    style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                    placeholder="confirma new password"
                                />
                                <div>
                                {formik.errors.c_newPassword && formik.touched.c_newPassword && (
                                    <Typography variant="caption" color="secondary">
                                    {formik.errors.c_newPassword}
                                    </Typography>
                                )}

                                </div>
                                
                            </li>

                            <li>
                                <Button
                                    color="primary"
                                    size="large"
                                    type="button"
                                    className={classes.savePassButton}
                                    onClick={() => resetPassword(formik)}
                                >
                                    Save Password
                            </Button>
                            </li>

                        </ul>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            size="large"
                            type="button"
                            className={classes.deleteButton}
                            onClick={() => console.log("go to forgot password page")}
                        >
                            Delete Account
                            </Button>
                    </Grid>

                </Grid>
                )}
            </Formik>
        </>
    );
}



export default ProfileDetails;
