export const handleError = (message: string) => (
  // eslint-disable-next-line @typescript-eslint/ban-types
  target: Object,
  propertyKey: string,
  descriptor: PropertyDescriptor
): PropertyDescriptor => {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: unknown[]) {
    try {
      const result = originalMethod.apply(this, args);
      if (result && result instanceof Promise) {
        // Return promise
        return result.catch((error: any) => error);
      }
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  return descriptor;
};
