export const toFirstLetterUppercase = function (str: string) {
  return str[0].toUpperCase() + str.slice(1);
};

export const email2ObjectKey = function (str: string) {
  return str.replace(/\./g, "+");
};

export const objectKey2Email = function (str: string) {
  return str.replace(/\+/g, ".");
};

export const array2Object = function <S, T>(arr: S[], value: any): T {
  const obj: any = {};
  for (const key of arr) obj[key] = value;
  return obj as T;
};

export const array2ObjectFunc = function <S, T>(
  arr: S[],
  mapper: (value: S) => any
): T {
  const obj: any = {};
  for (const key of arr) obj[key] = mapper(key);
  return obj as T;
};

export const groupBy = function <S>(xs: S[], key: string): Record<string, S[]> {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {}) as Record<string, S[]>;
};
