// prettier-ignore
import { makeStyles, createStyles, Card, CardContent } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import * as React from "react";
import { Invitation } from "../../model";
import { Typography, Button } from "../../components/Wrappers/Wrappers";
import { Link } from "react-router-dom";
import { getSectionLabel } from "../create/components/data";

interface Props {
  invitation: Invitation;
}

function SectionsOverview(props: Props) {
  const { invitation } = props;
  const classes = useStyles();

  const finished = Object.entries(invitation.sectionsStatus)
    .filter(([, value]) => value === "done")
    .map(([key]) => key);
  const unfinished = Object.entries(invitation.sectionsStatus)
    .filter(([, value]) => value !== "done")
    .map(([key]) => key);

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        color="text"
        brightness="dark"
        weight="bold"
        gutterBottom
      >
        Due diligence on {invitation.company_name}
      </Typography>
      <Typography variant="h3" color="text" brightness="dark" gutterBottom>
        Completion Overview
      </Typography>
      <Typography variant="h6" color="text" brightness="dark">
        You have been requested to complete due diligence on these sections
      </Typography>
      <div className={classes.cards}>
        <Card className={classes.section}>
          <CardContent>
            <Typography
              weight="bold"
              variant="h5"
              color="primary"
              brightness="main"
            >
              Sections To Do
            </Typography>
            {!unfinished.length && (
              <Typography style={{ paddingTop: 30 }}>
                No sections left!
              </Typography>
            )}
            {unfinished.map((section) => {
              return (
                <Link key={section} to={`/form/${section}?id=${invitation.id}`}>
                  <div className={classes.row}>
                    <Checkbox
                      className={classes.checkbox}
                      checked={false}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <Typography variant="h6" className={classes.link}>
                      {getSectionLabel(section)}
                    </Typography>
                  </div>
                </Link>
              );
            })}
          </CardContent>
        </Card>
        <Card className={classes.section}>
          <CardContent>
            <Typography
              weight="bold"
              variant="h5"
              color="primary"
              brightness="main"
            >
              Sections Finished
            </Typography>
            {!finished.length && (
              <Typography style={{ paddingTop: 30 }}>
                No sections yet!
              </Typography>
            )}
            {finished.map((section) => {
              return (
                <div key={section} className={classes.row}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={true}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <Typography variant="h6">
                    {getSectionLabel(section)}
                  </Typography>
                </div>
              );
            })}
          </CardContent>
        </Card>
      </div>
      {!unfinished.length && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginTop: 100 }}
          href="https://requestor.i3d.ai"
        >
          Go to Home
        </Button>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
    },
    row: {
      marginTop: theme.spacing(2),
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    section: {
      marginTop: theme.spacing(8),
      width: 300,
      minHeight: 400,
      "&:first-child": {
        marginRight: theme.spacing(2),
      },
    },
    checkbox: {},
    link: {
      textDecoration: "none",
    },
    cards: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default SectionsOverview;
