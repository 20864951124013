import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../reducers";

export interface Reminder {
  email: string;
  content: string;
}

export interface ReminderState {
  sending: boolean;
  sent: boolean;
  error?: any;
}

export enum ReminderActions {
  SEND = "SEND_REMINDER",
  SENT = "SENT_REMINDER",
  ERROR = "ERROR_REMINDER",
  RESET = "RESET_REMINDER",
}

interface ReminderActionsType<T, P = {}> {
  type: T;
  payload?: P;
}

export type ReminderAction =
  | ReminderActionsType<typeof ReminderActions.SEND>
  | ReminderActionsType<typeof ReminderActions.SENT>
  | ReminderActionsType<typeof ReminderActions.RESET>
  | ReminderActionsType<typeof ReminderActions.ERROR, any>;

export type ReminderThunk = ThunkAction<
  void,
  RootState,
  unknown,
  ReminderAction
>;
export type ReminderDispatch = ThunkDispatch<RootState, any, ReminderAction>;
