import React from "react";
import { Button, Grid, Button as MButton } from "@material-ui/core";
import { Typography } from "../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
// import { Select, Input, AutoComplete, Radio } from "formik-antd";
import { useFormikContext, Field } from "formik";
import { Questions } from "../../../model";
import { sectionOptions, stages } from "./data";
import { values } from "lodash";
import { Select, Input, AutoComplete, Checkbox } from "formik-antd";
import { useHistory } from "react-router-dom";

const { Option } = Select;

function QuestionNewForm() {
  const history = useHistory();
    const classes = useStyles();
    const formik = useFormikContext<Questions>();

    return (
        <Grid container style={{ padding: "8rem", paddingTop: "5rem" }}>
            <Grid item xs={6} style={{ paddingRight: "7rem" }}>
                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Question
                    </Typography>
                    <Input
                        className={classes.detailQuestion}
                        name="question"
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2rem", marginTop: "1rem" }}
                        placeholder="Enter Question here..."
                    />
                </div>
                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Context
                    </Typography>
                    <Input
                        className={classes.context}
                        name="context"
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2rem", marginTop: "1rem" }}
                        placeholder="provide question about the question.."
                    />

                </div>
            </Grid>
            <Grid item xs={6} style={{ marginTop: "2rem" }}>
                <div className={classes.inputField} style={{ width: "100%", marginBottom: "1rem" }}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="h4" className={classes.sectionTitles}>
                                Crypto
                            </Typography>
                            <Field type="checkbox" name="crypto" />
                            <label className={classes.label}>
                                enable
                            </label>
                        </Grid>
                    </Grid>
                        
                </div>

                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Selection (Select One)
                    </Typography>
                    <Grid container style={{ paddingTop: "0.6rem" }}>
                        {sectionOptions.map((value,index) => {
                            return (
                                <Grid item xs={4} key={index} className={classes.radioButtonBox}>
                                    <Field type="radio" name="section" value={value.value} />
                                    <label className={classes.label}>
                                        {value.label}
                                    </label>
                                </Grid>
                            );

                        })}

                    </Grid>
                </div>


                <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem" }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Company Stage
                    </Typography>

                    <Grid container style={{ paddingTop: "0.6rem" }}>
                        {stages.map((stage,index) => {
                            return (
                                <Grid item xs={12} key={index} className={classes.radioButtonBox}>
                                    <Field type="radio" name="stage" value={stage.value} />
                                    <label className={classes.label}>
                                        {stage.label}
                                    </label>
                                </Grid>
                            )

                        })}
                        <Grid item xs={12} className={classes.radioButtonBox}>
                            <Field type="radio" name="stage" value="all" />
                            <label className={classes.label}>
                                {"all"}
                            </label>
                        </Grid>

                    </Grid>

                </div>


                <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem" }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Platform
                    </Typography>
                    <Grid container style={{ paddingTop: "0.6rem" }}>
                        <Grid item xs={4} className={classes.radioButtonBox}>

                            <Field type="radio" name="platform" value={"rapid"} />
                            <label className={classes.label}>
                                i3D Rapid
                            </label>
                        </Grid>
                        <Grid item xs={4} className={classes.radioButtonBox}>
                            <Field type="radio" name="platform" value={"expert"} />
                            <label className={classes.label}>
                                i3D Expert
                            </label>
                        </Grid>
                        <Grid item xs={4} className={classes.radioButtonBox}>

                            <Field type="radio" name="platform" value={"both"} />
                            <label className={classes.label}>
                                both
                            </label>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem" }}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="h4" className={classes.sectionTitles}>
                                Active
                            </Typography>
                            <Field type="checkbox" name="active" />
                            <label className={classes.label}>
                                enable
                            </label>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h4" className={classes.sectionTitles}>
                                Comments
                            </Typography>
                            <Field type="checkbox" name="comment" />
                            <label className={classes.label}>
                                enable
                            </label>
                        </Grid>
                    </Grid>

                </div>
                <div className={classes.inputFields} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Question Type
                    </Typography>
                    <Select
                        name="type"
                        defaultValue="No type selected"
                        style={{ width: "100%" }}
                        className={classes.details}
                    >
                        <Option value={"number"} title={"number"}>
                            number
                        </Option>
                        <Option value={"text"} title={"text"}>
                            text
                        </Option>
                    </Select>

                </div>
            </Grid>

            <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem", textAlign:"center" }}>
                <Button
                    color="primary"
                    size="small"
                    type="button"
                    className={classes.submitButton}
                    onClick={() => formik.submitForm()}
                >
                    Save
                </Button>
                <MButton className={classes.cancelButton} style={{marginLeft: "2rem"}} color="inherit" onClick={() => history.goBack()}>
                    Cancel
                </MButton>
            </div>
        </Grid>


    );
}

export default QuestionNewForm;
