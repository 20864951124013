import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {},
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  centered: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  createNotification:{
    position:"fixed",
    right:"16px",
    bottom:"16px"
  },
  bodyContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    width: "100%",
    borderRadius: 8,
    padding: theme.spacing(3),
    backgroundColor: (props) => (props.shaded ? theme.palette.grey[200] : ""),
    transition: "all .3s ease",
    boxShadow: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 48,
    "&:hover": {
      boxShadow: theme.customShadows.widget,
    },
  },
  bodyText: {
    marginLeft: theme.spacing(1),
  },
}));
