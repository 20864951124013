import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
        width: "100%",
        backgroundColor: "#E5E5E5",
        overflow: "hidden",

    },
    pageTitleContainer: {
        '& .MuiTypography-h1': {
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "26px",
            lineHeight: "20px",
            color: "#000000",
        }
    },
    inlineStyle: {
        listStyle: "none",
    },
    profileLogo: {
        width: 65.56,
        height: 65,
        borderRadius: "50%",
        marginTop: "4%",
    },
    personalDetail: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "20px",
        alignItems: "center",
        color: "#000000"
    },

    detailsInput: {
        borderRadius: 10,
        padding: 4,
        marginTop: 2 + "%",
        width: "90% !Important",
        backgroundColor: "#FFFFFF",
        height: "40px",
        border: "1px solid #ffffff",
        boxShadow: theme.customShadows.widget,
        '& .MuiInputBase-root': {
            marginTop: 4 + "%"
        },
        '& .MuiNativeSelect-select.MuiNativeSelect-select': {
            fontSize: "14px"
        },

        '& .ant-select-selector': {
            border: "none !important"
        }

    },
    details: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        alignItems: "center",
        color: "#9D9D9D",
        marginTop: 5 + "%",
    },
    savePassButton: {
        border: "1px solid #137DFB",
        boxSizing: "border-box",
        borderRadius: "10px",
        marginTop: "20px",
        float: "right",
        textTransform: "capitalize",
        marginRight: 10 + "%",
    },
    deleteButton: {
        background: "#FF0000",
        borderRadius: "10px",
        color: "#ffffff",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "11px",
        lineHeight: "17px",
        padding: "13px",
        float: "right",
        textTransform: "capitalize",
    },


    // ________________________________FUTURE PROFILE CSS START_________________________________

    profileContainer: {
        background: "#FFFFFF",
        boxShadow: "0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 4%)",
        borderRadius: "10px",
        paddingTop: "1rem",
        textAlign: "center",
        paddingBottom: "1rem",
    },

    customFileUpload: {
        border: "1px solid #137DFB",
        display: "inline-block",
        padding: "6px 12px",
        cursor: "pointer",
        color: "#137DFB",
        fontFamily: "Montserrat",
        borderRadius: "5px",
        marginTop: "1rem",
    },

    socialMediaContainer: {
        marginTop: "1.5rem"
    },
    maedidetails: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "20px",
        alignItems: "center",
        color: "#9D9D9D",
        // marginLeft: "1rem"
    },

    socialMedia: {
        background: "#FFFFFF",
        boxShadow: theme.customShadows.widget,
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        marginTop: "1rem",
        padding: "0.8rem",

        '& div':{
            display: "flex",
            flexDirection: "column"
        },
        '& span':{
            width: "80%",
            display: "block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontSize: "14px",
            fontWeight: "600"
        }

    },

    interestContainer: {
        display: "flex",
        marginTop: "1rem",
        cursor: "pointer"
    },
    interestSection: {
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        position: "absolute",
        left: "55%",
        background: "#FFFFFF",
        boxShadow: "0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 4%)",
        borderRadius: "4px",
        flex: "none",
        order: "0",
        flexGrow: "0",
        margin: "0px 0px",
        width: "15%",
        height: "43vh",
        overflow: "hidden",
        zIndex: "99",
        paddingRight: "0px",
        '& div': {
            width: "100%",
            overflowY: "auto"
        },
        '& button': {
            background: "#0B5FFF",
            borderRadius: "6px",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            // marginBottom: "1rem",
            color: "#fff",
            width: "90%"
        },
        '& p': {
            paddingBottom: "1rem"
        },
        '& div > label': {
            margin: "0px !important",
            padding: "0rem 0rem 1rem 0rem",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "13px",
            width: "100%",
        },
        "& div::-webkit-scrollbar": {
            width: "10px",
            height: "64.65px",   /* width of the entire scrollbar */
        },

        "& div::-webkit-scrollbar-track ": {
            // background: "#C4C4C4",
            boxShadow: "inset 0 0 5px grey",
            borderRadius: "10px",       /* color of the tracking area */
        },

        "& div::-webkit-scrollbar-thumb": {
            backgroundColor: "#C4C4C4",   /* color of the scroll thumb */
            borderRadius: "20px",       /* roundness of the scroll thumb */
            border: " 2px solid #AEAEAE",  /* creates padding around scroll thumb */
        }
    },

    referredContainer: {
        background: "#FFFFFF",
        boxShadow: " 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 4%)",
        borderRadius: "10px",
        width: "100%",
        height: "50vh",
        overflowY: "auto",
        marginTop: "2rem",
        '&::-webkit-scrollbar': {
            width: "10px",
            height: "64.65px",
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: "inset 0 0 5px grey",
            borderRadius: "10px",
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#C4C4C4",   /* color of the scroll thumb */
            borderRadius: "20px",       /* roundness of the scroll thumb */
            border: " 2px solid #AEAEAE",
        },
        '& ul li': {
            display: "flex",
            alignItems: "center",
            height: "10vh",
            width: "100%",
            margin: "0px",
            padding: "0px"
        },
        '& ul li:nth-child( even )': {
            background: "#F2F7FF"
        },
        '& span': {
            padding: "0px 15px"
        },

    },
    referredPara: {
        marginLeft: "1rem",
        width: "80%",
        whiteSpace: "nowrap",
        overflow: "hidden !important",
        textOverflow: "ellipsis",
        display: "block"

    },


}))