import React, { useEffect } from "react";
import { PDFObject } from "react-pdfobject";
import useStyles from "./styles";
import { IconButton } from "@material-ui/core";
import { Home as CloseIcon } from "@material-ui/icons";
import { Invitation, ProjectDispatch } from "../../model";

import * as typeformEmbed from "@typeform/embed";
import { useDispatch } from "react-redux";
import { incrementProjectViews } from "../../actions/project";

interface Props {
  invitation: Invitation;
  section: string;
}

function useIncrementProjectViews(invitation: Invitation, section: string) {
  const dispatch = useDispatch<ProjectDispatch>();
  useEffect(() => {
    dispatch(incrementProjectViews(invitation.projectID!, section));
  }, [dispatch, invitation, section]);
}

function Form({ invitation, section }: Props) {
  const classes = useStyles();
  useIncrementProjectViews(invitation, section);

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.homebutton}
        href={`https://requestor.i3d.ai/form?id=${invitation.id}`}
      >
        <CloseIcon htmlColor="white" />
      </IconButton>
      <div className={classes.halfScreenContainer}>
        <ReactTypeformEmbed url={invitation.formLink[section]} />
      </div>
      <div className={classes.halfScreenContainer}>
        <PDFObject
          width="100%"
          height="100vh"
          url={invitation.pdfLink[section]}
        />
      </div>
    </div>
  );
}

class ReactTypeformEmbed extends React.Component<{ url: string }> {
  constructor(props) {
    super(props);
    this.el = null;
  }

  el: HTMLDivElement | null;

  componentDidMount() {
    if (this.el) {
      typeformEmbed.makeWidget(this.el, this.props.url, {
        hideFooter: false,
        hideHeaders: false,
      });
    }
  }
  render() {
    return (
      <div
        ref={(el) => (this.el = el)}
        style={{ width: "100%", height: "100%" }}
      />
    );
  }
}

export default Form;
