import axios from "axios";
import {app} from "../repositories/firebase" ;
export async function deleteNotifications(notificationId=null){
    const instanceurl =app.options.apiUrl
    let prarmTempObj={};
    if(notificationId){
        prarmTempObj.notificationId=notificationId;
    }

    const response=await axios.delete(
        instanceurl+".cloudfunctions.net/deleteNotification",
        {
            params:prarmTempObj,
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}