import { firebase, auth } from "../repositories/firebase";
import { handleError } from "../utils/decorators";
import { firestore } from "../repositories/firebase";

const userCollection = firestore.collection("users");

export class Auth {
  @handleError("Failed to sign in...")
  static async signInWithEmail(
    email: string,
    password: string
  ): Promise<firebase.User | Error> {
    const authResult = await auth.signInWithEmailAndPassword(email, password);
    if (!authResult.user) return new Error("No user exists for this account.");
    return authResult.user;
  }

  @handleError("Failed to reset password...")
  static async resetPassword(
    email: string,
    password: string,
    newPassword: string,
  ): Promise<string | Error> {
    const authResult = await auth.signInWithEmailAndPassword(email, password);
    if (!authResult.user) return new Error("wrong Password");
    var user = firebase.auth().currentUser;

    user.updatePassword(newPassword).then(function() {
      // Update successful.
    }).catch(function(error) {
      // An error happened.
    });
    return "password reset success";
  }

  @handleError("Failed to register...")
  static async registerWithEmail(
    name: string,
    lastName: string,
    email: string,
    password: string,
    sector: string,
  ): Promise<firebase.User | Error> {
    const authResult = await auth.createUserWithEmailAndPassword(
      email,
      password
    );
    // alert(authResult.user.uid);
    if (!authResult.user) return new Error("No user exists for this account.");
    await authResult.user.updateProfile({ displayName: name });
    await userCollection.doc(authResult.user.uid).set({
      sector: sector,
      lastName: lastName,
    });
    return authResult.user;
  }

  @handleError("Failed to sign in with Google...")
  static async signInWithGoogle(): Promise<firebase.User | Error> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    auth.useDeviceLanguage();
    const user = await auth.signInWithPopup(provider);
    if (!user.user) return new Error("No user found for this account.");
    return user.user;
  }

  @handleError("Failed to sign out...")
  static async signOut(): Promise<void | Error> {
    await auth.signOut();
  }
}
