import { UserState, UserActions, UserAction } from "../model/user";
import { Reducer } from "react";

const initialState: UserState = {
  isAuthenticated: false,
  isLoading: false,
  isInitialized: false,
  paymentLoading: false,
};

export const userReducer: Reducer<UserState, UserAction> = (
  state: UserState = initialState,
  action: UserAction
) => {
  switch (action.type) {
    case UserActions.AUTH_LOADING:
      return { ...state, isLoading: true };
    case UserActions.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: !!action.payload,
        isLoading: false,
        isInitialized: true,
        user: action.payload,
        error: undefined,
      };
    case UserActions.AUTH_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: action.payload?.message,
      };
    case UserActions.START_PAYMENT:
      return { ...state, paymentLoading: true };
    case UserActions.REDIRECTED:
      return { ...state, paymentLoading: false };
    case UserActions.ERROR:
      return { ...state, paymentLoading: false, error: action.payload };
    default:
      return state;
  }
};
