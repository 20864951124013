import {
  InvitationState,
  InvitationAction,
  InvitationActions,
} from "../model/invitation";
import { Reducer } from "react";

const initialState: InvitationState = {
  fetching: true,
};

export const invitationReducer: Reducer<InvitationState, InvitationAction> = (
  state: InvitationState = initialState,
  action: InvitationAction
): InvitationState => {
  switch (action.type) {
    case InvitationActions.FETCH:
      return { ...state, fetching: true };
    case InvitationActions.FETCHED_INVITATION:
      return { ...state, fetching: false, invitation: action.payload };
    case InvitationActions.FETCH_ERROR:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
};
