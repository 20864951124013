import React, { useEffect, Dispatch, useState } from "react";
import {
    createStyles, Grid, InputBase, Theme, Typography, withStyles, Button, IconButton
} from "@material-ui/core";
import { Checkbox, Divider } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "./styles";
import { Select, Input } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { ProfileInfo } from "../../model/profile";
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { values } from "lodash";
import profile from "./profile.svg";
import TextField from '@material-ui/core/TextField';
import facebook from "../../images/facebook.svg";
import twitter from "../../images/twitter.svg";
import linkedin from "../../images/linkedin.svg";
import doneIcon from "../../images/done.svg";
import rectangleHeart from "../../images/rectangleHeart.svg";
import userInvite from "../../images/userInvite.svg";
import registration from "../../images/registration.svg";
import investment from "../../images/investment.svg";
import addinterest from "../../images/addinterest.svg";
import copyText from "../../images/copyText.svg";

const { Option } = Select;
const intitialValues: ProfileInfo = {
    Name: "",
    Organization: "",
    emails: [],
    password: "",
    newPassword: "",
    c_newPassword: "",
};
function FutureProfileDetail() {
    const classes = useStyles();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.firebase.auth);
    const [interestDropdown, setInterestDropdown] = useState(false)

    const handleDropdwon = () => {
        setInterestDropdown(!interestDropdown)
    }

    return (
        <>
            <PageTitle
                title={
                    "Profile"
                }
                extra={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}
                    >
                    </div>
                }
            />
            <Formik<ProfileInfo>
                initialValues={intitialValues}
                validateOnMount={true}
                validateOnChange={true}
                validateOnBlur={true}

            >
                {(formik) => (
                    <Grid container style={{ padding: "5%" }} >
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <div className={classes.profileContainer}>
                                <img src={profile} />
                                <label for="file-upload" className={classes.customFileUpload}>
                                    Upload new photo
                                </label>
                                <input id="file-upload" type="file" style={{ display: "none" }} />
                            </div>
                            <div style={{ marginTop: "3.5rem" }}>
                                <Typography className={classes.details}>Social media</Typography>

                                <div className={classes.socialMediaContainer}>
                                    <div className={classes.socialMedia}>
                                        <img src={facebook} />
                                        <Typography className={classes.maedidetails}>Facebook</Typography>
                                    </div>
                                    <div className={classes.socialMedia}>
                                        <img src={twitter} />
                                        <Typography className={classes.maedidetails}>Twitter</Typography>
                                        <img src={doneIcon} style={{ marginLeft: "5rem" }} />
                                    </div>
                                    <div className={classes.socialMedia}>
                                        <img src={linkedin} />
                                        <Typography className={classes.maedidetails}>LinkedIn</Typography>
                                        <img src={doneIcon} style={{ marginLeft: "5rem" }} />
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <ul className={classes.inlineStyle} style={{ width: 100 + "%" }}>
                                <li>
                                    <Typography className={classes.personalDetail}>Personal Details</Typography>
                                </li>

                                <li className={classes.details} >
                                    <Typography className={classes.details}>Name</Typography>
                                    <Input
                                        className={classes.detailsInput}
                                        name="name"
                                        value={user.displayName}
                                        style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                        placeholder="e Invluencer Admin"
                                    />
                                </li>

                                <li className={classes.details} >
                                    <Typography className={classes.details}>Organization</Typography>
                                    <Input
                                        className={classes.detailsInput}
                                        name="Organisation"
                                        style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                        placeholder="Invluencer Ltd"
                                    />
                                </li>
                                <li className={classes.details} >
                                    <Typography className={classes.details}> E-Mail</Typography>
                                    <Input
                                        className={classes.detailsInput}
                                        name="email"
                                        value={user.email}
                                        style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                        placeholder="eadmin@invluencer.com"
                                    />
                                </li>

                                <li className={classes.details} >
                                    <Typography className={classes.details}>Country/Region</Typography>
                                    <Select
                                        name="Country/Region"
                                        defaultValue="Country/Region"
                                        style={{ width: "100%" }}
                                        className={classes.detailsInput}
                                    >

                                        <Option value="Country/Region">
                                            India
                                    </Option>

                                    </Select>
                                </li>

                                <li className={classes.details} >
                                    <Typography className={classes.details}>Role</Typography>
                                    <Select
                                        name="Role"
                                        defaultValue="Role"
                                        style={{ width: "100%" }}
                                        className={classes.detailsInput}
                                    >
                                        <Option value="">
                                            Angel Investor
                                </Option>

                                    </Select>
                                </li>

                            </ul>


                            {/* _____________________________INTEREST SECTION________________ */}


                            <ul className={classes.inlineStyle} style={{ width: 100 + "%", marginTop: "2rem" }}>
                                <li>
                                    <Typography className={classes.details}>Interest</Typography>
                                </li>
                                <li>
                                    <div className={classes.interestContainer}>
                                        <img src={addinterest} />
                                        <img src={addinterest} />
                                        <img src={addinterest} />
                                        <img src={addinterest} onClick={() => handleDropdwon()} />
                                    </div>

                                    {/* _____________________ONCLICK OPEN DROPDOWN________________ */}

                                    {interestDropdown && <div className={classes.interestSection}>
                                        <Typography >
                                            Interests (Max 5)
                                        </Typography>
                                        <div>
                                            <Checkbox>
                                                <Typography variant="body" weight="bold" >
                                                    Agriculture
                                            </Typography>
                                            </Checkbox>
                                            <Checkbox>
                                                <Typography variant="body" weight="bold" >
                                                    Architecture
                                            </Typography>
                                            </Checkbox>
                                            <Checkbox>
                                                <Typography variant="body" weight="bold" >
                                                    Banking
                                            </Typography>
                                            </Checkbox>
                                            <Checkbox>
                                                <Typography variant="body" weight="bold" >
                                                    Coding
                                            </Typography>
                                            </Checkbox>
                                            <Checkbox>
                                                <Typography variant="body" weight="bold" >
                                                    Cloud Computing
                                            </Typography>
                                            </Checkbox>
                                        </div>
                                        <Button>
                                            Add
                                        </Button>
                                    </div>}
                                </li>

                            </ul>


                            {/* ______________________PASSWORD MANAGEMENT ____________________ */}

                            <ul className={classes.inlineStyle} style={{ width: 100 + "%" }}>
                                <li>
                                    <Typography className={classes.personalDetail}>Password Management</Typography>
                                </li>

                                <li className={classes.details} >
                                    <Typography className={classes.details}>Primary password</Typography>
                                    <Input
                                        type="password"
                                        className={classes.detailsInput}
                                        name="password"
                                        style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                        placeholder="primary password"
                                    />
                                </li>

                                <li className={classes.details} >
                                    <Typography className={classes.details}>New password</Typography>
                                    <Input
                                        type="password"
                                        className={classes.detailsInput}
                                        name="newPassword"
                                        style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                        placeholder="new password"
                                    />
                                </li>

                                <li className={classes.details} >
                                    <Typography className={classes.details}>Confirm new password</Typography>
                                    <Input
                                        type="password"
                                        className={classes.detailsInput}
                                        name="c_newPassword"
                                        style={{ width: "100%", borderRadius: 10, paddingLeft: "15px" }}
                                        placeholder="confirma new password"
                                    />
                                </li>

                                <li>
                                    <Button
                                        color="primary"
                                        size="large"
                                        type="button"
                                        className={classes.savePassButton}
                                    >
                                        Save Password
                            </Button>
                                </li>

                            </ul>

                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div>
                                <Typography className={classes.personalDetail}>Personal Statistics</Typography>

                                <div className={classes.socialMedia}>
                                    <img src={rectangleHeart} />
                                    <div>
                                        <span>36</span>
                                        <Typography className={classes.maedidetails}>Projects liked</Typography>
                                    </div>
                                </div>
                                <div className={classes.socialMedia}>
                                    <img src={rectangleHeart} />
                                    <div>
                                        <span>36</span>
                                        <Typography className={classes.maedidetails}>New users invited</Typography>
                                    </div>
                                </div>
                                <div className={classes.socialMedia}>
                                    <img src={registration} />
                                    <div>
                                        <span>36</span>
                                        <Typography className={classes.maedidetails}>Days since registration</Typography>
                                    </div>
                                </div>
                                <div className={classes.socialMedia}>
                                    {/* <img src={investment} /> */}
                                    <img src={registration} />
                                    <div>
                                        <span>$587364</span>
                                        <Typography className={classes.maedidetails}>Investments made</Typography>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className={classes.scrollContainer} style={{ marginTop: "2rem" }}>
                                <Typography className={classes.personalDetail}>Referral Code</Typography>

                                <div className={classes.socialMedia}>
                                    <Typography className={classes.maedidetails} style={{ marginLeft: "0px" }}>INVLU-421-CD234</Typography>
                                    <img src={copyText} style={{ marginLeft: "10rem" }} />
                                </div>

                                <Typography className={classes.details}>People referred</Typography>
                                <div className={classes.referredContainer}>
                                    <ul className={classes.inlineStyle} style={{ padding: "0px" }}>
                                        <li>
                                            <span>1.</span>
                                            <Typography className={classes.referredPara} >amiartehrani@invluencer.com</Typography>
                                        </li>
                                        <li>
                                            <span>2.</span>
                                            <Typography className={classes.referredPara} >samiartehrani@invluencer.com</Typography>
                                        </li>
                                        <li>
                                            <span>3.</span>
                                            <Typography className={classes.referredPara} >amiartehrani@invluencer.com</Typography>
                                        </li>
                                        <li>
                                            <span>4.</span>
                                            <Typography className={classes.referredPara} >samiartehrani@invluencer.com</Typography>
                                        </li>
                                        <li>
                                            <span>5.</span>
                                            <Typography className={classes.referredPara} >amiartehrani@invluencer.com</Typography>
                                        </li>
                                        <li>
                                            <span>6.</span>
                                            <Typography className={classes.referredPara} >samiartehrani@invluencer.com</Typography>
                                        </li>
                                        <li>
                                            <span>7.</span>
                                            <Typography className={classes.referredPara} >amiartehrani@invluencer.com</Typography>
                                        </li>
                                        <li>
                                            <span>8.</span>
                                            <Typography className={classes.referredPara} >samiartehrani@invluencer.com</Typography>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
}



export default FutureProfileDetail;
