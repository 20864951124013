import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

// pages
import { Dashboard, Notifications, ProfileDetails, FutureProfileDetail,Faq, Rewards, Technical, NotificationV1 } from "../../pages";
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { NotificationsDispatch } from "../../model";

//actions
import { fetchNotifications } from "../../actions/notifications";
import ResponseList from "../Responses/ResponseList";
import BlockedDomainList from "../../pages/blockedDomain/listOfBlockedDomain";
import BlockedUserDomainsList from "../../pages/blockedDomain/listOfBlockedUser";

function Layout(props: any) {
  var classes = useStyles();
  const dispatch = useDispatch<NotificationsDispatch>();

  React.useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  const layoutState = useSelector((state: RootState) => state.layout);

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar/>
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
          style={{backgroundColor:"#F2F7FF"}}
        >
          <div className={classes.fakeToolbar}/>
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/faqs" component={Faq} />
            <Route path="/app/technical" component={Technical} />
            {/* <Route path="/app/notifications" component={Notifications} /> */}
            <Route path="/app/notifications" component={NotificationV1} />
            <Route path="/app/blockdomain/:name" component={BlockedUserDomainsList} />

            <Route path="/app/blocked-domain" component={BlockedDomainList} />
            <Route path="/app/profile" component={ProfileDetails} />
            <Route path="/app/rewards" component={Rewards} />
            <Route path="/app/profileDetail" component={FutureProfileDetail} />
            <Route path="/responses/:id" component={ResponseList} />
            <Route render={() => <Redirect to="/app/dashboard" />} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
