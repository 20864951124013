import React, { useState } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Fade,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";
import { TextField } from "material-ui-formik-components";
import classnames from "classnames";
import useStyles from "./styles";
import google from "./../../images/google.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { Formik, Field } from "formik";
import { AuthInfo, registerValidation } from "./index";
import { UserDispatch } from "../../model";
import { loginUserWithGoogle, registerUser } from "../../actions/user";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Form, Checkbox } from "formik-antd";
import { Select } from "formik-antd";
import { sectors } from "../../pages/create/components/data";
import ReCAPTCHA from "react-google-recaptcha";
import { useFirebase } from "react-redux-firebase";
import { callableFunctions } from "../../repositories/firebase";

interface Props {
  plan: string | null;
}
const { Option } = Select;

export function RegisterForm(props: Props) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<UserDispatch>();
  const classes = useStyles();
  const firebase = useFirebase();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);


  const handleSubmit = async (values: AuthInfo) => {
    if (!recaptchaToken) {
      console.log('reCAPTCHA token is missing');
      return;
    }

    try {
      const verifyRecaptcha = callableFunctions.httpsCallable('verifyRecaptcha');
      const result = await verifyRecaptcha({ token: recaptchaToken });

      if (result.data.success) {
        dispatch(registerUser(values.name, values.lastName, values.email, values.sector, values.password, props.plan));
      } else {
        console.log('reCAPTCHA verification failed:', result.data.error);
      }
    } catch (error) {
      console.error('reCAPTCHA verification error:', error);
    }
  };

  return (
    <Formik<AuthInfo>
      validateOnMount
      validateOnChange
      validateOnBlur
      initialValues={{
        name: "",
        lastName: "",
        email: "",
        password: "",
        sector: "",
        terms: false,
      }}
      validationSchema={registerValidation}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <Form style={{padding:"60px"}} onSubmit={formik.handleSubmit}>
            <Typography variant="h1" className={classes.greeting}>
              Welcome!
            </Typography>
            <Typography variant="h2" className={classes.subGreeting}>
              Create your account
            </Typography>
            <Field
              required
              name="name"
              label="Full Name"
              component={TextField}
              margin="normal"
              placeholder="Full Name"
              type="text"
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
            />

            <Field
              required
              name="lastName"
              label="Last Name"
              component={TextField}
              margin="normal"
              placeholder="Last Name"
              type="text"
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
            />

            <Select
              showSearch
              name="sector"
              placeholder="Select a sector"
              optionFilterProp="children"
              style={{ width: "100%", marginTop: 35 }}
              className={classnames(classes.textFieldUnderline, classes.textField, classes.selectorTextField)}
              filterOption={(input, option) => {
                if (option)
                  return (
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                return true;
              }}
              onDropdownVisibleChange={(open) => {
                if (!open && document.activeElement instanceof HTMLElement)
                  document.activeElement.blur();
              }}
            >
              {sectors.map((sector) => (
                <Option value={sector.value} key={sector.value}>
                  {sector.label}
                </Option>
              ))}
            </Select>

            <Field
              required
              name="email"
              label="Email"
              component={TextField}
              margin="normal"
              placeholder="Email Address"
              type="email"
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
            />
            <Field
              required
              name="password"
              label="Password"
              component={TextField}
              margin="normal"
              placeholder="Password"
              type={passwordShown ? "text" : "password"}
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                    style={{marginRight:'0px'}}
                      aria-label="toggle password visibility"
                      onClick={(e) => {
                        e.preventDefault();
                        setPasswordShown(!passwordShown);
                      }}
                      edge="end"
                    >
                      {passwordShown ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Checkbox name="terms" style={{ marginTop: 10, marginBottom: 20 }}>
              <Typography variant="caption">
                By clicking the checkbox, you agree to abide by the terms and
                conditions as outlined in{" "}
                <Link href="/terms-and-conditions.pdf">our T&C’s Policy</Link>
              </Typography>
            </Checkbox>
            <ReCAPTCHA
              sitekey="6LcxoiwqAAAAAI6VQGUN19Q5r20DJg8xkAzPc8jZ"
              onChange={(token) => setRecaptchaToken(token)}
            />
            <Fade in={!!user.error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {user.error ?? "Something went wrong during login."}
              </Typography>
            </Fade>
            <div className={classes.creatingButtonContainer}>
              {user.isLoading ? (
                <CircularProgress size={26} />
              ) : (
                <Button
                  type="submit"
                  disabled={!formik.isValid || !recaptchaToken}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.createAccountButton}
                >
                  Create your account
                </Button>
              )}
            </div>
            <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
              <Typography className={classes.formDividerWord}>or</Typography>
              <div className={classes.formDivider} />
            </div>
            <Button
              size="large"
              type="button"
              className={classnames(
                classes.googleButton,
                classes.googleButtonCreating
              )}
              onClick={() => {
                dispatch(loginUserWithGoogle());
              }}
            >
              <img src={google} alt="google" className={classes.googleIcon} />
              &nbsp;Sign in with Google
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
