import React, { Dispatch, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import * as Yup from "yup";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button as MButton,
} from "@material-ui/core";
import { useCounter } from "ahooks";
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import {FaqForm}  from "./components";
import {QuestionForm}  from "./components";
import { Button } from "../../components/Wrappers/Wrappers";
import FadeIn from "react-fade-in";
import { QuestionDispatch, Questions,Faqs } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import Loader from "../../components/Loader/Loader";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import {QuestionRepo} from "../../repositories/question";

const validationSchema = Yup.object().shape<Faqs>({
    question: Yup.string().required("this field is necessary"),
    context: Yup.string(),
    platform: Yup.string(),
  
});

const intitialValues: Faqs = {
  question: "",
  context: "",
  platform:'',
};

function CreateFaq() {
  const classes = useStyles();
  const history = useHistory();
  const { creating, createError } = useSelector(
    (state: RootState) => state.question
  );
  const dispatch = useDispatch<QuestionDispatch>();


  if (creating) return <Loader text="Creating project..." />;
  if (createError) return <ErrorDisplay error={createError} />;

  return (
    <div className={classes.root}>
      <Formik<Faqs>
        initialValues={intitialValues}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
            QuestionRepo.createFaq(values).then((res)=>{
                history.push('/app/faqs')
            })
        }}
      >
        {(formik) => (
          <Form className={classes.root}>
            <FadeIn>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                  <FaqForm/>
                  <div style={{ marginBottom: 40 }} />
              </div>
            </FadeIn>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateFaq;
