export interface SectionOption {
  label: string;
  value: string;
  description: string;
}

export function getSectionOptions(sections: string[]): SectionOption[] {
  if (!sections) return [];
  return sectionOptions.filter((v) => sections.includes(v.value));
}
export function getSectionDescription(section: string): string | undefined {
  return sectionOptions.find((s) => s.value === section)?.description;
}

export function getSectionLabel(section: string): string | undefined {
  return sectionOptions.find((s) => s.value === section)?.label;
}

export function getStageLabel(stage: string): string | undefined {
  return stages.find((s) => s.value === stage)?.label;
}

export function getSectorLabel(sector: string): string | undefined {
  return sectors.find((s) => s.value === sector)?.label;
}

export const sectionOptions: SectionOption[] = [
  {
    label: "Team",
    value: "team",
    description:
      "The Team was analysed on factors with a view to their standout or unique capabilities for succeeding in developing and growing the company. The score also indicates the potential the team has to carry out their vision. It is important to note that a low score does not necessarily mean the company will not succeed as there may be a gap in the team which allows for an investor to add value. Often the investment sought will indicate that funding is allocated to boosting a team. As such, this section should be read holistically with other sections such as HR and financial plans. Prior to any substantial investment into a company, investors should make themselves comfortable with a Team through correspondence, meetings, etc.",
  },
  {
    label: "Idea/Product",
    value: "idea",
    description:
      "The Idea & Product was analysed on factors that sought to match the problem the company is seeking to address against the solution they are proposing. It aims to examine the I&P in terms of where it sits in the ecosystem in which it is operating and how expansive their vision is. Success of the I&P is often related to the Competition, and Market & Customer sectional analysis.",
  },
  {
    label: "Market",
    value: "market",
    description:
      "The M&C for the company examines the planned operational sphere and boundaries of operation. It is an analysis of how clearly identified and realistic the company’s goals and ambitions are. Factors identified here and scored should be reviewed in conjunction with a Competition Report.",
  },
  {
    label: "Competition",
    value: "competition",
    description:
      "The report aims to review the company’s identification of the Marketplace in which the company is operating and how it compares or separates itself against the competition. This is linked to the Idea & Product and identification of risk factors, IP protection, etc.",
  },
  {
    label: "BM/Finance",
    value: "finance",
    description:
      "The review of the BM&F section is cross cutting sectional, aiming to examine how the solution provided by the company will over a return to investors. Our report is a holistic review often based on predictions and data supplied by the company if they are in early stage. A deeper dive into the financial statements of a company should take place prior to any substantial investment into a company.",
  },
  {
    label: "ESG",
    value: "esg",
    description:
      "ESG is rapidly becoming a key definer for success and sustainability of companies. While often not relevant in early stage companies, a clear strategy by a company indicates the intention of the company to ensure integrity in its Governance, Environment & Social structures.",
  },
];

// export const sectors: { label: string; value: string }[] = [
//   {
//     label: "Software/Tech",
//     value: "tech",
//   },
//   {
//     label: "Hardware",
//     value: "hardware",
//   },
//   {
//     label: "Telecoms",
//     value: "telecoms",
//   },
//   {
//     label: "Retail",
//     value: "retail",
//   },
//   {
//     label: "Pharma/Health",
//     value: "pharma-health",
//   },
//   {
//     label: "AgTech",
//     value: "agtech",
//   },
//   {
//     label: "Manufacturing",
//     value: "manufacturing",
//   },
//   {
//     label: "Logistics/Supply Chain",
//     value: "logistics",
//   },
//   {
//     label: "Hospitality/Tourism",
//     value: "hospitality",
//   },
//   {
//     label: "Infrastructure",
//     value: "infrastructure",
//   },
//   {
//     label: "Renewable Energy",
//     value: "renewable-energy",
//   },
//   {
//     label: "Other",
//     value: "other",
//   },
// ];

export const stages: { label: string; value: string; description: string }[] = [
  {
    label: "Early Stage",
    value: "early",
    description:
      "Some IP in place, proof of concept completed or in process; little to no revenue, negative operating income, no operating history, no comparable firms, all value is based entirely on future growth.",
  },
  {
    label: "Growth",
    value: "growth",
    description:
      "IP developed and proven, needs to build infrastructure to scale; revenues increasing, in low or negative, very limited operating history, some comparable firms but in same stage of growth, source of value still based on future growth prospects.",
  },
  {
    label: "Mature Growth",
    value: "mature-growth",
    description:
      "IP developed and proven, revenue traction, operating income growing, scaling infrastructure built, some operating history, more comparable firms out there at similar stage, a poertion of Company value can be attributable to existing assets, but growth still dominates.",
  },
  {
    label: "Mature",
    value: "mature",
    description:
      "Consistent cash flow, Steady revenue growth (or could be slowing) operating income still growing, operating history can be used in valuation, large number of comparable firms at different stages, more value comes from existing assets than growth.",
  },
];

export const sectors: { label: string; value: string }[] = [
  {
    label: "Advanced Medical Equipment & Technology",
    value: "medical-equipement",
  },
  { label: "Advertising & Marketing", value: "advertising" },
  { label: "Aerospace & Defense", value: "aerospace" },
  { label: "Agricultural Chemicals", value: "agricultural-chemicals" },
  { label: "Airline", value: "airline" },
  { label: "Airport Operators & Services", value: "aiport-services" },
  { label: "Aluminum", value: "aluminum" },
  { label: "Apparel & Accessories", value: "apparel" },
  { label: "Apparel & Accessories Retailers", value: "apparel-retailers" },
  { label: "Appliances, Tools & Housewares", value: "appliances" },
  { label: "Auto & Truck Manufacturer", value: "auto-manufacturer" },
  {
    label: "Auto Vehicles, Parts & Services Retailers",
    value: "auto-retailers",
  },
  { label: "Auto, Truck & Motorcycle Part", value: "auto-parts" },
  { label: "Banks", value: "banks" },
  { label: "Biotechnology & Medical Research", value: "biotech" },
  { label: "Brewers", value: "brewers" },
  { label: "Broadcasting", value: "broadcasting" },
  { label: "Call Center Services", value: "call-center" },
  { label: "Cleaning Services", value: "cleaning-service" },
  { label: "Commercial Educational Services", value: "commercial-educational" },
  { label: "Corporate Accounting Services", value: "corporate-accounting" },
  { label: "Data Processing Services", value: "data-processing" },
  { label: "Exhibition & Conference Services", value: "exhibition" },
  {
    label: "Health, Safety & Fire Protection Equipment",
    value: "health-safety-fire-protection",
  },
  { label: "Industrial Design Services", value: "industrial-design" },
  { label: "Industrial Equipment Rental", value: "industrial-equipement" },
  { label: "Legal Services", value: "legal-services" },
  { label: "Maintenance & Repair Services", value: "maintenance-repair" },
  { label: "Management Consulting Services", value: "management-consulting" },
  { label: "Office Equipment & Supplies Rental", value: "office-equipement" },
  { label: "Office Furniture", value: "office-furniture" },
  { label: "Office Supplies", value: "office-supplies" },
  { label: "Office Supplies Wholesale", value: "office-supplies-wholesale" },
  { label: "Pest Control Services", value: "pest-control" },
  { label: "Security Services", value: "security-services" },
  { label: "Testing Laboratories", value: "testing-lab" },
  {
    label: "Transaction & Payment Services",
    value: "transaction-payment-services",
  },
  {
    label: "Translation & Interpretation Services",
    value: "translation-interpretation-services",
  },
  { label: "Business Support Supplies", value: "business-support-supplies" },
  { label: "Casinos & Gaming", value: "casion-gaming" },
  { label: "Closed End Funds", value: "closed-end-funds" },
  { label: "Coal", value: "coal" },
  { label: "Commercial Printing Services", value: "commercial-printing" },
  { label: "Commercial REITs", value: "commercial-reit" },
  { label: "Commodity Chemical", value: "commodity-chemical" },
  { label: "Communications & Networking", value: "communcations-networking" },
  {
    label: "Computer & Electronics Retailers",
    value: "computer-electonics-retailer",
  },
  { label: "Computer Hardware", value: "computer-hardware" },
  { label: "Construction & Engineering", value: "construction-engineering" },
  { label: "Construction Materials", value: "construction-materials" },
  {
    label: "Construction Supplies & Fixture",
    value: "construction-supplies-fixture",
  },
  { label: "Consumer Lending", value: "consumer-lending" },
  { label: "Consumer Publishing", value: "consumer-publishing" },
  {
    label: "Corporate Financial Services",
    value: "corporate-financial-services",
  },
  {
    label: "Courier, Postal, Air Freight & Land-based Logistics",
    value: "courier-postal-air-freight",
  },
  { label: "Department Stores", value: "department-stores" },
  { label: "Discount Stores", value: "discount-stores" },
  { label: "Distillers & Wineries", value: "distillers" },
  { label: "Diversified Chemical", value: "diversified-chemical" },
  {
    label: "Diversified Industrial Goods Wholesaler",
    value: "diversified-industrial-goods-wholesaler",
  },
  {
    label: "Diversified Investment Services",
    value: "diversified-investment-services",
  },
  { label: "Diversified Mining", value: "diversified-mining" },
  { label: "Diversified REITs", value: "diversified-reit" },
  { label: "Drug Retailers", value: "drug-retailers" },
  { label: "Electric Utilities", value: "electric-utilities" },
  {
    label: "Electrical Components & Equipment",
    value: "electrical-components-equipement",
  },
  { label: "Electronic Equipment & Parts", value: "electronic-equipement" },
  { label: "Employment Services", value: "employment-services" },
  { label: "Entertainment Production", value: "entertainement-production" },
  {
    label: "Environmental Services & Equipment",
    value: "environemental-services-equipement",
  },
  { label: "Exchange-Traded Funds", value: "exchange-traded-funds" },
  {
    label: "Financial & Commodity Market Operators & Services Providers",
    value: "financial-commodity-market-providers",
  },
  {
    label: "Financial Technology",
    value: "fintech",
  },
  { label: "Fishing & Farming", value: "fishing-farming" },
  { label: "Agricultural Biotechnology", value: "agricultural-biotechnology" },
  {
    label: "Agricultural Consultancy Services",
    value: "agricultural-consultancy-services",
  },
  {
    label: "Agriculture Support Services",
    value: "agricultural-support-services",
  },
  { label: "Animal Breeding", value: "animal-breeding" },
  { label: "Animal Feed", value: "animal-feed" },
  { label: "Aquaculture", value: "aquaculture" },
  { label: "Cattle Farming", value: "cattle-farming" },
  { label: "Coffee, Tea & Cocoa Farming", value: "coffee-tea-cocoa-farming" },
  { label: "Commercial Fishing", value: "commercial-fishing" },
  { label: "Commercial Nurseries", value: "commercial-nurseries" },
  { label: "Fishing & Farming Wholesale", value: "fishing-farming-wholesale" },
  { label: "Fur Farming", value: "fur-farming" },
  { label: "Grain (Crop) Production", value: "grain-production" },
  { label: "Hog & Pig Farming", value: "hog-pig-farming" },
  { label: "Organic Farming", value: "organic-farming" },
  { label: "Poultry Farming", value: "poultry-farming" },
  { label: "Sheep & Specialty Livestock Farming", value: "seep-livestock" },
  { label: "Sugarcane Farming", value: "surgarcane-farming" },
  {
    label: "Vegetable, Fruit & Nut Farming",
    value: "vegetable-fruit-nut-farming",
  },
  { label: "Food Processing", value: "food-processing" },
  { label: "Food Retail & Distribution", value: "food-retail-distribution" },
  { label: "Beer, Wine & Liquor Store", value: "beer-wine-liquor-store" },
  { label: "Food Market", value: "food-market" },
  { label: "Food Wholesale", value: "food-wholesale" },
  {
    label: "Supermarkets & Convenience Store",
    value: "supermarket-convenience-store",
  },
  { label: "Tobacco Store", value: "tobacco-store" },
  { label: "Vending Machine Provider", value: "vending-machine-provider" },
  { label: "Footwear", value: "footwear" },
  { label: "Forest & Wood Product", value: "forest-wood-product" },
  { label: "Gold", value: "gold" },
  { label: "Ground Freight & Logistic", value: "ground-freight-logistic" },
  {
    label: "Healthcare Facilities & Services",
    value: "healthcare-facilities-services",
  },
  { label: "Heavy Electrical Equipment", value: "heavy-electrical-equipement" },
  { label: "Heavy Machinery & Vehicles", value: "heavy-machinery-vehicles" },
  { label: "Highways & Rail Tracks", value: "highway-rail-tracks" },
  { label: "Home Furnishings", value: "home-furnishings" },
  { label: "Home Furnishings Retailers", value: "home-furnishing-retailers" },
  {
    label: "Home Improvement Products & Services Retailers",
    value: "home-improvment",
  },
  { label: "Homebuilding", value: "homebuilding" },
  { label: "Hotels, Motels & Cruise Lines", value: "hotels-motels-cruise" },
  { label: "Household Electronic", value: "household-electronic" },
  { label: "Household Products", value: "household-products" },
  { label: "Independent Power Producer", value: "independent-power-producer" },
  { label: "Industrial Conglomerate", value: "industrial-conglomerate" },
  {
    label: "Industrial Machinery & Equipment",
    value: "industrial-machinery-equipement",
  },
  { label: "Insurance Funds", value: "insurance-funds" },
  { label: "Integrated Oil & Gas", value: "integrated-oil-gas" },
  {
    label: "Integrated Telecommunications Services",
    value: "integrated-telecommuncations-services",
  },
  {
    label: "Investment Banking & Brokerage Services",
    value: "investment-banking-brokerage-services",
  },
  {
    label: "Investment Holding Companies",
    value: "investment-holding-companies",
  },
  {
    label: "Investment Management & Fund Operators",
    value: "investment-management-fund-operators",
  },
  { label: "Iron & Steel", value: "iron-steel" },
  { label: "IT Services & Consulting", value: "it-services-consulting" },
  { label: "Leisure & Recreation", value: "leisure-recreation" },
  {
    label: "Adventure Sports Facilities & Ski Resorts",
    value: "adventure-sports-facilities-ski-resorts",
  },
  { label: "Amusement Parks and Zoos", value: "amusement-parks-zoos" },
  { label: "Golf Courses", value: "golf-courses" },
  { label: "Guided Tour Operators", value: "guided-tour-operators" },
  { label: "Gyms, Fitness and Spa Centers", value: "gyms-fitness-spa-centers" },
  { label: "Hunting & Fishing", value: "hunting-fishing" },
  { label: "Marinas", value: "marinas" },
  {
    label: "Movie Theaters & Movie Products",
    value: "movie-theaters-movie-products",
  },
  { label: "Museums & Historic Places", value: "museums-historic-places" },
  { label: "Professional Sports Venues", value: "professional-sports-venues" },
  { label: "Public Sport Facilities", value: "public-sport-facilities" },
  { label: "Theatres & Performing Arts", value: "theatres-performing-arts" },
  { label: "Travel Agents", value: "travel-agents" },
  { label: "Life & Health Insurance", value: "life-health-insurance" },
  { label: "Managed Healthcare", value: "managed-healthcare" },
  { label: "Marine Freight & Logistic", value: "marine-freight-logistic" },
  { label: "Marine Port Services", value: "marine-port-services" },
  {
    label: "Medical Equipment, Supplies & Distribution",
    value: "medical-equipement-supplies-distribution",
  },
  {
    label: "Mining Support Services & Equipment",
    value: "mining-support-services-equipement",
  },
  {
    label: "Miscellaneous Specialty Retailers",
    value: "misellaneous-specialty-retailers",
  },
  {
    label: "Multiline Insurance & Broker",
    value: "multiline-insurance-broker",
  },
  { label: "Multiline Utilities", value: "multiline-utilities" },
  { label: "Mutual Funds", value: "mutual-funds" },
  { label: "Natural Gas Utilities", value: "natural-gas-utilities" },
  { label: "Non-Alcoholic Beverages", value: "non-alcoholic-beverages" },
  {
    label: "Non-Gold Precious Metals & Minerals",
    value: "non-gold-precious-metals-minerals",
  },
  {
    label: "Non-Paper Containers & Packaging",
    value: "non-paper-containers-packaging",
  },
  { label: "Oil & Gas Drilling", value: "oil-gas-drilling" },
  {
    label: "Oil & Gas Exploration and Production",
    value: "oil-gas-exploration-production",
  },
  {
    label: "Oil & Gas Refining and Marketing",
    value: "oil-gas-refining-marketing",
  },
  {
    label: "Oil & Gas Transportation Services",
    value: "oil-gas-transportation-services",
  },
  {
    label: "Oil Related Services and Equipment",
    value: "oil-related-services-equipement",
  },
  { label: "Online Services", value: "online-services" },
  {
    label: "Content & Site Management Services",
    value: "content-site-management-services",
  },
  {
    label: "E-commerce & Marketplace Services",
    value: "ecommerce-marketplace-services",
  },
  { label: "Internet Gaming", value: "internet-gaming" },
  {
    label: "Internet Security & Transactions Services",
    value: "internet-security-transaction-services",
  },
  { label: "Search Engines", value: "search-engines" },
  { label: "Social Media & Networking", value: "social-media-networking" },
  { label: "Paper Packaging", value: "paper-packaging" },
  { label: "Paper Product", value: "paper-product" },
  {
    label: "Passenger Transportation, Ground & Sea",
    value: "passenger-transportation-ground-sea",
  },
  { label: "Pension Funds", value: "pension-funds" },
  { label: "Personal Products", value: "personal-products" },
  { label: "Personal Services", value: "personal-services" },
  {
    label: "Accounting & Tax Preparation",
    value: "accounting-tax-preparation",
  },
  { label: "Child Care & Family Services", value: "childcare-family-services" },
  { label: "Consumer Goods Rental", value: "consumer-goods-rental" },
  { label: "Consumer Repair Services", value: "consumer-repair-services" },
  { label: "Funeral Services", value: "funeral-services" },
  { label: "General Education Services", value: "general-education-services" },
  { label: "Personal Care Services", value: "personal-care-services" },
  { label: "Personal Legal Services", value: "personal-legal-services" },
  { label: "Pharmaceuticals", value: "pharmaceuticals" },
  { label: "Phones & Handheld Devices", value: "phone-handheld-devices" },
  {
    label: "Professional Information Services",
    value: "professional-information-services",
  },
  {
    label: "Property & Casualty Insurance",
    value: "property-casualty-insurance",
  },
  {
    label: "Real Estate Rental, Development & Operation",
    value: "real-estate-rental-development-operation",
  },
  { label: "Real Estate Services", value: "real-estate-services" },
  { label: "Recreational Products", value: "recreational-products" },
  { label: "Reinsurance", value: "reinsurance" },
  {
    label: "Renewable Energy Equipment & Services",
    value: "renewable-energy-equipement-services",
  },
  { label: "Renewable Fuels", value: "renewable-fuels" },
  { label: "Residential REITs", value: "residential-reits" },
  { label: "Restaurants & Bars", value: "restaurant-bars" },
  {
    label: "Semiconductor Equipment & Testing",
    value: "semiconductor-equipement-testing",
  },
  { label: "Semiconductors", value: "semiconductor" },
  { label: "Shipbuilding", value: "shipbuilding" },
  { label: "Software", value: "software" },
  { label: "Specialized REITs", value: "specialized-reits" },
  { label: "Specialty Chemicals", value: "specialty-chemicals" },
  { label: "Specialty Mining & Metal", value: "specialty-mining-metal" },
  { label: "Textiles & Leather Goods", value: "textiles-leather-goods" },
  { label: "Tires & Rubber Product", value: "tires-rubber-product" },
  { label: "Tobacco", value: "tobacco" },
  { label: "Toys & Children’s Products", value: "toys-children-products" },
  { label: "UK Investment Trusts", value: "uk-investment-trusts" },
  { label: "Uranium", value: "uranium" },
  { label: "Water & Related Utilities", value: "water-related-utilities" },
  {
    label: "Wireless Telecommunications Services",
    value: "wireless-telecommuncations-services",
  },
];
