import React, { useEffect, useState } from 'react';
import Modal from "antd/lib/modal/Modal";
import {
	Button,TextField
} from "@material-ui/core";
import useStyles from '../styles';
import invitation from '../../../repositories/invitation';

function WeightModal(props)
{
    const [technical,setTechnical]=useState("");
    const [rapid,setRapid]=useState('');
    const [expert,setExpert]=useState('');
    const [load,setLoad]=useState(true);
    const [error,setError]=useState('');
    const handleClose = ()=>{
        props.setOpen(false)
    }

    useEffect(()=>{
        invitation.getWeights().then((res)=>{
            setTechnical(res?.technical);
            setRapid(res?.rapid);
            setExpert(res?.expert);
            setLoad(false);
        })
    },[])
    const submitForm = ()=>{
        console.debug(parseFloat(technical)+parseFloat(rapid)+parseFloat(expert))
        if(parseFloat(technical)+parseFloat(rapid)+parseFloat(expert)==1)
        {
        invitation.updateWeights({technical:technical,expert:expert,rapid:rapid}).then((res)=>{
            window.location.reload();
        })

        }
        else
        {
            setError("all the three weights should add upto 1")
        }
        // reward.create({network:network,rewards:rewards}).then((res)=>{
        //     props.setAddOpen(false)
        //     window.location.reload(true);
        // })
    }
    
    return (
        <>
        <Modal
          title="Add"
          centered
        //   className={classes.modalTeam}
          zIndex={2}
          visible={props.open}
            onCancel={handleClose}
          footer={[
            <Button key="back"
              onClick={handleClose}
            >
              Cancel
            </Button>,
            <Button onClick={submitForm}
            >
              Submit
            </Button>
          ]}
        >

            {
                load?<h1>loading ...</h1>:
            <>
            <div>
            <TextField id="outlined-basic" label="Technical Weighting" value={technical} variant="outlined" onChange={(e)=>{setTechnical(e.target.value)}} />
            </div>
            <div style={{marginTop:10}}>
            <TextField id="outlined-basic" label="Expert Weighting" value={expert} variant="outlined" onChange={(e)=>{setExpert(e.target.value)}}/>
            </div>
            <div style={{marginTop:10}}>
            <TextField id="outlined-basic" label="Rapid  Weighting" value={rapid} variant="outlined" onChange={(e)=>{setRapid(e.target.value)}}/>
            </div>
            <div style={{color:'red'}}>{error}</div>
            </>
            }
        </Modal>
</>
    )


}

export default WeightModal

