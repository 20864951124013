import React, { useEffect, Dispatch, useState } from "react";
import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";

import {
	Fab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { searchString } from "../../actions/search";
import useStyles from "./styles";
import { TechnicalList } from "../../components/QuestionList";
import { RootState } from "../../reducers";
import {
	SearchAction,
} from "../../model";
import { TechnicalFactor } from "../../model/question";
import _ from "lodash";
import invitation  from "../../repositories/invitation";


const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#F2F7FF",
		fontWeight: "bold",
	},
}))(TableCell);

function Technical() {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();

	const keys = [
		{id:1,label:""},
		{id:2,label:"Question"},
		{id:3,label:"section"},
		{id:4,label:"weight"},
	];
	
	const search = useSelector((state: RootState) => state.search);
	const dispatchSearch = useDispatch<Dispatch<SearchAction>>();
	const [questions, setQuestions] = useState<TechnicalFactor[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");
	
	useEffect(() => {
		dispatchSearch(searchString(searchQuery));
	}, [dispatchSearch]);
	useEffect(() => {
        invitation.getTechnicalQuestions().then((res)=>{
            setQuestions(res);
        })
	}, [search.searchString]);

    // if (!questions.length)
    // return <Loader text="Fetching your Questions..." />;

	return (
		<>
			<Fab
				className={classes.fab}
				color="primary"
				variant="extended"
				aria-label="add"
				onClick={goToCreate}
			>
				<AddIcon />
				New Factor
			</Fab>
			<>
				
				{ questions.length && <div className={classes.container}>
					
						<TableContainer>
						<Table stickyHeader className="mb-0">
							<TableHead>
								<TableRow>
									{keys.map((key) => (
										<StyledTableCell key={key.id}>{key.label}</StyledTableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
					{questions.filter((v) =>
								v.value
									.toLowerCase()
									.includes(search.searchString.toLowerCase())
							).map((v, index) => {									
										return <TechnicalList key={index} data={v}/>															
					})}
							</TableBody>
						</Table>
					</TableContainer>
					
				</div> }
			</>
		</>
	);

	function goToCreate(e: React.MouseEvent): void {
		e.preventDefault();
		return history.push("/create-factor");
	}
}



export default Technical;