import {
  ProjectActions,
  ProjectThunk,
  FormInfo,
  Invitation,
  ProjectAction,
  Project,
  ShareInfo,
  ShareReport,
} from "../model";
import { History, LocationState } from "history";
import { ProjectRepo } from "../repositories/project";
import { array2Object, array2ObjectFunc } from "../utils";
import { firestore } from "firebase";

function _createPdfLinks(
  sections: string[],
  link?: string
): Record<string, string> {
  if (!link) return {};
  let temp: Record<string, string> = {};
  for (const section of sections) temp[section] = link;
  return temp;
}

export const createProject = (
  form: FormInfo,
  history: History<LocationState>
): ProjectThunk => {
  return async (dispatch, getState) => {
    if (!form.legal) return;
    dispatch({ type: ProjectActions.CREATE });
    const user = getState().firebase.auth;
    Object.keys(form.sectionWeights).map(
      (key) => (form.sectionWeights[key] /= 100)
    );
    
    const pdfLinks = form.singlePdf
      ? _createPdfLinks(form.sections, form.singlePdfLink)
      : form.pdfLinks;
    const selectedPeople = form.selectedEmails.map((value)=>({
      email: value,
      projectID: "",
      company_name: form.companyName,
    }));
    selectedPeople.push({
      email: "team@invluencer.com",
      projectID: "",
      company_name: form.companyName,
    })
    const invitations: Invitation[] = form.emails.map<Invitation>((value) => ({
      email: value,
      projectID: "",
      requestor: user.displayName!,
      requestorId: user.uid,
      company_name: form.companyName,
      sections: form.sections,
      sentAt: new Date(),
      dueDate: form.dateRange[0].toDate(),
      disclaimerAccepted: false,
      sectionsStatus: array2Object(form.sections, "sent"),
      pdfLink: pdfLinks,
      formLink: {},
    }));

    const projectData: Project = {
      id: "",
      isDemo: false,
      company: {
        name: form.companyName,
        logo: form.companyImage,
        description: form.companyDescription,
        sector: form.companySector!,
        stage: form.companyStage,
        website: form.companyWebsite,
        investmentLink: form.dealSource,
        contactEmail: form.contactEmail,
      },
      startDate: firestore.Timestamp.fromDate(form.dateRange[0].toDate()),
      endDate: firestore.Timestamp.fromDate(form.dateRange[1].toDate()),
      privacy: form.privacy,
      requester: user.uid,
      sections: form.sections,
      pdfLinks: pdfLinks,
      sectionWeights: form.sectionWeights,
      sectionStats: {
        ...array2ObjectFunc(form.sections, (value) => ({
          avgRating: 0,
          numComments: 0,
          views: 0,
          completed: 0,
          invited: invitations.reduce(
            (pre, next) => pre + (next.sections.includes(value) ? 1 : 0),
            0
          ),
        })),
        overall: {
          avgRating: 0,
          numComments: 0,
          views: 0,
          invited: form.emails.length,
          completed: 0,
        },
      },
    };

    localStorage.setItem("email-suggestions", JSON.stringify(form.emails));
    localStorage.setItem("pref-weights", JSON.stringify(form.sectionWeights));
    const res = await ProjectRepo.create(projectData, invitations,selectedPeople);
    if (res instanceof Error)
      dispatch({ type: ProjectActions.CREATE_ERROR, payload: res });
    else {
      dispatch({ type: ProjectActions.CREATE_SUCCESS, payload: res });
      history.push(`/app/reports/${res}`);
    }
  };
};

export const fetchAllProjects = (): ProjectThunk => {
  return async (dispatch, getState) => {
    const { uid,email } = getState().firebase.auth;
    dispatch({ type: ProjectActions.FETCH });
    const res = await ProjectRepo.fetchAll(uid,email);
    // console.debug(res,"prject action response")
    if (res instanceof Error)
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: res });
    else dispatch({ type: ProjectActions.FETCHED_ALL, payload: res });
  };
};

//export const addFavourite = (projectId: string,): ProjectThunk => {
  export const addFavourite = (projectId: string,) => {
    return async (dispatch, getState) => {
      const { uid } = getState().firebase.auth;
      // console.debug(uid,"uuid")
      // console.debug(projectId,"projectId")
      const data={
        UID: uid,
        projectid: projectId,
      }
      const res = await ProjectRepo.addToFavrourite(data);
      // console.debug(res,"project addFavourite action response")
      if (res instanceof Error)
        dispatch({ type: ProjectActions.ADD_FAVOURITE_ERROR, payload: res });
      else
        dispatch({ type: ProjectActions.ADD_FAVOURITE,payload:projectId });
    };
  };

  //export const addFavourite = (projectId: string,): ProjectThunk => {
  export const removeFavourite = (projectId: string,) => {
    // console.debug("remove fav action")
    return async (dispatch, getState) => {
      const { uid } = getState().firebase.auth;
      const data={
        UID: uid,
        projectid: projectId,
      }
      const res = await ProjectRepo.removeToFavrourite(data);
      // console.debug(res,"project removeFavourite action response")
      if (res instanceof Error)
        dispatch({ type: ProjectActions.REMOVE_FAVOURITE_ERROR, payload: res });
      else
        dispatch({ type: ProjectActions.REMOVE_FAVOURITE,payload:projectId });
    };
  };

export const fetchReport = (
  projectId: string,
  project?: Project
): ProjectThunk => {
  return async (dispatch) => {
    dispatch({ type: ProjectActions.FETCH });
    const res = await ProjectRepo.fetchReport(projectId, project);
    if (res instanceof Error)
      dispatch({ type: ProjectActions.FETCH_ERROR, payload: res });
    else dispatch({ type: ProjectActions.FETCHED_REPORT, payload: res });
  };
};

export const deleteProject = (projectId: string): ProjectThunk => {
  return async (dispatch) => {
    dispatch({ type: ProjectActions.DELETE });
    const err = await ProjectRepo.deleteProject(projectId);
    if (err) dispatch({ type: ProjectActions.DELETE_ERROR, payload: err });
    else {
      dispatch({ type: ProjectActions.DELETED_PROJECT, payload: projectId });
      if (window.location.pathname !== "/app/dashboard")
        window.location.assign("/");
    }
  };
};

export const incrementProjectViews = (
  projectId: string,
  section: string
): ProjectThunk => {
  return async () => {
    const res = await ProjectRepo.incrementViews(projectId, section);
    if (res) console.error(res);
  };
};

export const shareProject = (
  project: Project,
  info: ShareInfo
): ProjectThunk => {
  return async (dispatch, getState) => {
    const user = getState().firebase.auth;
    dispatch({ type: ProjectActions.SHARE });
    const err = await ProjectRepo.shareProject(
      project,
      info,
      user.displayName!,
      user.uid
    );
    if (err) dispatch({ type: ProjectActions.SHARE_ERROR, payload: err });
    else dispatch({ type: ProjectActions.SHARED_PROJECT });
  };
};

export const shareProjectReport = (
  project: Project,
  info: ShareReport
): ProjectThunk => {
  return async (dispatch, getState) => {
    const user = getState().firebase.auth;
    dispatch({ type: ProjectActions.SHARE });
    const err = await ProjectRepo.shareProjectReport(
      project,
      info,
      user.displayName!,
      user.uid
    );
    if (err) dispatch({ type: ProjectActions.SHARE_ERROR, payload: err });
    else dispatch({ type: ProjectActions.SHARED_REPORT });
  };
};

export const resetProjectModal = (): ProjectAction => ({
  type: ProjectActions.RESET_MODAL,
});
