import React, {useEffect,useState} from "react";

import {
	Fab,
	Input,
	Menu,
	MenuItem,
	Select,
	Table,
	TableBody,
	Button,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { useHistory,useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
	withStyles,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Questions } from "../../model";
import {QuestionRepo} from "../../repositories/question";
import { RootState } from "../../reducers";
import PageTitle from "../PageTitle/PageTitle";
import Loader from "../Loader/Loader";

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.common.white,
			},
		},
	})
)(TableRow);



export default function ResponseList(props) {
	const keys = [
		{id:1,label:""},
		{id:2,label:"Date"},
		{id:3,label:"Answer"},
		{id:4,label:"User"},
		{id:5,label:"Section"},
		{id:6,label:"Company"},
	];
    const { id } = useParams<{ id: string }>();
	const user = useSelector((state: RootState) => state.firebase.auth);

	const [data,setData]= React.useState([]);
	const [question,setQuestion]= React.useState("");
	const [section,setSection]= React.useState("");
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
    useEffect(() => {
        const res = QuestionRepo.fetchQuestion(id).then((result)=>{
            var array = [];
            setQuestion(result.question);
            setSection(result.section);
             result.responses.map((res)=>{
              res.form_response.answers.filter((ans)=>{
                    if(ans.field.id===id)
                    {
                        ans["date"] = res.form_response.submitted_at;
                        ans["user"] = res.user;
                        ans["project"] = res.form_response.hidden.company;
                        array.push(ans);
                    }
                });
            });
            setData(array);
        })
    }, []);
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#F2F7FF",
            fontWeight: "bold",
        },
    }))(TableCell);
	const userName = user.displayName?.split(" ")[0] ?? "there!";
    if (!data)
    return <Loader text="Fetching your responses..." />;
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
return (
    <div className={classes.container}>
			<PageTitle
					noMargin
					title={
						question
					}
					extra={
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
							}}
						>
						</div>
					}
				/>		
        <TableContainer>
        <Table stickyHeader className="mb-0">
            <TableHead>
                <TableRow>
                    {keys.map((key) => (
                        <StyledTableCell key={key.id}>{key.label}</StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data.map((data)=>{
                        return(
                            <StyledTableRow>
                                <TableCell>		
                                </TableCell>
                                <TableCell>
                                    {formatDate(data.date)}
                                </TableCell>
                                <TableCell>
                                    {data.type==="number"?data.number:(data.text).substring(0,40)+"..."}
                                </TableCell>
                                <TableCell>
                                    {data.user}
                                </TableCell>
                                <TableCell>
                                    {section}
                                </TableCell>
                                <TableCell>
                                    {data.project}
                                </TableCell>
                                
                            </StyledTableRow>
                        );
                    })
                }
                
            </TableBody>
        </Table>
        </TableContainer>
    </div>

		);
	}
	
