import React, { useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Grid, Typography, Tabs, Tab } from "@material-ui/core";
import { Typography as Typo } from "../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import logo from "./logos.png";
import { LoginForm, RegisterForm } from "../../components/Login";

function Login() {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const plan = searchParams.get("plan");

  // local
  const [activeTabId, setActiveTabId] = useState(!!plan ? 1 : 0);

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="New User" classes={{ root: classes.tab }} />
          </Tabs>
          <div className={classes.formContent}>
            {activeTabId === 0 && <LoginForm />}
            {activeTabId === 1 && <RegisterForm plan={plan} />}
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
