import { Reducer } from "react";
import { LayoutState, LayoutAction, LayoutActions } from "../model";

const initialState: LayoutState = {
  isSidebarOpened: window.innerWidth > 960,
};

export const layoutReducer: Reducer<LayoutState, LayoutAction> = (
  state: LayoutState = initialState,
  action: LayoutAction
): LayoutState => {
  switch (action.type) {
    case LayoutActions.TOGGLE_SIDEBAR:
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case LayoutActions.TOGGLE_MODAL:
      return { ...state, openModal: action.payload };
    default:
      return state;
  }
};
