import React, { useState, useEffect, MouseEvent } from 'react';
import {
  Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography, Button, Menu, MenuItem, Box, CircularProgress, TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import PageTitle from '../../components/PageTitle/PageTitle';
import FormDialog from '../../components/Modals/FormDailog';
import { callableFunctions } from '../../repositories/firebase';
import useStyles from "./styles";
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useHistory } from 'react-router-dom';
import ImportDomainDialog from '../../components/Modals/ImportDomainDialog';
import debounce from 'lodash.debounce';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.common.white,
      },
    },
  })
)(TableRow);

interface BlockedDomain {
  id: string;
  name: string;
  activeStatus: string;
}

const BlockedDomainsList = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.firebase.auth);
  const [allBlockedDomain, setAllBlockedDomain] = useState<BlockedDomain[]>([]);
  const [filteredBlockedDomain, setFilteredBlockedDomain] = useState<BlockedDomain[]>([]);
  const [pageToken, setPageToken] = useState<string | null>(null);
  const [fetching, setFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBlocked, setSelectedBlocked] = useState<BlockedDomain | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImportOpen, setDialogImportOpen] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [actionType, setActionType] = useState<string>('');
  const [initialValues, setInitialValues] = useState<BlockedDomain | {}>({});
  const [flag, setFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const userName = user.displayName?.split(" ")[0] ?? "there!";

  useEffect(() => {
    loadBlockedDomains();
  }, [flag]);

  // useEffect(() => {
  
  //   if (searchQuery.trim()) {
  //     console.debug("searchQuery", searchQuery)
  //     handleSearch(searchQuery);
  //   } else {
  //     setFilteredBlockedDomain(allBlockedDomain);
  //   }
  // }, [searchQuery]);

  const loadBlockedDomains = async () => {
    console.debug("searchOnChangeHanlder: if :loadBlockedDomains chalega",hasMore,fetching)
    if (!hasMore || fetching) return;
  
    setFetching(true);
    setSearchQuery('')
    try {
      const getBlockedDomains = callableFunctions.httpsCallable('getBlockedDomains');
      const result = await getBlockedDomains({ pageSize: 10, pageToken: flag ? null : pageToken });
      const { domains, nextPageToken, hasMore: moreData } = result.data;
  
      console.debug('Fetched domains', { domains, nextPageToken, moreData });
  
      if (flag) {
        setAllBlockedDomain(domains);  // Reset the domains if flag is true
        setFilteredBlockedDomain(domains);
      } else {
        setAllBlockedDomain(prevDomains => [...prevDomains, ...domains]); // Append the new domains to the existing ones
        setFilteredBlockedDomain(prevDomains => [...prevDomains, ...domains]);
      }
  
      setPageToken(nextPageToken);
      setHasMore(moreData);
      setFlag(false);
    } catch (error) {
      console.error('Error loading blocked domains:', error);
    }
    setFetching(false);
  };
  

  const handleSearch = debounce(async (searchText: string) => {
    if (fetching) return;

    setFetching(true);
    try {
      const searchBlockedDomains = callableFunctions.httpsCallable('searchBlockedDomains');
      const result = await searchBlockedDomains({ searchTerm: searchText, pageSize: 10, pageToken: '' });
      const { results, nextPageToken, hasMore: moreData } = result.data;

      setFilteredBlockedDomain(results);
      setPageToken(nextPageToken);
      // setHasMore(moreData);
    } catch (error) {
      console.error('Error searching blocked domains:', error);
    }
    setFetching(false);
  }, 500);

  const handleLoadMore = () => {
    if (!fetching) {
      loadBlockedDomains();
    }
  };

  const handleClick = (event: MouseEvent<HTMLElement>, blocked: BlockedDomain) => {
    setAnchorEl(event.currentTarget);
    setSelectedBlocked(blocked);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = async (blocked: BlockedDomain) => {
    console.debug("handleEdit:handleEdit", blocked);
    setStatusLoader(true);
    const UpdateActiveStatus = blocked.activeStatus === "TRUE" ? "FALSE" : "TRUE";
    try {
      const result = await updateBlockedDomain(blocked.id, blocked.name, UpdateActiveStatus);
      console.log(result.message);
      setFlag(true);
      handleClose();
      setStatusLoader(false);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleRename = (blocked: BlockedDomain) => {
    setActionType('rename');
    setInitialValues(blocked);
    setDialogOpen(true);
  };

  const handleDomainUser = (blocked: BlockedDomain) => {
    console.debug("handleDomainUser:handleDomainUser", blocked);
    history.push("/app/blockdomain/" + blocked.name);
  };

  const handleDelete = async (blocked: BlockedDomain) => {
    setDeleteLoader(true);
    const id = blocked.id;
    try {
      const result = await deleteBlockedDomain(id);
      console.debug(result.message);
      setFlag(true);
      handleClose();
      setDeleteLoader(false);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const deleteBlockedDomain = async (id: string) => {
    const deleteDomainFunction = callableFunctions.httpsCallable('deleteBlockedDomain');
    try {
      const result = await deleteDomainFunction({ id });
      console.log('Document deleted successfully:', result.data);
      return result.data;
    } catch (error) {
      console.error('Error deleting document:', error);
      throw error;
    }
  };

  const updateBlockedDomain = async (id: string, name?: string, activeStatus?: string) => {
    const updateDomainFunction = callableFunctions.httpsCallable('updateBlockedDomain');
    try {
      const result = await updateDomainFunction({ id, name, activeStatus });
      console.log(result.message);
      return result.data;
    } catch (error) {
      console.error('Error updating document:', error);
      throw error;
    }
  };

  const searchOnChangeHanlder =(e)=>{
    console.debug("searchOnChangeHanlder", e.target.value)
    setSearchQuery(e.target.value)
    if(e.target.value == ""){ 
      console.debug("searchOnChangeHanlder: if :loadBlockedDomains chalega", e.target.value)
      setFlag(true);
      setFetching(false)
    }else{
      console.debug("searchOnChangeHanlder: else :handleSearch chalega", e.target.value)
      handleSearch(e.target.value);
    }
  }

  const handleUploadSuccess = () => {
    setDialogImportOpen(false);
    setFlag(true);
  };

  const handleUploadError = (error: string) => {
    console.error('Error uploading file:', error);
  };

  const openImportDialog = () => {
    setDialogImportOpen(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" margin="20px 0">
        <PageTitle
          noMargin
          title={
            new Date().getHours() > 12
              ? "Good afternoon " + userName
              : "Good morning " + userName
          }
        />
        <Box display="flex" alignItems="center">
          <TextField
            label="Search Domain"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => searchOnChangeHanlder(e)}
            style={{ marginRight: 16 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={openImportDialog}
          >
            <AddIcon />
            Import Domain
          </Button>
        </Box>
      </Box>
      <Fab
        className={classes.addDomain}
        color="primary"
        variant="extended"
        aria-label="add"
        onClick={() => {
          setActionType('add');
          setDialogOpen(true);
        }}
      >
        <AddIcon />
        Add Domain
      </Fab>

      <TableContainer className={classes.tableContainer}>
        {fetching && allBlockedDomain.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={48} />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Domain</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBlockedDomain.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography align="center">No blocked domains found.</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredBlockedDomain.map((blocked) => (
                  <StyledTableRow key={blocked.id}>
                    <TableCell>{blocked.name}</TableCell>
                    <TableCell style={{ color: blocked.activeStatus === "TRUE" ? 'red' : 'green' }}>
                      {blocked.activeStatus === "TRUE" || blocked.activeStatus === "TRUE " ? 'Blocked' : "Unblocked"}
                    </TableCell>
                    <TableCell>
                      <Button aria-controls="simple-menu" color="primary" onClick={(event) => handleClick(event, blocked)}>
                        Action
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {statusLoader === false ? <MenuItem onClick={() => handleEdit(selectedBlocked!)}>
                          {selectedBlocked?.activeStatus === "TRUE" ? "Unblock" : "Block"}
                        </MenuItem> : <MenuItem>
                          <CircularProgress size={24} />
                        </MenuItem>}
                        <MenuItem onClick={() => handleRename(selectedBlocked!)}>
                          Rename
                        </MenuItem>
                        {deleteLoader === false ?
                          <MenuItem onClick={() => handleDelete(selectedBlocked!)}>
                            Delete
                          </MenuItem> :
                          <MenuItem>
                            <CircularProgress size={24} />
                          </MenuItem>}
                        <MenuItem onClick={() => handleDomainUser(selectedBlocked!)}>
                          Domain User
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Box style={{ textAlign: 'center', margin: '20px 0' }}>
        {fetching && allBlockedDomain.length > 0 && (
          <CircularProgress size={24} />
        )}
        {!fetching && hasMore && (
          <Button variant="contained"
            color="primary" onClick={handleLoadMore} disabled={fetching}>
            {fetching ? <CircularProgress size={24} /> : "Load More"}
          </Button>
        )}
      </Box>
      <FormDialog
        open={dialogOpen}
        initialValues={initialValues}
        type={actionType}
        selectedBlocked={selectedBlocked}
        setFlag={setFlag}
        handleClose={handleClose}
        setDialogOpen={setDialogOpen}
      />
      <ImportDomainDialog
        onSuccess={handleUploadSuccess}
        onError={handleUploadError}
        open={dialogImportOpen}
        setFlag={setFlag}
        setDialogImportOpen={setDialogImportOpen}
      />
    </>
  );
};

export default BlockedDomainsList;
