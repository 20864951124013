import * as Yup from "yup";

export * from "./Login";
export * from "./Register";

export interface LoginInfo {
  email: string;
  password: string;
}

export interface AuthInfo {
  name: string;
  lastName: string;
  email: string;
  password: string;
  sector: string;
  terms: boolean;
}

export const registerValidation = Yup.object().shape<AuthInfo>({
  name: Yup.string().min(3, "Too short!").max(30, "Too long!").required(),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Required"),
  lastName: Yup.string(),
  terms: Yup.boolean()
    .oneOf([true], "You need to accept the Terms of Service.")
    .required(),
  sector: Yup.string()
    .required("Required"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(25, "Too Long!")
    .required("Required"),
});

export const loginValidation = Yup.object().shape<LoginInfo>({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Required"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(25, "Too Long!")
    .required("Required"),
});
