import React, { Dispatch, useEffect,useState } from "react";
import {
	Button,TextField
} from "@material-ui/core";
import useStyles from '../styles';
import Modal from "antd/lib/modal/Modal";
function FactorModalEdit(props)
{
    let d = props.option[props.editKey];
   const[value,setValue]=useState(d.value);
   const[score,setScore]=useState(d.score);
   const[comment,setComment]=useState(d.comment?d.comment:'');
   const classes = useStyles();
    const handleClose = ()=>{
        props.setOpen(false)
        props.setEditKey(-1)
    }
    const submitForm = ()=>{
        
        var data = props.option;
        if('commentId' in data[props.editKey])
        {
        data[props.editKey]={value:value,score:parseInt(score),comment:comment,commentId:data[props.editKey].commentId};
        }
        else
        {
          if(comment=='')
          data[props.editKey]={value:value,score:parseInt(score)};
          else
          data[props.editKey]={value:value,score:parseInt(score),comment:comment};

        }
        props.setOption([...data]);
        props.setEditKey(-1)
        props.setOpen(false);

    }
return(
    <>
        <Modal
          title={'Edit'}
          centered
          className={classes.modalTeam}
          zIndex={2}
          visible={props.open}
            onCancel={handleClose}
          footer={[
            <Button key="back"
              onClick={handleClose}
            >
              Cancel
            </Button>,
            <Button onClick={submitForm}
            >
              Submit
            </Button>
          ]}
        >
            <div>
            <TextField id="outlined-basic" value={value} style={{width:'100%',marginBottom:10}} label="value" variant="outlined" onChange={(e)=>{setValue(e.target.value)}} />
            </div>
            <div>
            <TextField id="outlined-basic" value={score} label="score" style={{marginBottom:10}} variant="outlined" onChange={(e)=>{setScore(e.target.value)}}/>
            </div>
            <div>
            <TextField id="outlined-basic" value={comment} style={{width:'100%'}} label="comment" variant="outlined" onChange={(e)=>{setComment(e.target.value)}}/>
            </div>
        </Modal>
</>
)
}

export default FactorModalEdit;