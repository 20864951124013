import React from "react";
import { Button, Grid, Button as MButton } from "@material-ui/core";
import { Typography } from "../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
// import { Select, Input, AutoComplete, Radio } from "formik-antd";
import { useFormikContext, Field } from "formik";
import { Faqs } from "../../../model";
import { sectionOptions, stages } from "./data";
import { values } from "lodash";
import { Select, Input, AutoComplete, Checkbox } from "formik-antd";
import { useHistory } from "react-router-dom";

const { Option } = Select;

function FaqForm() {
  const history = useHistory();
    const classes = useStyles();
    const formik = useFormikContext<Faqs>();

    return (
        <Grid container style={{ padding: "8rem", paddingTop: "5rem" }}>
            <Grid item xs={6} style={{ paddingRight: "7rem" }}>
                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Question
                    </Typography>
                    <Input
                        className={classes.detailQuestion}
                        name="question"
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2rem", marginTop: "1rem" }}
                        placeholder="Enter Question here..."
                    />
                </div>
                <div className={classes.inputField} style={{ width: "100%", }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Context
                    </Typography>
                    <Input
                        className={classes.context}
                        name="context"
                        style={{ width: "100%", borderRadius: 10, marginBottom: "2rem", marginTop: "1rem" }}
                        placeholder="provide question about the question.."
                    />

                </div>
            </Grid>
            <Grid item xs={6} style={{ paddingRight: "7rem" }}>
            <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem" }}>
                    <Typography variant="h4" className={classes.sectionTitles}>
                        Platform
                    </Typography>
                    <Grid container style={{ paddingTop: "0.6rem" }}>
                        <Grid item xs={4} className={classes.radioButtonBox}>

                            <Field type="radio" name="platform" value={"rapid"} />
                            <label className={classes.label}>
                                i3D Rapid
                            </label>
                        </Grid>
                        <Grid item xs={4} className={classes.radioButtonBox}>
                            <Field type="radio" name="platform" value={"expert"} />
                            <label className={classes.label}>
                                i3D Expert
                            </label>
                        </Grid>
                        <Grid item xs={4} className={classes.radioButtonBox}>

                            <Field type="radio" name="platform" value={"both"} />
                            <label className={classes.label}>
                                both
                            </label>
                        </Grid>
                    </Grid>
                </div>
                </Grid>

            <div className={classes.inputField} style={{ width: "100%", marginTop: "1rem", textAlign:"center" }}>
                <Button
                    color="primary"
                    size="small"
                    type="button"
                    className={classes.submitButton}
                    onClick={() => formik.submitForm()}
                >
                    Save
                </Button>
                <MButton className={classes.cancelButton} style={{marginLeft: "2rem"}} color="inherit" onClick={() => history.goBack()}>
                    Cancel
                </MButton>
            </div>
        </Grid>


    );
}

export default FaqForm;
