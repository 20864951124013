import React from "react";
import { Field, Formik, Form } from "formik";
import {
  CircularProgress,
  Typography,
  Button,
  Fade,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import google from "./../../images/google.svg";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { LoginInfo, loginValidation } from "./index";
import { UserDispatch } from "../../model";
import { loginUserWithGoogle, loginUser } from "../../actions/user";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField } from "material-ui-formik-components";

export function LoginForm() {
  // global
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<UserDispatch>();
  const classes = useStyles();
  const [passwordShown, setpasswordShown] = React.useState<boolean>(false);

  return (
    <Formik<LoginInfo>
      initialValues={{
        email: "",
        password: "",
      }}
      validateOnMount
      validateOnChange
      validateOnBlur
      validationSchema={loginValidation}
      onSubmit={(values) => dispatch(loginUser(values.email, values.password))}
    >
      {(formik) => {
        return (
          <Form style={{padding:"60px"}} onSubmit={formik.submitForm}>
            <Typography variant="h1" className={classes.greeting}>
              Welcome Back!
            </Typography>
            <Button
              size="large"
              className={classes.googleButton}
              onClick={() => {
                dispatch(loginUserWithGoogle());
              }}
            >
              <img src={google} alt="google" className={classes.googleIcon} />
              &nbsp;Sign in with Google
            </Button>
            <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
              <Typography className={classes.formDividerWord}>or</Typography>
              <div className={classes.formDivider} />
            </div>
            <Fade in={!!user.error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {user.error ?? "Something went wrong during login."}
              </Typography>
            </Fade>
            <Field
              required
              name="email"
              label="Email"
              component={TextField}
              margin="normal"
              placeholder="Email Address"
              type="email"
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
            />
            <Field
              required
              name="password"
              label="Password"
              component={TextField}
              margin="normal"
              placeholder="Password"
              type={passwordShown ? "text" : "password"}
              fullWidth
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                    style={{marginRight:"0px"}}
                      aria-label="toggle password visibility"
                      onClick={() => setpasswordShown(!passwordShown)}
                      edge="end"
                    >
                      {passwordShown ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.formButtons}>
              {user.isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={!formik.isValid}
                  onClick={(e) => {
                    e.preventDefault();
                    formik.submitForm();
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Login
                </Button>
              )}
              <Button
                color="primary"
                size="large"
                type="button"
                className={classes.forgetButton}
                onClick={() => console.log("go to forgot password page")}
              >
                Forgot Password
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
